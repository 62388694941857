import { getLocalValue, LoacalVariables, localStorageVariable } from "../commonFunctions/CommonFunctions";
import { WSIcons } from "../commonFunctions/iIcons";

export const getMenu: any =(userType: string) => {

   return { "staticMenu"  :  userType=== 'admin' ?[
    {
        "id": 1,
        "label": "Home",
        "icon": WSIcons.Home,
        "path": "/home"
    },
    {
        "id": 2,
        "label": "Masters",
        "icon": WSIcons.Masters,
        "children": [
            {
                "id": 3,
                "label": "Work Location",
                "path": "/locations"
            },
            {
                "id": 4,
                "label": "Designations",
                "path": "/designations"
            },
            {
                "id": 5,
                "label": "Departments",
                "path": "/departments"
            },
            {
                "id": 6,
                "label": "Leave Type",
                "path": "/leavetypes"
            },
            {
                "id": 20,
                "label": "Schedule Meeting",
                "path": "/schedule/list"
                
            },
            {
                "id": 21,
                "label": "Announcement",
                "path": "/announcement/list"
            },

        ]
    },

    {
        "id": 7,
        "label": "Employees",
        "path": "/employee/list",
        "icon": WSIcons.Person
    },
    {
        "id": 8,
        "label": "Leave",
        "icon": WSIcons.PersonOffIcon,
        "children": [
            // {
            //     "id": 9,
            //     "label": "Leave Request",
            //     "path": "/leave"
            // },
            {
                "id": 10,
                "label": "Leave approval",
                "path": "/leave/actions"
            },

        ]
    },

    {
        "id": 11,
        "label": "Users",
        "path": "/user/list",
        "icon": WSIcons.UserGroup
    },
    {
        "id": 12,
        "label": "Roles",
        "path": "/roles/add",
        "icon": WSIcons.RolesIcon
    },
    {
        "id": 13,
        "label": "Settings",
        "icon": WSIcons.SettingsIcon,
        "children": [
            {
                "id": 14,
                "label": "Organization",
                "path": "/organization/view"
            },
            {
                "id": 15,
                "label": "Change Password",
                "path": "/changepassword"
            },
          
        ]
    },
    {
        "id": 18,
        "label": "Holiday",
        "icon": WSIcons.HolidayVillageIcon,
        "path": "/holiday"
    },
]:[
    {
        "id": 1,
        "label": "Home",
        "icon": WSIcons.Home,
        "path": "/home"
    },
 
    {
        "id": 8,
        "label": "Leave",
        "icon": WSIcons.PersonOffIcon,
        "children": [
            {
                "id": 9,
                "label": "Leave Request",
                "path": "/leave"
            },

        ]
    },
    {
        "id": 16,
        "label": "Change Password",
        "icon": WSIcons.TrendingUp,
        "path": "/changepassword"
    },
    {
        "id": 17,
        "label": "Holiday",
        "icon": WSIcons.HolidayVillageIcon,
        "path": "/holiday"
    },
]
   }
}
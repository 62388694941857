import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from "react-router-dom";
import { callAPI, getLocalValue, IRoleMain, LoacalVariables, MasterPages, Method } from '../../commonFunctions/CommonFunctions';
import PermissionDenied from '../../commonFunctions/PermissionDenied';
import { getPermissions } from '../../commonFunctions/PermissionManger';
import WSButton from '../../Components/Button/WSButton';
import WSRadioField from '../../Components/Radio/WSRadio';
import { useSnackbar } from '../../Components/Snackbar/SnackBarContext';
import { IInfoColumns, WSInfocard } from '../../Components/View/WSInfocard';
import WSTextAreaField from '../../Components/WSTextArea/WSTextArea';
import config from '../../Config/clientConfig.json';
import messages from '../../Config/Messages.json';
import { Header } from '../Header/Header';
interface ILeaveDetails {
    id: string;
    leaveData: any;
}
export const LeaveDetails: React.FC<ILeaveDetails> = React.memo(() => {
    const location = useLocation();
    const { showMessage } = useSnackbar();
    const [leaveData, setLeaveData] = useState<any>(location?.state?.leaveData || null);
    const navigate = useNavigate();
    const { register, handleSubmit, control, formState: { }, setValue, reset } = useForm();
    const { view } = getPermissions(MasterPages.Organisactions, IRoleMain.Transactions);
    const [viewReason, setViewReason] = useState(false)
    const columns: IInfoColumns[] = [
        {
            fieldName: 'dateFrom',
            label: 'From',
            type: 'date',
            style: { fontSize: '0.875rem', color: 'gray' }
        },
        {
            fieldName: 'dateTo',
            label: 'To',
            type: 'date',

            style: { fontSize: '0.875rem', color: 'gray' }
        },
        {
            fieldName: 'reason',
            label: 'Reason',
            type: 'none',
            isTooltip: true,
            style: { fontSize: '0.875rem', color: 'gray' }

        }, 
        {
            fieldName: 'leaveRequsetedDays',
            label: 'Leave Requested',
            type: 'none',
            isTooltip: true,
            style: { fontSize: '0.875rem', color: 'gray' }

        },
        
        {
            fieldName: 'statusCode',
            label: 'Status',
            type: 'status',
            style: { fontSize: '0.875rem', color: 'gray' }
        }
    ]
    async function leaveApprove(id: string, body: any) {
        const response = await callAPI(`LeaveApply/approve/${id}`, Method.POST, body)
        return response
    }
    async function leaveReject(id: string, body: any) {
        const response = await callAPI(`LeaveApply/reject/${id}`, Method.POST, body)
        return response 
    }
    const handleApprove = async () => {
        try {
            const request = {
                managerId: getLocalValue(LoacalVariables.UserId)
            }
            const response = await leaveApprove(leaveData?.id, request);
            if (response && response?.success===true) {
                showMessage(messages?.Leave?.Approved, 'success')
                navigate('/leave/actions')
            }
        }
        catch (e: any) {
            showMessage(e?.message || e || 'Something went wrong', 'error')
        }

    }
    const handleActions = (evt: any) => {

        setViewReason(evt?.target?.value === '2' ? true : false)
    }
    const onSubmit = async (data: any) => {
        try {
            const request = {
                managerId: getLocalValue(LoacalVariables.UserId),
                reason: data?.reason
            }
            const response = await leaveReject(leaveData?.id, request);
            if (response && response?.success===true) {
                showMessage(messages?.Leave?.Rejected, 'success')
                navigate('/leave/actions')
            }
        }
        catch (e: any) {
            showMessage(e || 'Something went wrong', 'error')
        }

    }
    return (
        <> <Header title="Leave Information" type="simple" isBackButton={true} />
            {view ? (
                <> <Paper elevation={3} style={{ padding: '25px', margin: '0px', border: '1px solid #ccc', borderRadius: '8px' }}>
                    <Grid container spacing={1}>
                        <Grid item sm={3}>
                            <Typography className="header-text" mt={0}>
                                {`${leaveData?.employee}`}
                            </Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <Divider sx={{ marginY: 1 }} />
                            <br />
                        </Grid>
                    </Grid>


                    {leaveData && <WSInfocard header="Details" data={leaveData} column={columns} labelStyle={{ color: 'black', fontSize: '0.875rem' }} />}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1} style={{padding:5}}>
                            <Grid item sm={12}>
                                <WSRadioField control={control} options={config.leaveApproveRrjectConfig.staticOptionData} id='action' label='Action' name='action' rules={{ required: true }} onChange={handleActions} defaultValue={"1"} />
                            </Grid>
                            <Grid item sm={12}>
                                <WSTextAreaField control={control} id='reason' label='Reason' name='reason' rules={{ required: !viewReason ? false : true }} hide={!viewReason} />
                            </Grid>

                        </Grid>
                        <Grid item container spacing={2} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                            {!viewReason && <Grid item >
                                <WSButton id='approve'
                                    label={"Approve"}
                                    type="button"
                                    size="medium"
                                    color='success'
                                    className='ws-success'
                                    variant='outlined'
                                    onClick={handleApprove}
                                />
                            </Grid>}
                            {viewReason && <Grid item>
                                <WSButton id='reject'
                                    label={"Reject"}
                                    type="submit"
                                    variant='outlined'
                                    size="medium" className="ws-reject"
                                />
                            </Grid>}
                        </Grid>
                        {/* <Grid item container spacing={1} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                                <Grid item >
                                    <WSButton id='leaveApprove' label='Approve' size='medium' type='button' onClick={handleApprove} />
                                </Grid>
                            </Grid>
                            <Grid item >
                                <WSButton id='leaveReject' label='Reject'  size='medium' type='submit' onClick={handleReject} />
                            </Grid>
                            <Grid item sm={12}>
                                <Divider sx={{ marginY: 1 }} />
                                <br />
                            </Grid> */}
                    </form>
                </Paper>
                </>

            ) : (
                <PermissionDenied />
            )}
        </>
    );
});

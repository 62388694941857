/**
 * Component   : ScheduleMeeting
 * Description : Schedule a Meeting
 */
import { Divider, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import moment from "moment";
import { momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import WSButton from "../../../Components/Button/WSButton";
import WSDateField from "../../../Components/DateField/WSDateField";
import { useSnackbar } from "../../../Components/Snackbar/SnackBarContext";
import WSTextField, { TextType } from "../../../Components/TextBox/WSTextBox";
import WSTextAreaField from "../../../Components/WSTextArea/WSTextArea";
import { callAPI, clearRequest, Method } from "../../../commonFunctions/CommonFunctions";
import { Header } from "../../Header/Header";
import { IAnnouncement } from "../../Interfaces/Interfaces";
export const Announcement: React.FC<any> = React.memo((props) => {
    const [startDate, setStartDate] = useState<string>()
    const { showMessage } = useSnackbar();
    const { register, handleSubmit, control, formState: { }, setValue, watch, reset } = useForm();
    const navigate = useNavigate();
    const params = useParams();
    const id = params?.id;
    useEffect(() => () => {
        reset()
    }, [])
    const localizer = momentLocalizer(moment);

    const events = [
        {
            title: 'Meeting',
            start: new Date(),
            end: new Date(),
        },
    ];

    useEffect(() => {
        /**
         * Method to get the user record by id for edit purpose
         * @param id 
         */
        async function getMeetingById(id: any): Promise<void> {
            try {
                const response: any = await callAPI('announcement', Method.GET, {}, id);

                if (response && response.data) {

                    for (const field in response.data) {

                        setValue(field, (response?.data as any)[field]);
                        if (field === 'startDate') {
                            setStartDate((response?.data as any)[field])
                        }

                    }
                }
            } catch (error) {
                showMessage('Error fetching user:', 'error');
            }
        }
        getMeetingById(id);
    }, [id]);

    const handleCancelClick = () => {
        navigate("/announcement/list");
    };
    const startDateChanged = (event: any) => {

        setStartDate(event)
    }
    /**
     * Method for Add/Update the data
     * @param data 
     */
    const onSubmit = async (data: any) => {
        try {

            const request: any = await setRequest(data);
            if (id) {
                const response: any = await callAPI(`announcement/${id}`, Method.PUT, request);
                if (response) {
                    showMessage(response?.message, response.success === true ? "success" : "error");
                    response.success === true && navigate("/announcement/list");
                }
            } else {
                const response: any = await callAPI('announcement', Method.POST, request);
                if (response) {
                    showMessage(response?.message, response.success === true ? "success" : "error");
                    response.success === true && navigate("/announcement/list");
                }
            }
        } catch (e: any) {
            showMessage(e.error);
        }
    };

    /**
     * Method to set the request for the user
     * @param data 
     * @returns 
     */
    async function setRequest(data: IAnnouncement) {


        let request: IAnnouncement = {
            title: data?.title,
            startDate: dayjs(data?.startDate).format("YYYY-MM-DDTHH:mm:ss.SSS"),
            endDate: dayjs(data?.endDate).format("YYYY-MM-DDTHH:mm:ss.SSS"),
            description: data?.description

        };
        return clearRequest(request);
    }
    const handleChange = () => { }
    return (
        <> <Header title={id ? 'Edit Announcement' : 'Add Announcement'} type="simple" url="/announcement/list" isBackButton={true}  isDivider={true}/>
         
            <Paper elevation={3} style={{ paddingLeft: 10, paddingRight: 0, marginTop: 0, paddingBottom: 20, border: '1px solid #ccc', borderRadius: '8px' }}>
                {/* <Grid item container spacing={1}>
                    <Grid item sm={12}>
                        <Typography className="header-text" >
                            {id?'Edit Announcement':'Add Announcement'}
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Divider sx={{ marginY: 2 }} />
                        <br />
                    </Grid>
                </Grid> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid xs={12} className="modal-input" container spacing={2} style={{ marginTop: 10 }}>

                        <Grid item sm={12} xs={12}>
                            <WSTextField
                                control={control}
                                id="title"
                                {...register("title")}
                                type={TextType.Text}
                                rules={{ required: true }}
                                name="title"
                                label="Title"
                            />

                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <WSDateField
                                id="startDate"
                                name="startDate"
                                label="Start Date *"
                                dateFormat="YYYY/MM/D"
                                denyFuturOrPast="past"
                                control={control}
                                rules={{ required: "Date is required" }}
                                onChange={startDateChanged}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <WSDateField
                                id="endDate"
                                name="endDate"
                                label="End Date *"
                                defaultValue={startDate}
                                setValue={setValue}
                                denyFuturOrPast="custom"
                                dateFormat="YYYY/MM/D"
                                control={control}
                                rules={{ required: "Date is required" }}

                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <WSTextAreaField
                                control={control}
                                id="description"
                                name="description"
                                label="Description "
                                rules={{ required: true }}
                            />
                        </Grid>

                    </Grid>

                    <Grid item container spacing={1} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                        <Grid item sx={{ marginRight: 1 }} >
                            <WSButton id='submit' label={"Save"} type="submit" size="medium" />
                        </Grid>
                        <Grid item sx={{ marginRight: 2 }}>
                            <WSButton id='cancel' label={"Cancel"} type="button" onClick={handleCancelClick} size="medium" className="ws-cancel" />
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    );
});

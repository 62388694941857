import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI, formatAddressObject, imgType, IRoleMain, MasterPages, Method } from "../../../commonFunctions/CommonFunctions";
import PermissionDenied from "../../../commonFunctions/PermissionDenied";
import { getPermissions } from "../../../commonFunctions/PermissionManger";
import { useSnackbar } from '../../../Components/Snackbar/SnackBarContext';
import { IInfoColumns, WSInfocard } from "../../../Components/View/WSInfocard";
import config from '../../../Config/clientConfig.json';
import { Header } from '../../Header/Header';

export const ViewOrganization: React.FC<any> = React.memo(() => {
    const { showMessage } = useSnackbar();
    const [organisationData, setOrganaisationData] = useState<any>(null);
    const navigate = useNavigate();
    const params = useParams();
    const id = params?.id;
    const [base64Image, setBase64Image] = useState<string | null>(null);
    const { view } = getPermissions(MasterPages.Organisactions, IRoleMain.Transactions);

    useEffect(() => {
        async function getOrganization(): Promise<void> {
            try {
                const response: any = await callAPI('organisation', Method.GET, {});
                if (!response || !response.success) {
                    navigate('/organization/add');
                } else {
                    const maindata = Array.isArray(response.data) && response.data.length > 0 ? response.data[0] : response.data;

                    maindata.industryName = config.studentConfig.staticOptionData.find((item: any) => item.value?.toString() == maindata.industry)?.label
                    maindata.address = formatAddressObject(maindata.addresses[0])
                    setOrganaisationData(maindata);
                    if (maindata.logo) {
                        const base64Response: string = imgType(maindata.sentot) + maindata.logo;
                        setBase64Image(base64Response);
                    }
                }
            } catch (error) {
                showMessage('Error fetching organization:', 'error');
            }
        }
        getOrganization();
    }, []);

    const handleEditClick = () => {
        navigate(`/organization/edit/${id}`);
    };

    const handleCancelClick = () => {
        navigate("/");
    };
    const columns: IInfoColumns[] = [
        {
            fieldName: 'location',
            label: 'Location',
            type: 'none',
            style:{fontSize:'0.875rem',color:'gray'}
        },
        {
            fieldName: 'email',
            label: 'Email',
            type: 'none',
            isTooltip: true,
            style:{fontSize:'0.875rem',color:'gray'}
        },
        {
            fieldName: 'industryName',
            label: 'Industry',
            type: 'none',
            style:{fontSize:'0.875rem',color:'gray'}

        },
        {
            fieldName: 'address',
            label: 'Address',
            style:{fontSize:'0.875rem',color:'gray'}
        }
    ]
    return (
        <><Header title="Organisation" type="simple" isDivider={true}/>
            {view  ? (
                <> <Paper elevation={3} style={{ padding: '20px', margin: '0px', border: '1px solid #ccc', borderRadius: '8px' }}>
                    <Grid container spacing={1}>
                        <Grid item sm={1} xs={4}>
                            <Box mt={0} border={'1px solid gray'} height={'3rem'} width={'3rem'}>
                                {base64Image && (
                                    <img
                                        src={base64Image}
                                        alt="Organization Logo"
                                        style={{ width: '3rem', height: '3rem', objectFit: 'contain' }}
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <Typography className="header-text" mt={1}>
                                {organisationData?.organisationName ? organisationData?.organisationName : "Organisation"}
                            </Typography>
                        </Grid>
                        <Grid item sm={8}  xs={2}container justifyContent="flex-end">
                            <IconButton onClick={handleEditClick} color="warning" aria-label="edit organization">
                                <EditIcon sx={{ fontSize: '1rem' }} />
                            </IconButton>
                        </Grid>
                        <Grid item sm={12}>
                            <Divider sx={{ marginY: 1 }} />
                            
                        </Grid>
                    </Grid>


                   {organisationData &&  <WSInfocard header="Details" data={organisationData} column={columns} labelStyle={{ color: 'black' ,fontSize:'0.875rem'}} />}
                </Paper>
                </>

            ) : (
                <PermissionDenied />
            )}
        </>
    );
});

class GlobalDataManager {
    // Static variable to store the data
    private static globalData: any = null;

    // Method to set data
    static setData(data: any) {
        GlobalDataManager.globalData = null;
        GlobalDataManager.globalData = data;
    }

    // Method to get data
    static getData() {
        return GlobalDataManager.globalData;
    }

    // Method to clear the data
    static clearData() {
        GlobalDataManager.globalData = null;
    }
}

export default GlobalDataManager;

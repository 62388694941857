import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { FormProvider, useForm } from 'react-hook-form';
import WSCommonDialog, { IMetaData } from './WSCommonDialog';

let modalRoot: HTMLElement | null = document.getElementById('modal-root');

if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.id = 'modal-root';
    document.body.appendChild(modalRoot);
}

interface OpenPopUpProps {
    metaData: IMetaData;
    handleClose: () => void;
    handleSubmits: (data: any) => void;
    popUpWidth?: string
}

const OpenPopUp: React.FC<OpenPopUpProps> = ({ metaData, handleClose, handleSubmits, popUpWidth }) => {
    const methods = useForm();
    const { control, reset, handleSubmit, register, formState: { errors },setValue } = methods;
    const [FormComponent, setFormComponent] = useState<React.FC<any> | null>(null);

    useEffect(() => {
        if (metaData?.content) {
            setFormComponent(() => metaData.content);
        }
    }, [metaData?.content])

    const handleCloseDialog = () => {
        handleClose();
    };

    const onSubmit = (data: any) => {
        handleSubmits(data);
        handleCloseDialog();
    };

    return createPortal(
        <FormProvider {...methods}>
            <WSCommonDialog
                title={metaData.title}
                popUpWidth={popUpWidth}
                content={
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {FormComponent && (
                            <FormComponent reset={reset} id="test-data" control={control} errors={errors} register={register} setValue={setValue} />
                        )}
                        <div>
                            <Button type="submit" style={{ display: 'none' }}>Submit</Button>
                        </div>
                    </form>
                }
                actions={metaData.actions.map((action) => {
                    if (action.type === 'submit') {
                        return { ...action, onClick: handleSubmit(onSubmit) };
                    }
                    if (action.type === 'button') {
                        return { ...action, onClick: handleCloseDialog };
                    }
                    return action;
                })}
                open={true}
                handleClose={handleCloseDialog}
                isDragable={metaData.isDragable}
                hideActions={metaData.hideActions}
            />
        </FormProvider>,
        modalRoot as Element
    );
};

export const openPopUp = (metaData: IMetaData, onSubmit: (data: any) => void, popUpWidth?: string) => {
    const root = createRoot(modalRoot as Element);

    const handleClose = () => {
        root.unmount();
        // if (onClose) onClose();
    };

    root.render(<OpenPopUp popUpWidth={popUpWidth} metaData={metaData} handleClose={handleClose} handleSubmits={onSubmit} />);
};

import { Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI, clearRequest, IsSmallScreen, Method } from "../../commonFunctions/CommonFunctions";
import WSButton from "../../Components/Button/WSButton";
import WSSelectField from "../../Components/Select/WSSelect";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import WSTextField, { TextType } from "../../Components/TextBox/WSTextBox";
import { Header } from "../Header/Header";
const MobLeaveType: React.FC<any> = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue, watch, reset } = useForm();
    const params = useParams();
    const id = params?.id;
    const navigate = useNavigate();
    const [leaveMethods, setLeaveMethods] = useState<any>([])
    const isMobile = IsSmallScreen()
    const { showMessage } = useSnackbar();
    useEffect(
        () => () => {
            reset();
        },
        [id, reset]
    );
    useEffect(() => {
        async function getLeaveMethods() {
            const response: any = await callAPI("LeaveMaster", Method.GET, {});
            if (response && response?.length > 0) {
                let leaveMethods: any = []
                response?.map((item: any) => {
                    let method: any = {};
                    method.label = item?.name;
                    method.value = item?.id + ',' + item?.code
                    leaveMethods.push(method)
                })

                setLeaveMethods(leaveMethods)
            }
        }
        getLeaveMethods();
    }, [])
    useEffect(() => {
        /**
         * Edit Function
         * @param id
         */
        async function getLeaveType(id: any) {
            try {
                const response: any = await callAPI("leaveType", Method.GET, {}, id);
                if (response?.data) {
                    setValue("leavetype", response.data?.name);
                    setValue("leavecount", response.data?.leave);
                    setValue("leaveMasterId", response.data?.leaveMasterId);
                    setValue("leavetypeId", response.data?.id);
                    // setValue("leaveMasterId", response.data.id);
                }
            } catch (error) {
                console.log("failed to retrieve data", error);
            }
        }
        if (id) {
            getLeaveType(id);
        }
    }, [id, leaveMethods]);

    const onSubmit = async (data: any) => {
        try {
            const splitLeaveMaster = data?.leaveMasterId?.split(',') || []
            if (splitLeaveMaster.length === 0) {
              showMessage('Laeve method Code is reqired.', 'error')
              return;
            }
    
            let request = {
              name: data?.leavetype,
              leave: data?.leavecount,
              leaveMasterId: splitLeaveMaster[0],
              code: splitLeaveMaster[1]
            };
            let newRequest: any = clearRequest(request);
            if (data?.leavetypeId && data?.leavetypeId !== "") {
              const response = await callAPI(
                "leavetype/" + data.leavetypeId,
                Method.PUT,
                newRequest
              );
             
              showMessage(
                response?.message,
                response?.success === true ? "success" : "error"
              );
            } else {
              const response = await callAPI("leavetype", Method.POST, newRequest);
             
              showMessage(
                response?.message,
                response?.success === true ? "success" : "error"
              );
            }
          } catch (error) {
            console.error("Failed to post data", error);
          }

    };
    const handleCancelClick = () => {
        navigate('/leavetypes')
    }
    return (
        // <Box sx={{ padding: "20px", position: "relative", margin: "auto", marginTop: "50px" }}>
        <> <Header title={id ? 'Edit Leave Type' : 'Add Leave Type'} type="simple" url="/leavetypes" isBackButton={true} isDivider={true}/>
      
            <Paper elevation={1} style={{ paddingLeft: 10, paddingRight: 0, marginTop: 0, paddingBottom: 20, border: '1px solid #ccc', borderRadius: '8px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid xs={12} className="modal-input" container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={12}>
                            {<WSSelectField
                                options={leaveMethods}
                                rules={{ required: true }}
                                {...register("leaveMasterId")}
                                id="leaveMasterId"
                                isMobile={isMobile}
                                control={control}
                                label="Leave Method"
                                name="leaveMasterId"
                            //labelType="Normal"
                            ></WSSelectField>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <WSTextField
                                control={control}
                                id="leavetype"
                                {...register("leavetype")}
                                rules={{ required: true }}
                                name="leavetype"
                                label="Leave Type *"
                            //labelType="Normal"
                            />
                            {errors.description && (
                                <Typography variant="body2" color="error">
                                    Leave Type is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <WSTextField
                                control={control}
                                id="leavecount"
                                {...register("leavecount")}
                                rules={{ required: true }}
                                name="leavecount"
                                label="Leave Count *"
                                //labelType="Normal"
                                type={TextType.Number}
                            />
                            {errors.description && (
                                <Typography variant="body2" color="error">
                                    Leave Count is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid style={{ display: "none" }}>
                            <WSTextField
                                control={control}
                                id="leavetypeId"
                                {...register("leavetypeId")}
                                rules={{ required: false }}
                                name="leavetypeId"
                                label="leavetypeId"
                                //labelType="Normal"
                                type={TextType.Number}
                            />
                        </Grid>
                    </Grid>

                <Grid item container spacing={1} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                    <Grid item sx={{ marginRight: 1 }} >
                        <WSButton id='submit' label={"Save"} type="submit" size="small" />
                    </Grid>
                    <Grid item sx={{ marginRight: 2 }}>
                        <WSButton id='cancel' label={"Cancel"} type="button" onClick={handleCancelClick} size="small" className="ws-cancel" />
                    </Grid>
                </Grid>
            </form>
           
        </Paper >
        </>

        // </Box>
    )
}
export default MobLeaveType;
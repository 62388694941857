/**
 * Component   : AddOrganization
 * Description : Adding a new organisaion for firstime
 *               Later will only do edit
 */
import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI, clearRequest, convertToBase64, customValidation, imgType, IRoleMain, MasterPages, Method, validations } from "../../../commonFunctions/CommonFunctions";
import PermissionDenied from "../../../commonFunctions/PermissionDenied";
import { getPermissions } from "../../../commonFunctions/PermissionManger";
import WSButton from "../../../Components/Button/WSButton";
import WSFileUpload from "../../../Components/FileUpload/WSFileUpload";
import WSSelectField from "../../../Components/Select/WSSelect";
import { useSnackbar } from '../../../Components/Snackbar/SnackBarContext';
import WSTextField from "../../../Components/TextBox/WSTextBox";
import config from '../../../Config/clientConfig.json';
import { Header } from "../../Header/Header";
import { IOrganization } from "../../Interfaces/Interfaces";

export const AddOrganization: React.FC<any> = React.memo((props) => {
    const { showMessage } = useSnackbar();
    const { register, handleSubmit, control, formState: { }, setValue } = useForm();
    const [base64Image, setBase64Image] = useState<string | null>(null);
    const [logoBase64Image, setLogoBase64Image] = useState<string | null>(null);
    const [imageType, setImageType] = useState<string>();
    const [addressId, setAddressId] = useState();
    const [id, setID] = useState();
    const navigate = useNavigate();
    const params = useParams()

    const { add, edit } = getPermissions(MasterPages.Organisactions, IRoleMain.Transactions)
    useEffect(() => {
        /**
         * Metod to get the organization record by id for edit purpose
         * @param id 
         */
        async function getOrganization(): Promise<void> {
            try {
                const response: any = await callAPI('organisation', Method.GET, {});
                if (response && response.success === true && response.data) {
                    const maindata = Array.isArray(response?.data) && response?.data.length>0 ? response?.data[0] : response?.data;
                    setID(maindata?.id)
                    for (const field in maindata) {
                        if (field === 'addresses' && Array.isArray(maindata?.addresses)) {
                            setAddressId(maindata.addresses[0]['id'])
                            for (let address in maindata.addresses[0])

                                setValue(address, maindata.addresses[0][address]);
                        } else if (field === 'logo') {
                            const base64Response: any = imgType(maindata.sentot) + maindata.logo;
                            setBase64Image(base64Response)
                        } else {
                            setValue(field, (maindata as any)[field]);
                        }
                    }
                }
            } catch (error) {
                showMessage('Error fetching organization:', 'error');
            }
        }
        getOrganization();
    }, [])

    const handleCancelClick = () => {
        navigate("/organization/view")
    }
    /**
     * Method for Add/update the data
     * @param data 
     */
    const onSubmit = async (data: any) => {
        try {
            const request: any = setRequest(data);
            if (id) {
                const response: any = await callAPI(`organisation/${id}`, Method.PUT, request);
                if (response) {
                    showMessage(response?.message, response.success === true ? "success" : "error")
                    navigate('/organization/view')
                }
            } else {
                const response: any = await callAPI('organisation', Method.POST, request);
                if (response) {
                    showMessage(response?.message, response.success === true ? "success" : "error")
                    navigate('/organization/view')
                }
            }
        }
        catch (e: any) {
            showMessage(e.error)
        }
    }

    const handleUploadChange = async (uploadedFile: any) => {
        const file: any = uploadedFile[0];
        try {
            let base64: any = await convertToBase64(file);
            setImageType(file?.type)
            let newBase64 = base64.replace(imgType(file?.type), "")
            setLogoBase64Image(newBase64)
        } catch (error) {
            showMessage("Error converting file to base64:", 'error');
        }
    }
    function setRequest(data: any) {
        let request: IOrganization = {
            organisationName: data?.organisationName,
            addresses: [{
                addressLine1: data?.addressLine1,
                addressLine2: data?.addressLine2 ? data?.addressLine2 : undefined,
                city: data?.city,
                state: data?.state,
                pincode: data?.pincode,
            }],
            dobf: data?.dobf,
            email: data?.email,
            industry: Number(data?.industry),
            location: data?.location?.toString(),
            sentot: imageType,
            logo: logoBase64Image
        }
        if (id && addressId && request.addresses) {
            request.addresses[0]['id'] = addressId
        }
        return clearRequest(request)
    }

    return (
        <><Header title={id ? "Edit Organization" : "Add Organization"} type="simple" url="/organization/view" isBackButton={true} isDivider={true} />
            {add && edit ? <Paper elevation={2} style={{ padding: '25px', margin: '0px', border: '1px solid #ccc', borderRadius: '8px' }}>  <Grid item container spacing={1}>
                {/* <Grid item sm={12}>
                    <Typography className="header-text" >
                        {id ? "Edit" : "Add"}
                    </Typography>
                </Grid>
                <Grid item sm={12}>
                    <Divider sx={{ marginY: 2 }} />

                </Grid> */}
            </Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                <Grid xs={12} className="modal-input" container spacing={2} style={{ marginTop: 10 }}>
                        {<Grid item container sm={4}>
                            <WSFileUpload
                                id="document-upload"
                                label="Organization Logo"
                                name="document"
                                imgUrl={base64Image}
                                control={control}
                                rules={{ required: base64Image ? false : 'File is required' }}
                                acceptFileTypes=".jpg,.png"
                                maxFileSizeMB={20}
                                multiple={false}
                                image={true}
                                align="Start"
                                onChange={handleUploadChange}
                            />
                        </Grid>}
                        <Grid item sm={12}>
                            <WSTextField control={control} id="organisationName" {...register('organisationName')} rules={{ required: true }} name="organisationName" label="Organization Name *"
                            //  labelType="Normal" 
                             description={{
                                text: 'This is your registered business name which will appear in all the forms and payslips .', align: 'Bottom', style: {
                                    color: 'gray',marginBottom: 10
                                }
                            }}></WSTextField>
                        </Grid>
                        <Grid item sm={6}>
                            <WSSelectField label="Business Location *" options={config.businessConfig.staticOptionData} 
                            // labelType="Normal"
                             control={control} id="location" defaultValue={config.businessConfig.staticOptionData[1]?.value} {...register('location')} rules={{ required: false }} disabled={false} name="location" ></WSSelectField>
                        </Grid>
                        <Grid item sm={6}>
                            <WSSelectField label="Industry *" options={config.studentConfig.staticOptionData} rules={{ required: true }} 
                            // labelType="Normal" 
                            control={control} defaultValue={config.studentConfig.staticOptionData[0]?.value} id="industry" {...register('industry')} name="industry" ></WSSelectField>
                        </Grid>
                        <Grid item sm={12}>
                            <WSSelectField label="Date Format *"
                            //  labelType="Normal" 
                             rules={{ required: false }} control={control} id="dobf" {...register('dobf')} options={config.dateFormat.staticOptionData} defaultValue={config.dateFormat.staticOptionData[0].value} name="dobf" ></WSSelectField>
                        </Grid>
                        <Grid item sm={12}>
                            <WSTextField control={control} id="addressLine1" {...register('addressLine1')} rules={{ required: false }} name="addressLine1" label="Address Line1 *" description={{
                                text: 'This will be considered as the address of your primary work location .', align: 'Bottom', style: {
                                    color: 'gray', marginBottom: 10
                                }
                            }}></WSTextField>
                        </Grid>
                        <Grid item sm={12}>
                            <WSTextField

                                control={control} id="addressLine2"  {...register('addressLine2')} name="addressLine2" label="Address Line2"  ></WSTextField>
                        </Grid>
                        <Grid item sm={4}>
                            <WSSelectField label="State *" options={config.stateConfig.staticOptionData} rules={{ required: false }} defaultValue={config.stateConfig.staticOptionData[0].value} control={control} id="state" {...register('state')} name="state" ></WSSelectField>
                        </Grid>
                        <Grid item sm={4}>
                            <WSTextField control={control} rules={{ required: false }} id="city" {...register('city')} name="city" label="City *" ></WSTextField>
                        </Grid>
                        <Grid item sm={4}>
                            <WSTextField rules={{ required: false }} control={control} maxLength={6} id="pincode" {...register('pincode')} name="pincode" label="Pin Code *" ></WSTextField>
                        </Grid>
                        <Grid item sm={12}>
                            <WSTextField control={control} id="email" {...register('email')} name="email" rules={customValidation(false, validations.email)} label="Primary Contact Email Address "
                            //  labelType="Normal" 
                           />
                        </Grid>

                    </Grid>
                    <Grid item container spacing={2} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                        <Grid item sx={{ marginRight: 1 }}>
                            <WSButton id='submit'
                                label={"Save"}
                                type="submit"
                                size="medium"
                            />
                        </Grid>
                        <Grid item sx={{ marginRight: 2 }}>
                            <WSButton id='cancel'
                                label={"Cancel"}
                                type="button"
                                onClick={handleCancelClick}
                                size="medium" className="ws-cancel"
                            />
                        </Grid>
                    </Grid>
                </form>

            </Paper> : <PermissionDenied />}

        </>
    );
});

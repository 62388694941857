import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { InputAdornment, Link, Typography } from '@mui/material';
import TextField, { TextFieldPropsSizeOverrides } from '@mui/material/TextField';
import { OverridableStringUnion } from '@mui/types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
interface ControlledPhoneFieldBaseProps {
    label: any;
    id: string;
    name: string;
    className?: string;
    variant?: "standard" | "filled" | "outlined";
    disabled?: boolean;
    control: any;
    defaultValue?: any;
    rules?: any; // Adjust as per your actual rules type
    maxLength?: number;
    minLength?: number;
    labelType?: "Material" | "Normal";
    description?: IDescription;
    suffix?: ISuffix;
    handleSuffixClick?: (value:any,length:number) => void;
    isSuccess?: boolean;
    size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
}

interface ISuffix {
    label: string;
    type?: "Normal" | "Link";
    color?: string;
}

interface IDescription {
    text: string | any;
    align: "Top" | "Bottom";
    style?: React.CSSProperties;
    className?: string;
}

const WSPhone: React.FC<ControlledPhoneFieldBaseProps> = ({
    id,
    label,
    className,
    control,
    name,
    rules,
    variant = "outlined",
    disabled = false,
    defaultValue = '',
    maxLength,
    minLength,
    labelType = "Material",
    description,
    suffix,
    handleSuffixClick,
    isSuccess = false,
    size="small"
}) => {
    const [length, setLength] = useState<number>(0);
    const [otpValue, setOtpValue] = useState<string>();

    const formatPhoneNumber = (value: string) => {
        // Remove all non-numeric characters
        const numericValue = value.replace(/\D/g, '');

        // Format as XXX-XXX-XXXX
        if (numericValue.length <= 3) {
            return numericValue;
        } else if (numericValue.length <= 6) {
            return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}`;
        } else {
            return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
        }
    };

    const validatePhoneNumberInput = (event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
        const value = event.target.value;
        const formattedValue = formatPhoneNumber(value);

        onChange(formattedValue);
        setOtpValue(formattedValue)
        setLength(formattedValue.length); // Update length state
      
    };
    const handleSuffixClicked=()=>{
        if(handleSuffixClick && otpValue && length==12){
            handleSuffixClick(otpValue,length)
        }
    }
    const getSuffix = (suffix?: ISuffix) => {
        if (!suffix) return null;

        if (suffix.type === 'Link') {
            return (
                <Link onClick={handleSuffixClicked} style={{ color: suffix.color || 'inherit', cursor: 'pointer' }} rel="noopener noreferrer">
                    {suffix.label}
                </Link>
            );
        }

        return <span style={{ color: isSuccess? 'green' :suffix.color }}>{suffix.label}</span>;
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field, fieldState }) => (
                <>
                    {description && description.align === "Top" && (
                        <Typography style={description.style}>{description.text}</Typography>
                    )}
                    {labelType === 'Normal' && (
                        <label style={{ display: 'block', marginBottom: '0.875rem',fontSize:'0.875rem' }} htmlFor={id}>
                            {label}
                        </label>
                    )}
                    {description && description.align === "Bottom" && (
                        <Typography style={description.style} className={description?.className}>
                            {description.text}
                        </Typography>
                    )}
                    <TextField
                        id={id}
                        size={size}
                        sx={ {
                            '& .MuiInputBase-input': {
                                fontSize: '0.875rem', // Reduce the font size
                            },
                            '& .MuiFormHelperText-root': {
                                fontSize: '0.75rem', // Reduce the font size of the error message
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '0.875rem', // Reduce the font size of the label
                            },
                        }}
                        fullWidth
                        {...field}
                        className={fieldState.invalid ? 'WSError' : className}
                        label={labelType === 'Material' ? label : undefined}
                        variant={variant}
                        disabled={disabled}
                        type='text' // Ensure proper type
                        error={fieldState.invalid}
                        value={field.value}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                        inputProps={{ maxLength, minLength, inputMode: 'tel' }}
                       // onClick={handleSuffixClicked}
                        InputProps={{
                            endAdornment: length === 12 && suffix ? (
                                <>
                                    {!isSuccess && (
                                        <InputAdornment position="end" className="suffixAdornment">
                                            {getSuffix(suffix)}
                                        </InputAdornment>
                                    )}
                                    {isSuccess && (
                                        <InputAdornment position="end"className="suffixAdornmentSuccess">
                                            <CheckCircleOutlineIcon/><label  style={{color:'green',paddingLeft:'5px'}} >{'Verified'}</label>
                                        </InputAdornment>
                                    )}
                                </>
                            ) : null,
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            validatePhoneNumberInput(e, field.onChange);
                        }}
                    />
                </>
            )}
        />
    );
};

export default WSPhone;

import { Alert, Box, Snackbar } from "@mui/material";
import React from "react";

interface ISnackBarProps {
  message: string;
  open: boolean;
  onClose: () => void;
  vertical?: "top" | "bottom";
  horizontal?: "left" | "center" | "right";
  severity?: "error" | "warning" | "info" | "success"; // Using MUI's predefined severity levels for color
}

export const WSSnackBar: React.FC<ISnackBarProps> = React.memo((props) => {
  const { message, open, onClose, vertical = 'top', horizontal = 'center', severity = 'info' } = props;

  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={onClose}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert variant="filled" onClose={onClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

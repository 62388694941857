import React, { useEffect, useState } from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Sidebar/Navbar';
import { Outlet, useNavigate } from 'react-router-dom';
import NotificationBar, { INotificationData } from './NofificationBar';
import { getLocalValue, IsSmallScreen, LoacalVariables } from '../../commonFunctions/CommonFunctions';
import NotificationPopover from './NotificationPopover';

interface DashboardLayoutProps {
  children?: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const isMobile=IsSmallScreen()
  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] = useState(false);
  const [showNotificationBar, setShowNotificationBar] = useState(false);
  const [userData, setUserData] = useState<any>(getLocalValue(LoacalVariables?.EmployeeInfo, true))
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsCollapsed((prev) => !prev);
  };
  useEffect(() => {

  }, [userData?.announcements])
  const toggleNotificationDrawer = () => {
    setIsNotificationDrawerOpen((prev) => !prev);
  };
  const closeNotificationBar = () => {
    setIsNotificationDrawerOpen(false);
   
  };
  const notifcationFields: INotificationData[] = [
    {
      field: 'title',
      label: '',
      type: 'text',
     // isBorder: true,
      textStyle: { fontSize: '1rem', marginTop: 5, marginBottom: 5, fontWeight: 'bold' }
    },
    {
      field: 'description',
      label: '',
      type: 'text',
      textStyle: { fontSize: '0.875rem' }
    },
    {
      field: 'startDate',
      label: '',
      type: 'date',
      textStyle: { fontSize: '0.875rem', color: 'gray' },
      stringInBetween: {
        firstField: 'startDate',
        secondField: 'endDate',
        type: 'date',
        inbetWeenString: 'to',
        format: 'MMMM D, YYYY'
      }
    },

  ]
  const handleClick=()=>{
    navigate('/announcement/list')
  }
  return (
    <Box sx={{ display: 'flex' }}>
      {/* Pass the collapsed state to the Sidebar */}
      <Sidebar isCollapsed={isCollapsed} />

      <Box sx={{ flexGrow: 1 }}>
        <Navbar badgeContent={userData?.announcements?.length || userData?.announcement?.length || 0} toggleSidebar={toggleSidebar} toggleNotificationDrawer={toggleNotificationDrawer} />

        <Box component="main" sx={{ p: 3 }}>
          {/* This will render the child routes */}
          <Outlet />
        </Box>
      </Box>

      {/* Notification Drawer */}
      {/* <Drawer
        anchor="right"
        open={isNotificationDrawerOpen}
        onClose={toggleNotificationDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: 300,
            padding: 2,
            backgroundColor: '#edeff1',
          },
        }}
      > */}

        <Box>
          {/* Render notifications here */}
          {/* <Typography>Notification 1</Typography>
          <Typography>Notification 2</Typography>
          <Typography>Notification 3</Typography> */}
          {isNotificationDrawerOpen && <NotificationPopover rows={notifcationFields} data={userData?.announcements || userData?.announcement || []} title='Annoncements' id="dashboard-notification" onClose={closeNotificationBar}  onClicked={handleClick} />}
          
        </Box>
      {/* </Drawer> */}
    </Box>
  );
};

export default DashboardLayout;

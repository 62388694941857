import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { clearEmployeeLocalValue, EmployeeStorageVariable, IRoleMain, IsSmallScreen, localStorageVariable, MasterPages, setEmployeeLocalValues } from "../../commonFunctions/CommonFunctions";
import WSDataGrid, { IColumns } from "../../Components/DataGrid/WSDataGrid";
import WSMobViewDataGrid from "../../Components/DataGrid/WSMobViewDataGrid";
import { IRows } from "../../Components/Filter/WSFilterComponent";
import config from '../../Config/clientConfig.json';
import { Header } from "../Header/Header";
import { getPermissions } from "../../commonFunctions/PermissionManger";
import PermissionDenied from "../../commonFunctions/PermissionDenied";
export const EmployeeList: React.FC<any> = React.memo(
    (props) => {
        const navigate = useNavigate();
        const { view, exports, add, edit } = getPermissions(MasterPages.Employees, IRoleMain.Masters);
        const source = {
            url: "employee/empbasicinfo/search",
            page: 1,
            pageSize: 10,
            filter: [],
            sort: []
        };

        /**
         * Edit function
         * @param action 
         * @param row 
         */
        const handleAction = (action: string, row: any) => {

            if (action === "Edit") {
                clearEmployeeLocalValue(localStorageVariable.EmployeeMaster)

                const empid: string = row?.id;
                const masterIds: any = row?.masterId
                setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.basicId, empid)
                setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.bankInfoId, masterIds.bankId)
                setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.personalInfoId, masterIds.personalId)
                setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.salaryInfoId, masterIds.salaryId)
                setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.leaveInfoId, masterIds.leaveId)
                navigate('/employee')
            }
        };

        const columns: IColumns[] = [
            {
                header: "Name",
                fieldName: "name",
                tooltip: true,
                sortable: true,
                sortKey: 'firstName',
                type:'default',
            },
            {
                header: "EID",
                fieldName: "empId",
                tooltip: true,
                sortable: true,
                type:'default',
            },
            {
                header: "DOJ",
                fieldName: "dateOfJoining",
                tooltip: true,
                type:'default',
            },
            {
                header: "Gender",
                fieldName: "sex",
                type:'default',
            },
            {
                header: "Actions",
                fieldName: "actions",
                type: "action",
                actions: [edit ? "Edit" : undefined],
                actionCallback: (action, row) => handleAction(action, row),
            },
        ];



        const filterRows: IRows[] = [
            {
                label: "First Name",
                fieldName: "FirstName",
                type: "text",
                operator: 'like',
                rowDivision:6
            },
            {
                label: "Last Name",
                fieldName: "LastName",
                type: "text",
                operator: 'like',
                rowDivision:6
            },

        ];
        const transFormData = (data: any) => {
            data && data?.map((item: any) => {
                const genderObj = config.genderConfig.staticOptionData.find((gender: any) => gender.value == item.gender)
                item.name = `${item?.firstName} ${item?.middleName ? item?.middleName : ''} ${item?.lastName}`
                item.sex = genderObj?.label;
            })
            return data
        }
        const handleClick = () => {
            clearEmployeeLocalValue(localStorageVariable.EmployeeMaster)
            navigate('/employee')
        }
        return (
            <><Header title="Employees" type="simple" isDivider={true} />
                {view ?<Grid container spacing={2} >
                    {!IsSmallScreen() ? <Grid item xs={12}>
                        <WSDataGrid
                            transFormData={transFormData}
                            id="employeeloist"
                            title="Employees"
                            filterFields={filterRows}
                            columns={columns}
                            source={source}
                            exportCsv={exports}
                            addButton={add ? { label: '', color: 'secondary' } : undefined}
                            onAddButtonClick={handleClick}
                        />
                    </Grid> : <Grid item xs={12}>
                        <WSMobViewDataGrid
                            transFormData={transFormData}
                            id="employeeloist"
                            title="Employees"
                            filterFields={filterRows}
                            columns={columns}
                            source={source}
                            exportCsv={exports}
                            addButton={add ? { label: '', color: 'secondary' } : undefined}
                            onAddButtonClick={handleClick}
                        />
                    </Grid>}
                </Grid>:<PermissionDenied/>}

            </>
        );
    }
);





/**
 * Component  : CreateHoliday
 * Usage      : Add holidays
 */
import { Grid, Paper } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI, clearRequest, getLocalValue, IRoleMain, IsSmallScreen, LoacalVariables, MasterPages, Method } from "../../commonFunctions/CommonFunctions";
import PermissionDenied from "../../commonFunctions/PermissionDenied";
import { getPermissions } from "../../commonFunctions/PermissionManger";
import WSButton from "../../Components/Button/WSButton";
import WSSCheckBoxField from "../../Components/CheckBox/WSCheckBox";
import WSDateField from "../../Components/DateField/WSDateField";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import WSTextField from "../../Components/TextBox/WSTextBox";
import { Header } from "../Header/Header";
import { IHoliday } from "../Interfaces/Interfaces";

const CreateHoliday: React.FC<any> = React.memo((props) => {
    const { control, reset, handleSubmit, register, formState: { errors, isSubmitted, isSubmitting, }, trigger, watch, setValue, getValues, unregister } = useForm<any>();
    const userType: any = getLocalValue(LoacalVariables.UserType);
    const { view, exports, edit, add } = getPermissions(MasterPages.Holidays, IRoleMain.Masters)
    const params = useParams();
    const id = params?.id
    const isMobile=IsSmallScreen()
    const { showMessage } = useSnackbar();
    const navigate = useNavigate();
    useEffect(() => () => {
        reset()
    }, [reset])
    useEffect(() => {
        /**
         * Method to get the data for editing pupose
         */
        try {
            async function getData() {
                let response = await callAPI(`NationalHoliday`, Method.GET, {}, id)
                const holidayInfo: IHoliday = response?.data
                if (setValue && holidayInfo) {
                    setValue("name", holidayInfo.name)
                    setValue("date", dayjs(holidayInfo.date).format("MM/DD/YYYY"))
                    setValue("isPublicHoliday", holidayInfo?.isPublicHoliday)
                }
            }
            if (id) {
                getData();
            }
        } catch (e) {

        }
    }, [id])


    const onSubmit = async (data: any) => {
        try {
            if (!id) {
                createHoliday(data)
            } else {
                updateHoliday(data);
            }
        } catch (error) {
            showMessage("Failed to create Holiday", 'error');
        }
        finally {

        }

    };
    /**
     * Add department
     * @param data 
     */
    const createHoliday = async (data: IHoliday) => {
        try {
            let request: IHoliday = {
                name: data.name,
                date: dayjs(data.date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                isPublicHoliday: data.isPublicHoliday ? data.isPublicHoliday : false,
                remindmail: 0
            };

            let newRequest: any = clearRequest(request);
            const response = await callAPI("NationalHoliday", Method.POST, newRequest);
            showMessage(
                response?.message,
                response?.success == true ? "success" : "error"
            );
            response?.success && navigate('/holiday')
        } catch (error) {
            showMessage("Failed to create Holiday", 'error')

        }
    };
    const updateHoliday = async (data: IHoliday) => {
        try {
            let request: IHoliday = {
                id: id,
                name: data.name,
                date: dayjs(data.date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                isPublicHoliday: data.isPublicHoliday ? data.isPublicHoliday : false,
                remindmail: 0
            };

            let newRequest: any = clearRequest(request);
            const response = await callAPI("NationalHoliday", Method.PUT, newRequest);
            showMessage(
                response?.message,
                response?.success == true ? "success" : "error"
            );
            response?.success && navigate('/holiday')
        } catch (error) {
            showMessage("Failed to update Holiday", 'error')

        }
    };
    const handleCancelClick = () => {
        navigate('/holiday')
    }

    return (
        <> {(userType === 'admin' && (add && !id || (edit && id))) ?
            <><Header title={id ? "Edit Holiday" : "Add Holiday"} type="simple" url="/holiday" isBackButton={true} isDivider={true}/>
              
                <Paper elevation={1} style={{ paddingLeft: 10, paddingRight: 0, marginTop: 0, paddingBottom: 20, border: '1px solid #ccc', borderRadius: '8px' }}>
                    {/* <Grid item container spacing={1}>
                    <Grid item sm={12}>
                        <Typography className="header-text" >
                            {id ? "Edit Holiday" : "Add Holiday"}
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Divider sx={{ marginY: 2 }} />
                        <br />
                    </Grid>
                </Grid> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid xs={12} className="modal-input" container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item sm={6} xs={12}>
                                <WSTextField
                                    id="name"
                                    label="Holiday"
                                    control={control}
                                    {...register('name')}
                                    rules={{ required: true }}
                                    maxLength={40}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <WSDateField
                                    id='date'
                                    name='date'
                                    //labelType='Normal'
                                    label={'Date'}
                                    dateFormat="MM/DD/YYYY"
                                    setValue={setValue}
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    className="WSTextField"
                                />

                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <WSSCheckBoxField
                                    id="isPublicHoliday"
                                    {...register('isPublicHoliday')}
                                    label="Is Public Holiday"
                                    control={control}
                                    isMobile={isMobile}
                                    rules={{ required: false }}
                                    defaultValue={false}
                                />
                            </Grid>

                        </Grid>
                        <Grid item container spacing={2} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                            <Grid item sx={{ marginRight: 1 }}>
                                <WSButton id='submit' label={"Save"} type="submit" size="medium" />
                            </Grid>
                            <Grid item sx={{ marginRight: 2 }}>
                                <WSButton id='cancel' label={"Cancel"} type="button" onClick={handleCancelClick} size="medium" className="ws-cancel" />
                            </Grid>
                        </Grid>
                    </form>
                </Paper></> : <PermissionDenied />}
        </>
    );
})
export default CreateHoliday;

import { Alert, Divider, Grid, Paper, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI, clearRequest, Method } from "../../commonFunctions/CommonFunctions";
import WSButton from "../../Components/Button/WSButton";
import WSSelectField from "../../Components/Select/WSSelect";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import WSTextField from "../../Components/TextBox/WSTextBox";
import config from '../../Config/clientConfig.json';
import { Header } from "../Header/Header";
interface EditWorkLocationProps {


}
const MobWorkLocation: React.FC<EditWorkLocationProps> = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue, watch, reset } = useForm();
    const params = useParams();
    const id = params?.id;
    const navigate = useNavigate();
    useEffect(() => () => {
        reset()
    }, [id, reset])
    const [selectedState, setSelectedState] = useState<string>("");
    const { showMessage } = useSnackbar();
    useEffect(() => {
        /**
         * Edit Function
         * @param id 
         * @param addressid 
         */
        async function getWorkLocation(id: any) {
            try {
                const response: any = await callAPI('worklocation', Method.GET, {}, id);
                if (response && response?.data) {
                    const address = response.data?.addresses[0] || {};
                    setValue("worklocationname", response.data.workLocationName);
                    setValue("addressId", address.id);
                    setValue("address", address.addressLine1);
                    setValue("addressline2", address.addressLine2);
                    setValue("selectstate", address.state);
                    setSelectedState(address.state)
                    setValue("district", address.city);
                    setValue("postalcode", address.pincode);
                    setValue("id", response.data.id);
                }
            }

            catch (error) {
                console.log("failed to retrieve data", error);

            }

        }
        if (id) {
            getWorkLocation(id);

        }
    }, [id, setValue]);


    const handleStateChange = (event: ChangeEvent<{ value: unknown }>) => {
        setSelectedState(event.target.value as string);
    };

    const onSubmit = async (data: any) => {
        try {
            let request: any = {
                workLocationName: data.worklocationname,
                addresses: [{
                    id: data.addressId,
                    addressLine1: data.address,
                    addressLine2: data.addressline2,
                    state: data.selectstate,
                    city: data.district,
                    pincode: data.postalcode,
                }]
            };
            let newRequest: any = clearRequest(request);
            if (data?.id && data?.id !== "") {
                const response = await callAPI("worklocation/" + data.id, Method.PUT, newRequest)

                showMessage(response?.message, response?.success === true ? "success" : 'error')
            }
            else {
                const response = await callAPI("worklocation", Method.POST, newRequest);
                showMessage(response?.message, response?.success === true ? "success" : 'error')
            }

        } catch (error) {
            console.error("Failed to create Work Location", error);
        }

    };
    const handleCancelClick = () => {
        navigate('/locations')
    }
    return (
        // <Box sx={{ padding: "20px", position: "relative", margin: "auto", marginTop: "50px" }}>
        <> <Header title={id?'Edit Location':'Add Location'} type="simple" url="/locations" isDivider={true} isBackButton={true} />
      
            <Paper elevation={1}  style={{ paddingLeft:10,paddingRight:0,marginTop:0, paddingBottom:20,  border: '1px solid #ccc', borderRadius: '8px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid xs={12} className="modal-input" container spacing={2} style={{marginTop:10}}>
                        <Grid item xs={12}>
                            <WSTextField
                                control={control}
                                id="worklocationname"
                                {...register("worklocationname")}
                                rules={{ required: true }}
                                name="worklocationname"
                                label="Work location Name *"
                            //labelType="Normal"
                            />
                            {errors.description && (
                                <Typography variant="body2" color="error">
                                    Work Location Name is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <WSTextField
                                control={control}
                                id="address"
                                {...register("address")}
                                rules={{ required: true }}
                                name="address"
                                label="Address line1 *"
                            //labelType="Normal"
                            />
                            {errors.description && (
                                <Typography variant="body2" color="error">
                                    Address is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <WSTextField
                                control={control}
                                id="addressline2"
                                {...register("addressline2")}
                                rules={{ required: false }}
                                name="addressline2"
                                label="Address line2"
                            //labelType="Normal"
                            />
                            {errors.description && (
                                <Typography variant="body2" color="error">
                                    Address is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid item container xs={12} sm={4}>
                            <WSSelectField
                                options={config.stateConfig.staticOptionData}
                                rules={{ required: true }}
                                {...register("selectstate")}
                                id="select"
                                control={control}
                                defaultValue={"0"}
                                label="State *"
                                isMobile={true}
                                name="selectstate"
                                //labelType="Normal"
                                onChange={handleStateChange}
                            ></WSSelectField>
                            {errors.description && (
                                <Typography variant="body2" color="error">
                                    State is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <WSTextField
                                control={control}
                                id="district"
                                {...register("district")}
                                rules={{ required: true }}
                                name="district"
                                label="District *"
                            //labelType="Normal"
                            />
                            {errors.description && (
                                <Typography variant="body2" color="error">
                                    District is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <WSTextField
                                control={control}
                                id="postalcode"
                                {...register("postalcode")}
                                rules={{ required: true }}
                                name="postalcode"
                                label="PIN *"
                                //labelType="Normal"
                                maxLength={6}
                            />
                            {errors.description && (
                                <Typography variant="body2" color="error">
                                    Postal code is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid style={{ display: "none" }}>
                            <WSTextField
                                control={control}
                                id="id"
                                {...register("id")}
                                rules={{ required: false }}
                                name="id"
                                label=""
                            // defaultValue={designationId}
                            />
                        </Grid>
                        <Grid style={{ display: "none" }}>
                            <WSTextField
                                control={control}
                                id="addressId"
                                {...register("addressId")}
                                rules={{ required: false }}
                                name="id"
                                label=""
                            // defaultValue={designationId}
                            />
                        </Grid>

                    </Grid>
                    <Grid item container spacing={1} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                        <Grid item sx={{marginRight:1}} >
                            <WSButton id='submit' label={"Save"} type="submit" size="small" />
                        </Grid>
                        <Grid item sx={{marginRight:2}}>
                            <WSButton id='cancel' label={"Cancel"} type="button" onClick={handleCancelClick} size="small" className="ws-cancel" />
                        </Grid>
                    </Grid>
                </form>
                {selectedState === "Kerala" && (
                    <Alert severity="info" sx={{ mt: 2 }}>
                        Labour Welfare Fund is applicable for Kerala. If you've not configured it yet, configure it in Settings &gt; Statutory Components.
                    </Alert>
                )}
            </Paper >
        </>

        // </Box>
    )
}
export default MobWorkLocation;
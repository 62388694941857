import React, { useEffect } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';

interface ControlledCheckBoxFieldProps {
  label?: string;
  id: string;
  name: string;
  className?: string;
  disabled?: boolean;
  control: any;
  defaultValue?: any;
  rules: UseControllerProps['rules'];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  setValue?: any;
  reset?:any,
  isMobile?: boolean; 
}

const WSSCheckBoxField: React.FC<ControlledCheckBoxFieldProps> = ({
  id,
  label,
  className,
  control,
  name,
  rules,
  disabled = false,
  defaultValue = false,
  setValue,
  onChange,
  reset,
  isMobile=false
}) => {
  useEffect(() => {
    if (setValue) {
      setValue(name, defaultValue);
    }
  }, [setValue, name, defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}

      render={({ field, fieldState }) => (
        <FormControl className={className} disabled={disabled} error={!!fieldState.error}>
          <FormControlLabel
            control={
              isMobile ? (
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    if (onChange) {
                      onChange(event);
                    }
                    field.onChange(event.target.checked);
                  }}
                  sx={{
                    transform: 'scale(1.1)', // Adjust switch scale if needed
                    '& .MuiSwitch-switchBase': {
                      '&.Mui-checked': {
                        color: '#1976d2', // Customize switch color when checked
                      },
                    },
                  }}
                />
              ) :(<Checkbox
                {...field}
                checked={field.value}
                onChange={(event) => {

                  if (onChange) {
                    onChange(event)
                  }
                  field.onChange(event.target.checked);
                }}
                sx={{
                  transform: 'scale(1.1)', // Reduce the size of the checkbox
                  '& .MuiSvgIcon-root': {
                    fontSize: '1rem', // Adjust icon size
                  },
                }}

              // onChange={(event) => {
              //   field.onChange(event);
              //   if (onChange) {
              //     onChange(event);
              //   }
              // }}
              />
      )}
            label={<span style={{ fontSize: '0.875rem' }}>{label}</span>}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '0.875rem' // Adjust font size for label text
              }
            }}
          />
          {fieldState.error && <FormHelperText sx={{ fontSize: '0.75rem' }}>{fieldState.error.message}</FormHelperText>} {/* Reduced error message size */}
        </FormControl>
      )}
    />
  );
};

export default WSSCheckBoxField;

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};



function a11yProps(index: number) {
  return {
    id: `ws-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface TabData {
  label: string;
  content: React.ReactNode;
}

interface ControlledTabsProps {
  id:string
  control: any;
  name: string;
  defaultValue?: number;
  tabs: TabData[];
}

export const WSTabs: React.FC<ControlledTabsProps> = ({
  control,
  name,
  defaultValue = 0,
  tabs,
  id
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        const handleChange = (event: React.SyntheticEvent, newValue: number) => {
          field.onChange(newValue);
        };

        return (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={field.value}
                onChange={handleChange}
              >
                {tabs.map((tab, index) => (
                  <Tab key={index} label={tab.label} {...a11yProps(index)} />
                ))}
              </Tabs>
            </Box>
            {tabs.map((tab, index) => (
              <CustomTabPanel key={index} value={field.value} index={index}>
                {tab.content}
              </CustomTabPanel>
            ))}
          </Box>
        );
      }}
    />
  );
};


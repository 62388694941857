import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
import WSSelectField from "../../Components/Select/WSSelect";
import WSTextField, { TextType } from "../../Components/TextBox/WSTextBox";
import WSTextAreaField from "../../Components/WSTextArea/WSTextArea";
import { callAPI, IRoleMain, IsSmallScreen, MasterPages, Method } from "../../commonFunctions/CommonFunctions";
import PermissionDenied from "../../commonFunctions/PermissionDenied";
import { getPermissions } from "../../commonFunctions/PermissionManger";

interface AddDepartmentProps {
  control: Control<any>;
  errors: FieldErrors;
  register: UseFormRegister<any>;
  id: any;
  reset: UseFormReset<any>;
  departmentId?: string;
  setValue: any;
}
const AddDepartment: React.FC<AddDepartmentProps> = ({
  control, errors, register, id, reset, departmentId, setValue, }) => {
  const { add, edit } = getPermissions(MasterPages.Departments, IRoleMain.Masters)
  const isMobile=IsSmallScreen()
  useEffect(
    () => () => {
      reset();
    },
    [id, reset]
  );

  useEffect(() => {
    /**
     * edit function
     * @param id
     */
    try {
      async function getDepartment(id: any) {
        try {
          const response: any = await callAPI("department", Method.GET, {}, id);
          setValue("departmentName", response.data.departmentName);
          setValue("departmentCode", response.data.departmentCode);
          setValue("departmentHead", response.data.departmentHead);
          setValue("reportTo", response.data.reportTo);
          setValue("maxLeaveAllowed", response.data.maxLeaveAllowed);
          setValue("description", response.data.description);
          setValue("departmentId", response.data.id);
       
          
        } catch (error) {
          console.log("failed to retrieve data", error);
        }
      }
      if (departmentId) {
        getDepartment(departmentId);
      }
    }
    catch (e) {
    }
  }, [departmentId]);
  const employeeSource: any = { url:'employee/empbasicinfo/search', "page": 1, "pageSize": 10000, "filter": [], "sort": [] }
  const transFormData = ((data: any) => {
    let empList: Array<any> = []
    data && data?.map((item: any) => {
      empList.push({ label: `${item?.firstName} ${item?.middleName ? item?.middleName : ''} ${item?.lastName} `,value:item.id })
    })
    return empList
  })
  return (
    <>
      {
        add ?
          <Grid className="modal-input" container spacing={2}>
            <Grid item xs={12} sm={6}>
              <WSTextField
                control={control}
                id="departmentName"
                {...register("departmentName")}
                rules={{ required: true }}
                name="departmentName"
                label="Department Name *"
              //labelType="Normal"
              />
              {errors.departmentname && (
                <Typography variant="body2" color="error">
                  Department Name is required
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <WSTextField
                control={control}
                id="departmentCode"
                {...register("departmentCode")}
                rules={{ required: true }}
                name="departmentCode"
                label="Department Code *"
              //labelType="Normal"
              />
              {errors.departmentcode && (
                <Typography variant="body2" color="error">
                  Department Code is required
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <WSSelectField
                // options={config.departmentconfig.staticOptionData}
                source={employeeSource}
                transFormData={transFormData}
                rules={{ required: true }}
                {...register("departmentHead")}
                id="departmentHead"
                control={control}
                isMobile={isMobile}
                label="Department Head"
                name="departmentHead"
              //labelType="Normal"
              ></WSSelectField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <WSSelectField
                // options={config.reportconfig.staticOptionData}
                isMobile={isMobile}
                source={employeeSource}
                rules={{ required: true }}
                {...register("reportTo")}
                id="reportTo"
                control={control}
                label="Report To"
                name="reportTo"
                transFormData={transFormData}
              //labelType="Normal"
              />
            </Grid>
            <Grid item xs={12}>
              <WSTextField
                control={control}
                id="maxLeaveAllowed"
                {...register("maxLeaveAllowed")}
                rules={{ required: true }}
                name="maxLeaveAllowed"
                label="Max Leave Allowed *"

                //labelType="Normal"
                type={TextType.Number}
              />
              {errors.departmentcode && (
                <Typography variant="body2" color="error">
                  Max Leave is required
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <WSTextAreaField
                label="Description"
                id="description"
                control={control}
                {...register("description")}
                rules={{ required: true }}
                name="description"
              />
            </Grid>
            <Grid item xs={12} style={{ display: "none" }}>
              <WSTextField
                control={control}
                id="departmentId"
                {...register("departmentId")}
                rules={{ required: false }}
                name="departmentId"
                label="departmentId"
                //labelType="Normal"
                type={TextType.Number}
              />
            </Grid>
          </Grid>
          : <PermissionDenied />}</>

  );
};
export default AddDepartment;

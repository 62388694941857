import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import PermissionDenied from './commonFunctions/PermissionDenied';
import { AddDepartmentListingComponent } from './Pages/AddDepartmentListing/AddDepartmentListing';
import { ChangePassword } from './Pages/ChangePassword/ChangePassword';
import { DesignationListingComponent } from './Pages/DesignationList/DesignationList';
import { EditWorkLocationListingComponent } from './Pages/EditWorkLocationList/EditWorkLocationList';
import { EmployeeList } from './Pages/Employee/EmployeeList';
import EmployeeWizard from './Pages/Employee/EmployeeWizard';
import CreateHoliday from './Pages/Holiday/CreateHoliday';
import { HolidayList } from './Pages/Holiday/HolidayList';
import { LeaveApprovalList } from './Pages/Leave/LeaveApprovalList';
import { LeaveDetails } from './Pages/Leave/LeaveDetails';
import LeaveMain from './Pages/Leave/LeaveMain';
import LeaveRequestForm from './Pages/Leave/LeaveRequestForm';
import { LeaveTypeMasterListingComponent } from './Pages/LeaveTypeMasterList/LeaveTypeMasterList';
import { AddOrganization } from './Pages/Masters/Organizations/CreateOrganization';
import { ViewOrganization } from './Pages/Masters/Organizations/ViewOrganization';
import { ScheduleMeeting } from './Pages/Masters/ScheduleMeeting/ScheduleMeeting';
import { RolesManagement } from './Pages/Roles/RolesManagement';
import { UserDashboard } from './Pages/UserDashboard/UserDashboard';
import { UserManagement } from './Pages/Users/UserManagement';
import { UserManagementList } from './Pages/Users/UserManagementList';

import MobDepartment from './Pages/AddDepartmentListing/MobDepartment';
import MobDesignation from './Pages/DesignationList/MobDesignation';
import MobWorkLocation from './Pages/EditWorkLocationList/MobWorkLocation';
import MobLeaveType from './Pages/LeaveTypeMasterList/MobLeaveType';
import { Announcement } from './Pages/Masters/ScheduleMeeting/Announcement';
import { AnnouncementList } from './Pages/Masters/ScheduleMeeting/AnnouncementList';
import { ScheduleMeetingList } from './Pages/Masters/ScheduleMeeting/ScheduleMeetingList';



interface PrivateRouteProps {
  token: string | null;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ token }) => {
  if (!token) {
    return token ? <Outlet /> : <Navigate to="/login" />;
  }

  return (
    <Routes>
        {/* <Route path="/" element={<Layout />}/> */}
        <Route path="locations" element={<EditWorkLocationListingComponent />} />
        <Route path="locations/add" element={<MobWorkLocation   />} />
        <Route path="locations/edit/:id" element={<MobWorkLocation   />} />
        
        
          <Route path="designations" element={<DesignationListingComponent />} />
          <Route path="designations/add" element={<MobDesignation   />} />
          <Route path="designations/edit/:id" element={<MobDesignation   />} />
          <Route path="departments" element={<AddDepartmentListingComponent />} />
          <Route path="departments/add" element={<MobDepartment   />} />
          <Route path="departments/edit/:id" element={<MobDepartment   />} />

          
          <Route path="leavetypes" element={<LeaveTypeMasterListingComponent />} />
          <Route path="leave-type/add" element={<MobLeaveType   />} />
          <Route path="leave-type/edit/:id" element={<MobLeaveType   />} />

          
          <Route path="employee" element={<EmployeeWizard />} />
          <Route path="employee/list" element={<EmployeeList />} />
          <Route path="organization/add" element={<AddOrganization />} />
          <Route path="organization/view" element={<ViewOrganization />} />
          <Route path="organization/edit/:id" element={<AddOrganization />} />
          <Route path="user/list" element={<UserManagementList />} />
          <Route path="user/add" element={<UserManagement />} />
          <Route path="user/:id" element={<UserManagement />} />
          {/* <Route path="roles/list" element={<RolesList/>} /> */}
          <Route path="roles/add" element={<RolesManagement/>} />
          <Route path="/home" element={<UserDashboard/>} />
          <Route path="/leave" element={<LeaveApprovalList/>} />
          <Route path="/leave/request" element={<LeaveRequestForm id="leaveRequest"/>} />
          <Route path="/changepassword" element={<ChangePassword id="changePassword"/>} />
          <Route path="/leave/actions" element={<LeaveMain/>} />
          <Route path="/leave/details" element={<LeaveDetails id='leave' leaveData={{}}/>} />
          <Route path="/holiday" element={<HolidayList/>} />
          <Route path="/holiday/create" element={<CreateHoliday id="add-holiday"/>} />
          <Route path="/holiday/edit/:id" element={<CreateHoliday id="edit-holiday"/>} />
          <Route path="/schedule" element={<ScheduleMeeting id="schedule"/>} />
          <Route path="/schedule/add" element={<ScheduleMeeting id="schedule-add"/>} />
          <Route path="/schedule/edit/:id" element={<ScheduleMeeting id="schedule-edit"/>} />
          <Route path="/announcement" element={<Announcement id="announcement"/>} />
          <Route path="/announcement/add" element={<Announcement id="announcement-add"/>} />
          <Route path="/announcement/edit/:id" element={<Announcement id="announcement-edit"/>} />
          <Route path="/schedule/list" element={<ScheduleMeetingList id="schedule-list"/>} />
          <Route path="/announcement/list" element={<AnnouncementList id="announcement-list"/>} />
          <Route path="/error-page" element={<PermissionDenied/>} />
          
    </Routes>
  );
};

export default PrivateRoute;

import { Grid } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { callAPI, clearRequest, formatAddressObject, IRoleMain, IsSmallScreen, MasterPages, Method } from "../../commonFunctions/CommonFunctions";
import WSDataGrid, { IColumns } from "../../Components/DataGrid/WSDataGrid";
import { openPopUp } from "../../Components/Dialog/OpenNewModal";
import { IMetaData } from "../../Components/Dialog/WSCommonDialog";
import { IRows } from "../../Components/Filter/WSFilterComponent";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import { resetDataComponent } from "../../StateManagement/actions";
import EditWorkLocation from "./EditWorkLocation";

import { useNavigate } from "react-router-dom";
import PermissionDenied from "../../commonFunctions/PermissionDenied";
import { getPermissions } from "../../commonFunctions/PermissionManger";
import WSMobViewDataGrid from "../../Components/DataGrid/WSMobViewDataGrid";
import { Header } from "../Header/Header";

export const EditWorkLocationListingComponent: React.FC<any> = React.memo(
  (props) => {

    const { showMessage } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobileScreen = IsSmallScreen()
    const source = {
      url: "worklocation/search",
      page: 1,
      pageSize: 10,
      filter: [],
      sort: [],
    };
    const { view, exports, add, edit } = getPermissions(MasterPages.WorkLocations, IRoleMain.Masters);
    /**
     * converting data
     * @param data 
     * @returns 
     */
    const transFormData = (data: any) => {
      return data.map((item: any) => {
        const address = item.addresses[0];
        item.address = formatAddressObject(
          address
        );
        return item;
      });
    };

    const handleClick = () => {
      openPopUp(metadata, handleData, "40rem");
    };
    const handleBobClick = () => {
      navigate('/locations/add')
    }
    /**
     * Edit function
     * @param action 
     * @param row 
     */
    const handleAction = (action: string, row: any) => {
      if (action === "Edit" && !isMobileScreen) {
        const workid: string = row?.id;
        const metadataWorkLocation: IMetaData = {
          title: "Edit Work Location",
          hideActions: false,
          content: (props: any, row: any) => (
            <EditWorkLocation {...props} workLocationId={workid} />
          ),
          actions: [
            {
              label: "Cancel",
              className: "ws-cancel",
              type: "button",
            },
            {
              label: "submit",
              onClick: handleData,
              type: "submit",
              autoFocus: true,
            },
          ],
          isDragable: true,
        };
        openPopUp(metadataWorkLocation, handleData, "40rem");
      }
      else if (action === "Edit" && isMobileScreen) {
        navigate(`/locations/edit/${row?.id}`)
      }
    };


    /**
     * submitting data
     * @param data 
     */
    const handleData = async (data: any) => {
      try {
        let request: any = {
          workLocationName: data.worklocationname,
          addresses: [{
            id: data.addressId,
            addressLine1: data.address,
            addressLine2: data.addressline2,
            state: data.selectstate,
            city: data.district,
            pincode: data.postalcode,
          }]
        };
        let newRequest: any = clearRequest(request);
        if (data?.id && data?.id !== "") {
          const response = await callAPI("worklocation/" + data.id, Method.PUT, newRequest)
          dispatch(resetDataComponent('worklocationGrid'));
          showMessage(response?.message, response?.success === true ? "success" : 'error')
        }
        else {
          const response = await callAPI("worklocation", Method.POST, newRequest);
          dispatch(resetDataComponent('worklocationGrid'));
          showMessage(response?.message, response?.success === true ? "success" : 'error')
        }

      } catch (error) {
        console.error("Failed to create Work Location", error);
      }

    };

    const metadata: IMetaData = {
      title: " Work Location",
      hideActions: false,
      content: (props: any, row: any) => (
        <EditWorkLocation {...props} workLocationId={row?.id} />
      ),
      actions: [
        {
          label: "Cancel",
          // onClick: handleCloseDialog,
          className: "ws-cancel",
          type: "button",
        },
        {
          label: "Submit",
          onClick: handleData,
          type: "submit",
          autoFocus: true,
        },
      ],
      isDragable: true,
    };

    const columns: IColumns[] = [
      {
        header: "Location Name",
        fieldName: "workLocationName",
        tooltip: true,
        type:'default',
        sortable: true
      },
      {
        header: "Address",
        fieldName: "address",
        tooltip: true,
        type:'default',
      },
      {
        header: "Actions",
        fieldName: "actions",
        type: "action",
        actions: [edit ? "Edit" : undefined],
        actionCallback: (action, row) => handleAction(action, row),
      },
    ];



    const filterRows: IRows[] = [
      {
        label: "Location Name",
        fieldName: "WorkLocationName",
        type: "text",
        operator:'like'
      },
      
    ];
    return (
      <>
        <Header title="Location" type="simple" isDivider={true} />
        {view ?
          <Grid container spacing={2}>
            {!IsSmallScreen() ?
              <Grid item xs={12}>
                <WSDataGrid
                  transFormData={transFormData}
                  id="worklocationGrid"
                  title="Work Location"
                  filterFields={filterRows}
                  buttonStyle="Normal"
                  columns={columns}
                  source={source}
                  exportCsv={exports}
                  addButton={add ? { label: '', color: 'secondary' } : undefined}
                  onAddButtonClick={handleClick}
                /> </Grid> : <Grid item xs={12}>
                <WSMobViewDataGrid
                  transFormData={transFormData}
                  id="worklocationGrid"
                  title="Location List"
                  filterFields={filterRows}
                  buttonStyle="Normal"
                  columns={columns}
                  source={source}
                  exportCsv={exports}
                  addButton={add ? { label: '', color: 'secondary' } : undefined}
                  onAddButtonClick={handleBobClick}
                />

              </Grid>
            }
          </Grid>
          : <PermissionDenied />}
      </>
    );
  }
);





import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Controller, UseControllerProps } from 'react-hook-form';

interface ControlledTextAreaProps {
  label: string;
  id: string;
  name: string;
  className?: string;
  variant?: "standard" | "filled" | "outlined";
  disabled?: boolean;
  control: any;
  defaultValue?: any;
  rules?: UseControllerProps['rules'];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean; 
  setValue?: any;
  register?: any;
  maxLength?: number;
  minLength?: number;
  rows?: number;
  hide?:boolean;
}

const WSTextAreaField: React.FC<ControlledTextAreaProps> = ({
  id,
  label,
  className,
  control,
  name,
  rules,
  variant = "outlined",
  disabled = false,
  defaultValue = '',
  setValue,
  onChange,
  maxLength,
  minLength,
  rows = 4,
  hide=false
}) => {
  useEffect(() => {
    // Custom logic (if needed)
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <TextField
            id={id}
            fullWidth
            {...field}
            className={hide===true ? 'ws-hidden' : fieldState.invalid ? 'WSError' : className}
            label={label}
            variant={variant}
            disabled={disabled}
            error={fieldState.invalid}
            value={field.value}
            helperText={fieldState.error ? fieldState.error.message : ''}
            multiline
            rows={rows}
            inputProps={{ maxLength, minLength }}
          />
        );
      }}
    />
  );
};

export default WSTextAreaField;

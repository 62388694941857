import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

import WSButton from '../Button/WSButton';
import { WSStatus } from '../Status/WSStatus';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
interface ControlledCardProps {
    id: string;
    title: string;
    icon?: React.ElementType; // Icon component
    iconColor?: string;
    text?: string;
    value?: string;
    actionButtonText?: string;
    type?: 'Custom' | 'Normal' | 'Rows';
    onClick?: () => void;
    customContent?: React.ReactNode; // Allow custom content to be passed in
    data?: any
    fields?: IFields[];
    profileHeight?: number
}
export interface IFields {
    field: IField,
    text: IText;
    type?: 'Text' | 'Date' | 'Status',
    divider?: boolean
}
interface IField {
    name: string,
    style?: React.CSSProperties
}
interface IText {
    label: string,
    style?: React.CSSProperties
}
const WSMainCard: React.FC<ControlledCardProps> = ({
    id,
    title,
    icon: Icon, // Renaming for clarity
    text,
    value,
    actionButtonText,
    type,
    onClick,
    customContent,
    iconColor,
    data,
    fields,
    profileHeight,
}) => {
    const [screenHeight, setScreenHeight] = useState(profileHeight?profileHeight:window.outerHeight);
    const renderHeader = () => (
        <>
            <CardHeader
                title={
                    <Grid container spacing={2} alignItems="center">
                        {Icon && <Grid item sm={1}>
                            {Icon && <Icon style={{ color: iconColor ? iconColor : 'black' }} />} {/* Conditionally render the icon */}
                        </Grid>}
                        <Grid item sm={Icon ? 11 : 12}>
                            <Typography variant="h6" style={{ marginBottom: 5, fontWeight: 'bold', paddingLeft: 0, fontSize: '1.15rem' }}>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            />
            <Divider />
        </>
    );

    const renderContent = () => {
        switch (type) {
            case 'Custom':
                return customContent;
            case 'Rows':
                return (
                    <Grid item container spacing={1}>
                        {fields && fields.map((field: IFields, index: number) => (
                            <React.Fragment key={index}>
                                <Grid item sm={12} xs={12} sx={{ marginTop: index === 0 ? 2 : 0 }}>
                                    <Typography style={field?.text?.style ? field?.text?.style :
                                        { fontSize: '0.875rem', fontWeight: 'bold' }} >{field?.text?.label}</Typography>
                                </Grid>
                                {field.type === 'Text' && <Grid item sm={12}  xs={12} sx={{ paddingTop: 0 }}>
                                    <Typography style={field?.field?.style ? field?.field?.style : {
                                        fontSize: '0.875rem', color: 'gray'
                                    }}>{data && data[field?.field?.name] ? data[field?.field?.name] : '-'}</Typography>
                                </Grid>}
                                {field.type === 'Status' && <Grid item sm={12}  xs={12} sx={{ marginTop: 0 }}>
                                    <WSStatus value={data && data[field?.field?.name]} toolTip={true} />
                                </Grid>}
                                {field?.divider &&
                                    <Grid item sm={12}  xs={12}>
                                        <Divider />
                                    </Grid>
                                }
                            </React.Fragment>
                        ))}
                    </Grid>
                );

            case "Normal":
                return (
                    <>
                        {text && <Typography variant="body1" color={'GrayText'} style={{ fontWeight: 'bold' }}>{text}</Typography>}
                        {value && (
                            <Typography variant="h5" color={'#00008B'} sx={{ mt: 1, fontWeight: 'bold' }}>
                                {value}
                            </Typography>
                        )}
                    </>
                );
                break
            default:
                return (
                    <>
                        {text && <Typography variant="body1" color={'GrayText'} style={{ fontWeight: 'bold' }}>{text}</Typography>}
                        {value && (
                            <Typography variant="h5" color={'#00008B'} sx={{ mt: 1, fontWeight: 'bold' }}>
                                {value}
                            </Typography>
                        )}
                    </>
                );
        }
    };

    return (
        <Paper elevation={1} variant="elevation">
            <Box style={{ height:   screenHeight }} >

                <Card variant="outlined" onClick={onClick} style={{ height:  screenHeight }}>
                    {renderHeader()}
                    <CardContent sx={{ paddingTop: 0, height: type === 'Normal' || 'Rows' ? screenHeight : '' }}>{renderContent()}</CardContent>
                    <CardActions sx={{ paddingTop: 0 }}>
                        {actionButtonText && <Grid container justifyContent="end">
                            <WSButton id="btn" label={actionButtonText || 'Apply'} size="small" color='info' variant='outlined' />
                        </Grid>}
                    </CardActions>
                </Card>
            </Box>

        </Paper>
    );
};

export default WSMainCard;

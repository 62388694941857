import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { truncateString } from "../../commonFunctions/CommonFunctions";
// Define an interface for your component props
interface ViewProps {
    title: string;
    isTooltip?: boolean;
    style?:React.CSSProperties
    dotStartFrom?:number
}

// Define and export the memoized component in one line
export const WSView: React.FC<ViewProps> = React.memo((props) => {
    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            {props.isTooltip ? (
                <Tooltip title={props.title} placement="top">
                    <Typography style={props?.style}>{truncateString(props.title, props?.dotStartFrom || 20)}</Typography>
                </Tooltip>
            ) : (
                <Typography style={props?.style}>{props.title}</Typography>
            )}
        </div>
    );
});



import { Divider, Grid, MenuItem, Popover, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { WSView } from '../../Components/View/WSView';
import { IsSmallScreen } from '../../commonFunctions/CommonFunctions';
import './NotificationPopover.css';

// Define the interface for notification data
export interface INotificationData {
  field: string;
  label: string;
  type?: 'text' | 'date' | 'status' | 'link';
  align?: 'right' | 'left';
  isBorder?: boolean;
  format?: string;
  makeSameLine?: boolean;

  textStyle?: React.CSSProperties;
  stringInBetween?: {
    type: 'text' | 'date';
    inbetWeenString: string;
    firstField: string;
    secondField: string;
    format?: string;
  };
}

// Define the props for the NotificationPopover component
interface NotificationBarProps {
  id: string;
  title: string;
  onClose: () => void;
  rows: INotificationData[];
  data: any[];
  onClicked?:()=>void
}

const renderNotificationContent = (notification: any, rows: INotificationData[]) => {
  return rows.map((row, idx) => {
    const value = notification[row.field];
    switch (row.type) {
      case 'date':
        return (
          <>{!row?.stringInBetween && <Typography style={row?.textStyle} key={idx} align={row.align || 'left'} sx={{ flexGrow: 1 }}>
            {new Date(value).toLocaleDateString()}
          </Typography>}
            {row?.stringInBetween && <Typography style={row?.textStyle} key={idx} align={row.align || 'left'} sx={{ flexGrow: 1 }}>
              {row?.stringInBetween?.type === 'date' && ` ${dayjs(notification[row?.stringInBetween?.firstField]).format(row?.stringInBetween?.format || 'YYYY/MM/d')} ${row?.stringInBetween?.inbetWeenString} ${dayjs(notification[row?.stringInBetween?.secondField]).format(row?.stringInBetween?.format || 'YYYY/MM/d')}`}

            </Typography>}
          </>
        );
      case 'status':
        return (
          <Typography
            key={idx}
            style={row?.textStyle}
            align={row.align || 'left'}
            sx={{ flexGrow: 1, color: value === 'success' ? 'green' : 'red' }}
          >
            {value}
          </Typography>
        );
      case 'link':
        return (
          <Typography
            key={idx}
            style={row?.textStyle}
            align={row.align || 'left'}
            sx={{ flexGrow: 1, color: 'blue' }}
          >
            <a href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          </Typography>
        );
      default:
        return (
          <>
            <Grid item sm={12}>
              <WSView title={value} isTooltip={true} dotStartFrom={35} style={{ fontSize: '0.875rem' }} />
              {row?.isBorder && <Divider style={{ paddingTop: 5, marginBottom: 5 }} />}
            </Grid>
          </>

        );
    }
  });
};

const NotificationPopover: React.FC<NotificationBarProps> = ({ rows, data, title, id, onClose,onClicked }) => {
  const isMobile=IsSmallScreen()
  return (
    <Popover
      id={id}
      open={true}
      onClose={onClose}
      sx={{ top: 60, left:isMobile ?-50: -80 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className="notification-popover">
        <div>
          {data.length > 0 ? (
            data.map((notification, index) => (
              <div key={index}>
                <MenuItem onClick={() => { }} className="dropdown-item">
                  <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                    {/* Render notification fields based on the rows configuration */}
                    {renderNotificationContent(notification, rows)}
                  </div>
                </MenuItem>
                {index < data.length - 1 && <Divider />}
              </div>
            ))
          ) : (
            <Typography>No notifications available.</Typography>
          )}
          <MenuItem onClick={onClose}  className="dropdown-footer">
            <Grid container direction="column" alignItems="center" spacing={1}>
              <Grid item xs={12} style={{ width: '100%' }}>
                <Divider />
              </Grid>
              <Grid item>
                <Typography onClick={onClicked} variant="caption" color="Highlight" className="text-sm">
                  See all Messages
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        </div>
      </div>
    </Popover>
  );
};

export default NotificationPopover;

import React from 'react';
import Draggable from 'react-draggable';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Box, Divider, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface AlertDialogProps {
  title: string;
  content: React.ReactNode;
  actions: IDialogActions[];
  open: boolean;
  isDragable?: boolean;
  handleClose: () => void;
  hideActions?: boolean;
  popUpWidth?: string
}

export interface IMetaData {
  title: string;
  hideActions: boolean;
  content: any;
  isComponent?: boolean;
  actions: IDialogActions[];
  isDragable?: boolean;
  onChange?: (data: any) => void
}

interface IDialogActions {
  type?: "submit" | "button";
  label: string;
  onClick?: (data?: any) => any;
  className?: string;
  autoFocus?: boolean;
}

const PaperComponent = (props: any) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const WSCommonDialog: React.FC<AlertDialogProps> = ({ title, content, actions, open, handleClose, isDragable = false, hideActions = false, popUpWidth = '600' }) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={isDragable ? PaperComponent : undefined}
        aria-labelledby="draggable-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#fff',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: 4,
            boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
            margin: { xs: 1, sm: 2 },
            maxHeight: 'calc(100% - 64px)',
            maxWidth: popUpWidth,
          },
        }}
      >
        <DialogTitle style={isDragable ? { cursor: 'move' } : undefined} id="draggable-dialog-title" className='ws-dialog ws-dialog-title'>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

        </DialogTitle>
        <DialogContent>
          <Divider sx={{ paddingTop: 2 }} />
          {typeof content === 'string' ? (
            <DialogContentText id="alert-dialog-description" >
              {content}
            </DialogContentText>
          ) : (
            <Box marginTop={'-3rem!important'}>{content}</Box>
          )}
          <Box>
            <br />
            <Divider />
          </Box>
        </DialogContent>
        {!hideActions && (
          <DialogActions sx={{ paddingRight: 4, paddingBottom: 3 }}>
            {actions.map((action, index) => (
              <Box mr={1} key={index}>
                <Button
                  color='primary'
                  variant="contained"
                  size='small'
                  style={{ fontSize: 14 }}
                  type={action.type}
                  className={action.className ? action.className : ''}
                  onClick={action.onClick}
                  autoFocus={action.autoFocus}
                >
                  {action.label}
                </Button>
              </Box>
            ))}
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default WSCommonDialog;

import React, { useEffect } from 'react';
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material';

interface ControlledCheckBoxFieldProps {
  id: string;
  label: string;
  name: string;
  options: any[];
  rules?: UseControllerProps['rules'];
  className?: string;
  disabled?: boolean;
  defaultValue?: string[];
  onChange?: (selectedValues: string[]) => void;
  align?: 'horizontal' | 'vertical'; // New prop for alignment
}

const WSMultiCheckBox: React.FC<ControlledCheckBoxFieldProps> = ({
  id,
  label,
  name,
  options,
  defaultValue = [],
  rules,
  className,
  disabled = false,
  onChange,
  align = 'vertical', // Default to vertical alignment
}) => {
  const { control, setValue, getValues } = useFormContext();

  useEffect(() => {
    // Set default value when setValue is provided
    if (defaultValue.length > 0) {
      setValue(name, defaultValue);
    }
  }, [setValue, defaultValue, name]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    let updatedValues: string[];

    if (checked) {
      updatedValues = [...(getValues(name) as string[]), value];
    } else {
      updatedValues = (getValues(name) as string[]).filter((v: string) => v !== value);
    }

    setValue(name, updatedValues);

    if (onChange) {
      onChange(updatedValues);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => (
        <FormControl className={className} disabled={disabled} error={!!fieldState.error}>
          <FormLabel>{label}</FormLabel>
          {align === 'horizontal' ? (
            <FormGroup row> {/* Use FormGroup with row prop for horizontal alignment */}
              {options.map((option: any) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      {...field}
                      checked={(field.value as string[]).includes(option.value)}
                      onChange={handleChange}
                      value={option.value}
                    />
                  }
                  label={option.label}
                />
              ))}
            </FormGroup>
          ) : (
            // Default to vertical alignment (or when align="vertical")
            options.map((option: any) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    {...field}
                    checked={(field.value as string[]).includes(option.value)}
                    onChange={handleChange}
                    value={option.value}
                  />
                }
                label={option.label}
              />
            ))
          )}
          {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default WSMultiCheckBox;


import { useMediaQuery } from '@mui/material';
import http from '../commonFunctions/httpClient';
import config from '../Config/config.json';
import dayjs from 'dayjs';
/**
 * Add validation to a control
 * @param required should be true or false
 * @param validations should provide the regular expressions and the error message
 * @returns 
 */
export const customValidation = (required: boolean, validations: IValidations) => ({
    required: required,
    pattern: {
        value: validations.regExp,
        message: validations.error
    }
});
interface IValidations {
    regExp: any;
    error: string
}
export const validations = {
    email: { regExp: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, error: 'Invalid email address' }
}
/**
 * Method to truncate the string if it is too large
 * @param text String to truncate
 * @param length to truncate the string with the length provided
 * @returns 
 */
export function truncateString(text: string, length: number): string {
    if (text && text.length > length) {
        return text.slice(0, length) + '...';
    } else {
        return text;
    }
}
/**
* Converts a string to title case.
* @param str The string to be converted.
* @returns The string in title case.
*/
export function toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export async function callAPI(url: string, method: Method, body?: any, id?: any): Promise<any> {
    let response: any = {}
    try {
        switch (method) {
            case Method.POST:
                response = await http.post(url, body);
                break;
            case Method.GET:
                if (id) {
                    response = await http.get(`${url}/${id}`);
                }
                else {
                    response = await http.get(`${url}`);
                }
                break;
            case Method.DELETE:
                response = await http.del(url, id);
                break;
            case Method.PUT:
                response = await http.put(url, body);
                break;
        }
        return response;
    }
    catch (error) {
        console.error('API call error:', error);
        //throw error; // Rethrow the error to handle it in the calling function
    }
}

export enum Method {
    POST = 'POST',
    GET = 'GET',
    DELETE = 'DELETE',
    PUT = 'PUT'
}
/**
* Converts a string to upper case.
* @param str The string to be converted.
* @returns The string in title case.
*/
export function toUpperCase(str: string): string {
    return str && str.toUpperCase()
}
/**
* Converts a string to lower case.
* @param str The string to be converted.
* @returns The string in title case.
*/
export function toLowerCase(str: string): string {
    return str && str.toLowerCase()
}


/**
 * Convert to base64
 * @param file 
 * @returns 
 */
export const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
};

/**
 * Decoding the image
 * @param base64Image 
 * @returns 
 */
const decodeBase64Image = (base64Image: string): JSX.Element | null => {

    if (!base64Image) return null;

    // Remove the data url prefix (e.g., 'data:image/jpeg;base64,')
    const base64String = base64Image.split(',')[1];

    // Decode base64 string to binary data
    const byteString = atob(base64String);

    // Create ArrayBuffer and UInt8Array to represent the binary data
    const buffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(buffer);

    // Populate UInt8Array with binary data
    for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
    }

    // Create Blob from ArrayBuffer
    const blob = new Blob([buffer], { type: 'image/jpeg' });

    // Generate object URL from Blob
    const imageUrl = URL.createObjectURL(blob);

    // Return an img element displaying the decoded image
    return <img src={imageUrl} alt="Decoded" style={{ width: '5rem', height: '6rem', marginRight: '8px', objectFit: 'fill' }} />;
};

export default decodeBase64Image;

export enum ImageFormat {
    PNG = 'image/png',
    JPEG = 'image/jpeg',
}
/**
 * Getting the type of the image
 * @param imgFormat 
 * @returns 
 */
export function imgType(imgFormat: string) {
    return imgFormat == ImageFormat.JPEG ? "data:image/jpeg;base64," : "data:image/png;base64,"

}
/**
 * Funtion will clear the unwanted nodes in the request 
 * @param request 
 * @returns 
 */
export function clearRequest(request: any): void {
    for (let field in request) {
        if (typeof request[field] === 'object' && !Array.isArray(request[field])) {
            clearRequest(request[field]);
        } else if (Array.isArray(request[field])) {
            for (let arr of request[field]) {
                clearRequest(arr);
            }
        }
        if (request[field] === undefined || request[field] === null || request[field] === '') {
            delete request[field];
        }
    }
    return request;
}
/**
 * Method to Format Address
 * @param address1 
 * @param address2 
 * @param state 
 * @param city 
 * @param pincode 
 * @returns 
 */
export function formatAddress(address1: string, address2: string | undefined, state: string, city: string, pincode: string): string {
    let address: any = `${address1}, ${address2 ? address2 : ''}, ${state}, ${city}, ${pincode}`;
    address = address.trim();
    return address;
}
export function formatAddressObject(address: any): string {
    let newAddress: any = `${address?.addressLine1}, ${address?.addressLine2 ? address?.addressLine2 : ''}, ${address?.state}, ${address?.city}, ${address?.pincode}`;
    newAddress = newAddress.trim();
    return newAddress;
}
export function setEmployeeLocalValues(mainKey: string, key: string, value: string) {
    try {
        let storedData = localStorage.getItem(mainKey);
        let obj: Record<string, string> = storedData ? JSON.parse(storedData) : {};
        obj[key] = value;
        localStorage.setItem(mainKey, JSON.stringify(obj));
    } catch (error) {
        console.error('Error setting employee master values in localStorage:', error);
    }
}
export function clearEmployeeLocalValue(mainKey: string) {
    try {
        localStorage.removeItem(mainKey);
    } catch (error) {
        console.error('Error removing employee master values in localStorage:', error);
    }
}
export function getEmployeeLocalValue(mainKey: string, key?: string) {
    try {
        let obj: any = localStorage.getItem(mainKey);
        if (obj) {
            const empObj: any = JSON.parse(obj)
            return !key ? empObj : empObj[key]
        }
        return null;
    } catch (error) {
        console.error('Error getting employee master values from localStorage:', error);
    }
}
export enum localStorageVariable {
    EmployeeMaster = 'empMaster'
}
export enum EmployeeStorageVariable {
    basicId = "basicId",
    bankInfoId = 'bankInfoId',
    personalInfoId = "personalInfoId",
    salaryInfoId = "salaryInfoId",
    leaveInfoId = "leaveInfoId"
}
export function setLocalValues(key: string | MasterPages | TransactionPages, value: string | object) {
    try {
        let objectOfValue: string = typeof value === 'string' ? value : JSON.stringify(value);
        // if (isEncrypt && isEncrypt === true) {
        //     const secretKey = await generateKey();
        //     const encrypted= await encryptPassword(objectOfValue,secretKey)

        //     localStorage.setItem(key, encrypted);
        // } else {
        localStorage.setItem(key, objectOfValue);
        // }
    } catch (error) {
        console.error('Error setting values in localStorage:', error);
    }
}

export function clearLocalValue(key: string | MasterPages | TransactionPages) {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error('Error removing  values in localStorage:', error);
    }
}
export function getLocalValue(key: string | MasterPages | TransactionPages | LoacalVariables, tranformToObject?: boolean) {
    try {
        let strValue: any = localStorage.getItem(key);
        // if (isEncrypt && isEncrypt === true) {
        //     const secretKey = await generateKey();
        //     const decrypted= await decryptPassword(strValue,secretKey)

        //     return tranformToObject === true ? (convertToJson(decrypted)) : decrypted || null;
        // }
        return tranformToObject === true ? (convertToJson(strValue)) : strValue || null;
    } catch (error) {
        console.error('Error getting  values from localStorage:', error);
    }
}
export function convertToJson(value: string) {
    return JSON.parse(value)
}
export async function encryptPassword(password: string, secretKey: CryptoKey): Promise<string> {
    const encoder = new TextEncoder();
    const encodedPassword = encoder.encode(password);

    const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector

    const encryptedData = await crypto.subtle.encrypt(
        {
            name: "AES-GCM",
            iv: iv
        },
        secretKey,
        encodedPassword
    );

    const encryptedArray = new Uint8Array(encryptedData);
    const encryptedString = Array.from(encryptedArray)
        .map(byte => String.fromCharCode(byte))
        .join("");

    const ivString = Array.from(iv).map(byte => String.fromCharCode(byte)).join("");

    return btoa(ivString + encryptedString); // Combine and encode IV with encrypted data
}
export async function decryptPassword(encrypted: string, secretKey: CryptoKey): Promise<string> {
    const decodedData = atob(encrypted);
    const iv = new Uint8Array(decodedData.slice(0, 12).split("").map(char => char.charCodeAt(0)));
    const encryptedBytes = new Uint8Array(decodedData.slice(12).split("").map(char => char.charCodeAt(0)));

    const decryptedData = await crypto.subtle.decrypt(
        {
            name: "AES-GCM",
            iv: iv
        },
        secretKey,
        encryptedBytes
    );

    const decoder = new TextDecoder();
    return decoder.decode(decryptedData);
}
export async function generateKey(): Promise<CryptoKey> {
    return crypto.subtle.generateKey(
        {
            name: "AES-GCM",
            length: 256
        },
        true,
        ["encrypt", "decrypt"]
    );
}
export enum EPhone {
    AddHyphen = "AddHyphen",
    RemoveHyphen = "RemoveHyphen"
}
export function formatPhoneNumber(value: string, method: EPhone) {
    let phoneNumber: string = '';
    if (value) {

        switch (method) {
            case EPhone.AddHyphen:
                phoneNumber = value?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
                break
            case EPhone.RemoveHyphen:
                phoneNumber = value?.replace(/-/g, "");
                break;
        }
    }
    return phoneNumber


}
export enum IRoleMain {
    Roles = "roles",
    Masters = "masters",
    Transactions = "transactions"
}
export enum MasterPages {
    Departments = "departments",
    Designations = "designations",
    LeaveTypes = "leaveTypes",
    WorkLocations = "workLocations",
    Employees = "employees",
    Users = "users",
    Organisactions = "organisactions",
    Holidays="holidays",
    Meetings="meetings",
    Announcements="announcements",
    Roles="roles"
}
export enum TransactionPages {
    LeaveApproval = "leaveApproval"
}
export enum LoacalVariables {
    UserId = "UserId",
    UserType = "UserType",
    EmployeeInfo = "EmployeeInfo",
}
// fetchAdminData.ts

export const fetchAdminData = async (fileName: string) => {
    const response = await fetch(`${fileName}.json`);
    if (!response.ok) {
        throw new Error('Failed to fetch admin data');
    }
    const data = await response.json();
    return data;
};
export const fetchRolesById = async (id: string) => {
    try {
        const response = await fetch(`${config.serverUrl}roles/${id}`);

        if (!response.ok) {
            throw new Error('Failed to fetch roles');
        }

        const role = await response.json();

        return role || null
        // Handle the fetched role data as needed
    } catch (error) {
        console.error(error);
    }
};

export function getFullName(firstName: string, lastName: string, middleName?: string): string {
    // Join the parts, skipping any empty or undefined strings
    return [firstName, middleName, lastName].filter(Boolean).join(' ');
}
// export function calculateDateDifference(dateFrom: string, dateTo: string): number {
//     const dayjsDateFrom = dayjs(dateFrom);
//     const dayjsDateTo = dayjs(dateTo);

//     // Calculate the difference in hours
//     const diffInHours = dayjsDateTo.diff(dayjsDateFrom, 'hour');

//     // Convert the difference in hours to days, allowing for fractional days
//     const diffInDays = diffInHours / 24;

//     return diffInDays;
// }
export function calculateDateDifferenceExcludingWeekends(dateFrom: string, dateTo: string): number {
    if (dateFrom && dateTo) {
        const dayjsDateFrom = dayjs(dateFrom);
        const dayjsDateTo = dayjs(dateTo);
        

        // Calculate total difference in days
        let diffInDays = dayjsDateTo.diff(dayjsDateFrom, 'day') + 1;

        // Start from the beginning date and count how many Saturdays and Sundays are in between
        let currentDate = dayjsDateFrom;
        let weekendsCount = 0;

        for (let i = 0; i <= diffInDays; i++) {
            // Check if currentDate is Saturday (6) or Sunday (0)
            if (currentDate.day() === 0 || currentDate.day() === 6) {
                weekendsCount++;
            }
            currentDate = currentDate.add(1, 'day'); // Move to the next day
        }

        // Subtract weekends from the total number of days
        const adjustedDiffInDays = diffInDays - weekendsCount;

        return adjustedDiffInDays;
    }
    return 0
}


// Holidays list for the current year (you can customize this)
const holidays = [
    '2024-10-08T11:49:30.829',
    '2024-10-11T11:49:30.829'
];

// Function to calculate date difference excluding weekends and holidays
export function calculateDateDifferenceExcludingWeekendsAndHolidays(dateFrom: string, dateTo: string): number {
    if (dateFrom && dateTo) {
        const dayjsDateFrom = dayjs(dateFrom);
        const dayjsDateTo = dayjs(dateTo);

        // Calculate total difference in days
        let diffInDays = dayjsDateTo.diff(dayjsDateFrom, 'day') + 1;

        // Start from the beginning date and count how many Saturdays, Sundays, and holidays are in between
        let currentDate = dayjsDateFrom;
        let weekendsAndHolidaysCount = 0;

        for (let i = 0; i < diffInDays; i++) {
            const isWeekend = currentDate.day() === 0 || currentDate.day() === 6;
            const isHoliday = holidays.includes(currentDate.format('YYYY-MM-DD')); // Check if current date is a holiday

            if (isWeekend || isHoliday) {
                weekendsAndHolidaysCount++;
            }

            currentDate = currentDate.add(1, 'day'); // Move to the next day
        }

        // Subtract weekends and holidays from the total number of days
        const adjustedDiffInDays = diffInDays - weekendsAndHolidaysCount;

        return adjustedDiffInDays;
    }

    return 0;
}


export const checkIfHoliday = (holidaysList: any[], dateToCheck: string): boolean => {
    // Format the dateToCheck to 'YYYY-MM-DD'
    const formattedDateToCheck = dayjs(dateToCheck).format('YYYY-MM-DD');
    
    // Check if any holiday date matches the formatted date
    return holidaysList.some((item: any) => dayjs(item.date).format('YYYY-MM-DD') === formattedDateToCheck);
  };

  export const convertTo12HourFormat = (decimalTime: string) => {
    
    const [hourStr, minuteStr] = decimalTime.split(':');
    const hours = parseInt(hourStr, 10); // Convert hours to an integer
    const minutes = parseInt(minuteStr, 10); // Convert minutes to an integer
  
    // Determine AM/PM
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12; // Convert 0 hours to 12
  
    // Format minutes to always have two digits
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    // Return the formatted time
    return `${adjustedHours}:${formattedMinutes} ${period}`;
  };
  
  export const IsSmallScreen = () => {
    return useMediaQuery('(max-width:600px)');
  };
let componentRegistry: { [key: string]: any } = {};

export function updateOrRegisterComponent(id: string, props: any) {
    if(!componentRegistry[id]){
    componentRegistry[id] = props;
    }
    else{
        updateComponent(id,props)
    }
}

export function updateComponent(id: string, updatedProps: any) {
    if (componentRegistry[id]) {
        componentRegistry[id] = { ...componentRegistry[id], ...updatedProps };
    }

}
export function unRegisterComponent(id: string,) {
    if (componentRegistry[id]) {
        componentRegistry[id] = undefined
    }

}

export function getComponentProps(id: string) {
    return componentRegistry[id];
}

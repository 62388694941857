/**
 * Component  : SalaryListHomeComponent
 * Usage      : All Salary listing component
 */
import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import WSHiddenField from "../../Components/HiddenField/WSHiddenField";
import WSSelectField from "../../Components/Select/WSSelect";
import WSTextField, { CurrencyType, TextType } from "../../Components/TextBox/WSTextBox";
import config from '../../Config/clientConfig.json';
import { callAPI, EmployeeStorageVariable, getEmployeeLocalValue, IsSmallScreen, localStorageVariable, Method } from "../../commonFunctions/CommonFunctions";
import { ISalaryInfo } from "../Interfaces/Interfaces";
interface ISalaryDetailsProps {
    id: any;
    control: any;
    register: any;
    errors: any
    reset: any
    setValue?: any

}
const SalaryDetailsComponent: React.FC<ISalaryDetailsProps> = React.memo((props) => {
    const [pfMonthlyAmount, setPFMonthlyAmount] = useState(0);
    const [esiMonthlyAmount, setESIMonthlyAmount] = useState(0);
    const [pfAnnualAmount, setPFAnnualAmount] = useState(0);
    const [esiAnnualAmount, setESIAnnulAmount] = useState(0);
    const [isMonthly, setIsMonthly] = useState(false);
    const { control, register, setValue } = props;
    const [salaryInfoId, setSalaryInfoId] = useState(
        getEmployeeLocalValue(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.salaryInfoId) || null
    );
    const isMobile = IsSmallScreen()
    useEffect(() => {
        /**
  * Method to get the data for editing pupose
  */    try {
            async function getData() {
                let response = await callAPI(`employee/salaryinfo`, Method.GET, {}, salaryInfoId);
                const salaryInfo: ISalaryInfo = response?.data;
                if (setValue && salaryInfo) {
                    setValue("PF", salaryInfo.pf);
                    setValue("ESI", salaryInfo.esi);
                    setValue("payType", salaryInfo?.payType);
                    setValue("payCalculation", salaryInfo?.payCalculation);
                    setValue("amount", salaryInfo.amount);
                    setValue('monthlyAmount', salaryInfo.monthlyAmount)
                    setValue('annualAmount', salaryInfo.annualAmount)
                    if (salaryInfo?.payType === 0) {
                        setIsMonthly(true)
                    }
                    setPFMonthlyAmount(Number(salaryInfo.pf) * 1)
                    setPFAnnualAmount(Number(salaryInfo.pf) * 12)
                    setESIMonthlyAmount(Number(salaryInfo.esi) * 1)
                    setESIAnnulAmount(Number(salaryInfo.esi) * 12)
                }
            }

            if (salaryInfoId) {
                getData();
            }
        }
        catch (e) {
        }
    }, [salaryInfoId, setValue]);


    const salaryComponent: any = [
        { name: 'PF', type: 'number', suffix: '% of CTC' },
        { name: 'ESI', type: 'number', suffix: '% of Basic' },
        // { name: 'Conveyance Allowance', type: 'label' },
        // { name: 'Children Education Allowance', type: 'label' }

    ]
    useEffect(() => {
        setValue('monthlyAmount', pfMonthlyAmount + esiMonthlyAmount)
        setValue('annualAmount', pfAnnualAmount + esiAnnualAmount)
    },

        [pfMonthlyAmount > 0, esiMonthlyAmount > 0])
    const handlePayTypeChange = (e: any) => {
        if (e.target.value === 0) {
            setIsMonthly(true)

        } else {
            setIsMonthly(false)
        }
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target
        switch (name?.toLowerCase()) {
            case 'pf':
                setPFMonthlyAmount(Number(value) * 1)
                setPFAnnualAmount(Number(value) * 12)
                break
            case 'esi':
                setESIMonthlyAmount(Number(value) * 1)
                setESIAnnulAmount(Number(value) * 12)
                break
        }
    };
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item sm={isMonthly ? 4 : 6} xs={12} >
                    <WSSelectField
                        id="payType"
                        {...register('payType')}
                        label="Pay Type *"
                        options={config.payTypeConfig.staticOptionData}
                        //labelType="Normal"
                        isMobile={isMobile}
                        control={control}
                        onChange={handlePayTypeChange}
                        rules={{ required: true }}
                    />
                </Grid>
                {isMonthly && <Grid item sm={isMonthly ? 4 : 6} xs={12} >
                    <WSSelectField
                        id="payCalculation"
                        {...register('payCalculation')}
                        name="payCalculation"
                        label="Calculation Type *"
                        options={config.calculationTypeConfig.staticOptionData}
                        //  labelType="Normal"
                        isMobile={isMobile}
                        control={control}
                        rules={{ required: true }}
                    />
                </Grid>}
                {salaryComponent && <Grid item sm={isMonthly ? 4 : 6} xs={12} >
                    <WSTextField
                        id="amount"
                        label="Amount"
                        control={control}
                        //  labelType="Normal"
                        type={TextType.Currency}
                        currency={CurrencyType.Rupee}
                        {...register('amount')}
                        rules={{ required: true }}
                    />
                </Grid>}
            </Grid>
            <Grid container spacing={1} className="ws-grid-table" >
                <Grid item sm={3} xs={2} alignContent={'center'} className="child-cell header" >
                    <Typography style={{ fontSize: '.75rem', fontWeight: 'bold' ,wordBreak:'break-word'}}>{`${isMobile ? 'SALARY' : 'SALARY COMPONENTS'}`}</Typography>
                </Grid>
                <Grid item sm={3} xs={4} alignContent={'center'} className="child-cell header" >
                    <Typography style={{ fontSize: '.75rem', fontWeight: 'bold' }}>{`${isMobile ? 'AMOUNT' : 'CALCULATION AMOUNT'}`}</Typography>
                </Grid>
                <Grid item sm={3} xs={3} alignContent={'center'} className="child-cell header" >
                    <Typography style={{ fontSize: '.75rem', fontWeight: 'bold' }}>{`${isMobile ? 'MONTHLY' : 'MONTHLY AMOUNT'}`}</Typography>
                </Grid>
                <Grid item sm={3} xs={3} alignContent={'center'} className="child-cell header" >
                    <Typography style={{ fontSize: '.75rem', fontWeight: 'bold' }}>{`${isMobile ? 'ANNUAL' : 'ANNUAL AMOUNT'}`}</Typography>
                </Grid>

                {salaryComponent && salaryComponent.map((item: any) => {
                    return (
                        <><Divider />
                            <Grid item sm={3} xs={2} alignContent={'center'} className="child-cell">
                                <Typography  style={{ fontSize: '.75rem', }}>{item.name}</Typography>
                            </Grid>
                            {item?.type === 'number' && <Grid item sm={3} xs={4} className="child-cell">
                                <WSTextField
                                    id={`${item.name}`}
                                    control={control}
                                    type={TextType.Currency}
                                    onTextChange={handleTextChange}
                                    currency={CurrencyType.Rupee}
                                    {...register(`${item.name}`)}
                                    rules={{ required: true }}
                                />
                            </Grid>}
                            {item?.type === 'label' &&
                                <Grid item sm={3} xs={3} alignContent={'center'} >
                                    <Typography  style={{ fontSize: '.75rem', }}>{item.name}</Typography>
                                </Grid>
                            }
                            <Grid item sm={3} xs={3} className="child-cell" alignContent={'center'}>
                                {/* <Grid item xs={3} alignContent={'center'}> */}
                                <Typography  style={{ fontSize: '.75rem', }}>{item?.name == "PF" ? pfMonthlyAmount : esiMonthlyAmount}</Typography>
                                {/* </Grid> */}
                                {/* <WSTextField
                                    id={`payTypeAmount_${item.name}`}
                                    control={control}

                                    type={TextType.Currency}
                                    currency={CurrencyType.Rupee}
                                    {...register(`payTypeAmount_${item.name}`)}
                                    rules={{ required: true }}
                                /> */}
                            </Grid>
                            <Grid item sm={3} xs={3} alignContent={'center'} className="child-cell">
                                <Typography  style={{ fontSize: '.75rem', }}>{item?.name == "PF" ? pfAnnualAmount : esiAnnualAmount}</Typography>
                            </Grid>
                        </>


                    )
                })}
                <Grid item sm={6} xs={6} alignContent={'center'} className="child-cell">
                    <Typography  style={{ fontSize: '.75rem', }}>Cost to Company</Typography>
                </Grid>
                <Grid item sm={3} xs={3} alignContent={'center'} className="child-cell">
                    <Typography  style={{ fontSize: '.75rem', }}>{pfMonthlyAmount + esiMonthlyAmount}</Typography>
                </Grid>
                <Grid item sm={3} xs={3} alignContent={'center'} className="child-cell">
                    <Typography  style={{ fontSize: '.75rem', }}>{pfAnnualAmount + esiAnnualAmount}</Typography>
                </Grid>
                <WSHiddenField
                    name="monthlyAmount"
                    defaultValue={pfMonthlyAmount + esiMonthlyAmount}
                    control={control}
                    setValue={setValue}
                />
                <WSHiddenField
                    name="annualAmount"
                    control={control}
                    defaultValue={pfAnnualAmount + esiAnnualAmount}
                    setValue={setValue}
                />
            </Grid>

        </div >
    );

})
export default SalaryDetailsComponent;

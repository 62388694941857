import {
    Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, Fab, Grid, IconButton, LinearProgress, Link, Paper, Skeleton, Table, TableBody, TableCell, TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
    createSvgIcon
} from '@mui/material';
import dayjs from 'dayjs'; // Import dayjs for date formatting
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatAddressObject, toUpperCase } from '../../commonFunctions/CommonFunctions';
import http from '../../commonFunctions/httpClient';
import { WSIcons } from '../../commonFunctions/iIcons';
import { IButton } from '../../Pages/Interfaces/Interfaces';
import { storeDataComponent } from '../../StateManagement/actions';
import store from '../../StateManagement/store';
import { WSFilterComponent } from '../Filter/WSFilterComponent';
import { WSStatus } from '../Status/WSStatus';
import ThreeDotMenu from '../ThreeDotMenu/ThreeDotMenu';
import { WSView } from '../View/WSView';
interface DataGridProps {
    id: string;
    title?: string;
    columns: IColumns[];
    multiSelect?: boolean;
    onSelectionChange?: (selectedRows: any[]) => void;
    source?: {
        url: string;
        pageSize: number;
        page: number;
        filter: any[];
        sort: any[];
        type?: 'POST' | 'GET'
    };
    filters?: any;
    filterFields?: any
    exportCsv?: boolean,
    addButton?: IButton;
    transFormData?: (data: any) => any;
    onAddButtonClick?: () => void;
    buttonStyle?: 'Normal' | 'Fab',
    showpagination?: boolean
}
export interface IColumns {
    header: string;
    fieldName: string;
    type?: 'default' | 'link' | { image: { suffix1?: string; suffix2?: string; } } | 'action' | 'status' | 'date';
    tooltip?: boolean;
    actions?: any;
    defaultSort?: string;
    actionCallback?: (action: string, row: any) => void;
    sortable?: boolean; // Indicate if column is sortable
    sortKey?: string
}

const WSDataGrid: React.FC<DataGridProps> = ({
    id,
    columns,
    multiSelect,
    onSelectionChange,
    source,
    filters,
    filterFields,
    exportCsv = false,
    transFormData,
    title,
    addButton,
    onAddButtonClick,
    buttonStyle = "Normal",
    showpagination = true
}) => {
    const [page, setPage] = useState(0);
    const [_rows, setRows] = useState<any[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [exporting, setExporting] = useState<boolean>(false);
    const [showImagePopup, setShowImagePopup] = useState<boolean>(false);
    const [popupImageURL, setPopupImageURL] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const gridContainerRef = useRef<HTMLDivElement>(null);
    const [gridHeight, setGridHeight] = useState<any>()
    const [newFilters, setFilters] = useState(filters);
    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        'SaveAlt',
    );
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' }>({
        key: '',
        direction: 'desc',
    });

    const dispatch = useDispatch();
    const handleImageClick = (imageUrl: string) => {
        setPopupImageURL(imageUrl);
        setShowImagePopup(true);
    };

    const fetchDataFromAPI = async (
        page: number,
        pageSize: number,
        filter: any[] = [],
        sort: any[] = []
    ) => {
        try {
            setLoading(true);
            if (source?.type === 'GET') {
                const response: any = await http.get(source.url);
                return response;
            }
            if (source) {
                const response: any = await http.post(source.url, {
                    page: page,
                    pageSize: pageSize,
                    filter: filter,
                    sort: source.sort.length > 0 ? source.sort : sort,
                });
                return response;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setLoading(false);  // End loading
        }
    };

    const loadPageData = useCallback(async () => {
        try {
            const defaultSortKey = columns?.find((column: any) => column?.defaultSort);
            const sortKey: any = sortConfig.key ? sortConfig.key : defaultSortKey ? defaultSortKey.defaultSort : ''
            const sort = [{ 'field': sortKey, direction: sortConfig.direction }];
            let filter: any = []
            if (source && source?.filter?.length > 0) {
                filter = [...source?.filter]
                if (newFilters) {
                    filter = [...newFilters]
                }
            }
            let response = await fetchDataFromAPI(page + 1, rowsPerPage, filter && filter?.length > 0 ? filter : newFilters, sortKey !== '' ? sort : []);
            //if (response && response.data && _rows && _rows.length > 0 && _rows[0].id !== response.data[0].id) {
            dispatch(storeDataComponent(id, response?.data || []));
            //}
            if (transFormData) {
                setRows(transFormData(response?.data || []));
            } else {
                setRows(response?.data || []);
            }
            setTotalCount(response?.pagination?.totalCount | 0);
        } catch (error) {
            console.error('Error loading page data:', error);
        }
    }, [page, rowsPerPage, source, id, sortConfig, newFilters, transFormData]);

    useEffect(() => {


        loadPageData();

        if (gridContainerRef.current) {
            setGridHeight(gridContainerRef.current.clientHeight);

        }
    }, [page, rowsPerPage, sortConfig, newFilters]);
    useEffect(() => {

        const unsubscribe = store.subscribe(() => {
            const state = store.getState();
            if (state.components[id]?.data === null) {
                loadPageData();
            }
        });

        return () => {
            unsubscribe();
        };
    }, [id, loadPageData]);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page to 0 when rows per page changes
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedRows = _rows.map((_, index) => page * rowsPerPage + index);
            setSelectedRows(newSelectedRows);
            if (onSelectionChange) {
                onSelectionChange(newSelectedRows.map((idx) => _rows[idx % rowsPerPage]));
            }
            return;
        }
        setSelectedRows([]);
        if (onSelectionChange) {
            onSelectionChange([]);
        }
    };

    const handleClick = (index: number) => {
        const selectedIndex = selectedRows.indexOf(index);
        let newSelectedRows: number[] = [];

        if (selectedIndex === -1) {
            newSelectedRows = newSelectedRows.concat(selectedRows, index);
        } else if (selectedIndex === 0) {
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedRows = newSelectedRows.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }
        setSelectedRows(newSelectedRows);

        if (onSelectionChange) {
            onSelectionChange(newSelectedRows.map((idx) => _rows[idx % rowsPerPage]));
        }
    };

    const isSelected = (index: number) => selectedRows.indexOf(index) !== -1;
    const handleRequestSort = (property: string) => {
        const isAsc = sortConfig.key?.toLowerCase() === property && sortConfig.direction === 'asc';
        setSortConfig({ key: property, direction: isAsc ? 'desc' : 'asc' });
        setPage(0);
    };

    const sortedRows = () => {
        if (sortConfig.key) {
            const comparator = (a: any, b: any) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            };
            return [..._rows].sort(comparator);
        }
        return _rows;
    };

    const isArrayOrObject = (value: any) => {
        return value && typeof value === 'object';
    };

    const handleFilterChange = (newFilters: any) => {
        setFilters(newFilters);
    };
    const handleAddButton = () => {
        if (onAddButtonClick) {
            onAddButtonClick();
        }
    }
    const formatExportData = (rows: any[], columns: IColumns[]) => {
        return rows.map((row) => {
            const formattedRow: any = {};
            columns?.forEach((column) => {
                if (column.fieldName.includes('addresses')) {
                    const formattedAddress: any = formatAddressObject(row[column.fieldName]);
                    formattedRow[column.fieldName] = `${formattedAddress.addressLine1 || ''} ${formattedAddress.addressLine2 || ''} ${formattedAddress.city || ''} ${formattedAddress.state || ''} ${formattedAddress.zipCode || ''}`;
                } else {
                    formattedRow[column.fieldName] = row[column.fieldName];
                }
            });
            return formattedRow;
        });
    };

    const handleExportCSV = async () => {
        setExporting(true);
        const exportData = formatExportData(_rows, columns);
        const csvContent = [
            columns?.map((column) => column.header).join(','),
            ...exportData.map((row) =>
                columns?.map((column) => `"${row[column.fieldName] || ''}"`).join(',')
            ),
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `${id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setExporting(false);
    };

    return (
        <div className="data-grid-container" ref={gridContainerRef}>
             <Grid item container spacing={1} justifyContent={'start'} >
                <Grid item sm={12} xs={12}>
            <Paper style={{width:'100%'}}>
                <Grid item container spacing={1} justifyContent={'start'}>
                    {title && <Grid style={{ paddingLeft: 20, paddingTop: 25, height: '4rem' }} item sm={4} className='ws-data-grid ws-data-grid-title' ><Typography variant="h6" id="tableTitle" sx={{ fontSize: '1.15rem' }} >
                        {title}
                    </Typography>
                    </Grid>
                    }
                    {!title && <Grid style={{ paddingLeft: 20, paddingTop: 25, height: '4rem' }} item sm={4} className='ws-data-grid ws-data-grid-title' >
                    </Grid>
                    }
                    <Grid item sm={8} container spacing={2} justifyContent={'end'}>

                        {addButton && <Grid item style={{ paddingTop: 25 }}>

                            {buttonStyle === "Normal" && <IconButton
                                sx={{
                                    backgroundColor: '#404040',
                                    borderRadius: 1,
                                    //padding: '8px',
                                    width: '38px',
                                    height: '32px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginRight: (!exportCsv || !addButton) ? 1 : 0,
                                    justifyContent: 'center',
                                    '&:hover': {
                                        backgroundColor: '#606060', // Change the color on hover
                                        //transform: 'scale(1.05)', // Optional: Slightly scale up the button on hover
                                    },

                                }}
                                onClick={handleAddButton}>
                                <WSIcons.AddIcon style={{ color: 'white' }} />

                            </IconButton>}
                            {buttonStyle === "Fab" && <Fab size="small" onClick={handleAddButton} sx={{
                                backgroundColor: '#404040',
                                '&:hover': {
                                    backgroundColor: '#606060',
                                },
                                height: 32
                            }} aria-label="add">
                                <WSIcons.AddIcon style={{ color: 'white' }} />
                            </Fab>}
                        </Grid>}
                        {exportCsv && <Grid item sx={{ paddingRight: !filterFields ? 2 : undefined }} style={{ paddingTop: 25 }}>
                            {buttonStyle === 'Normal' && <Button sx={{
                                height: '32px', fontSize: '0.75rem'
                            }}
                                variant="contained"
                                color="primary"
                                startIcon={<ExportIcon />}
                                onClick={handleExportCSV}
                                disabled={exporting}
                            >
                                Export
                            </Button>
                            }
                            {buttonStyle === 'Fab' &&
                                <Fab sx={{ height: 32 }} variant="extended" size="medium" color="primary" onClick={handleExportCSV}>
                                    <ExportIcon sx={{ mr: 1 }} />
                                    Export
                                </Fab>
                            }
                        </Grid>
                        }
                        {filterFields && <Grid item sx={{ paddingRight: 2 }} style={{ paddingTop: 25 }}>
                            <WSFilterComponent rows={filterFields} alignment="right" onFilterChange={handleFilterChange}></WSFilterComponent>
                        </Grid>}
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: 10 }} />
                
                <Table size="small" >
                    <TableHead>
                        <TableRow component="th">
                            {multiSelect && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={
                                            selectedRows.length > 0 && selectedRows.length < rowsPerPage
                                        }
                                        checked={selectedRows.length === rowsPerPage}
                                        onChange={handleSelectAllClick}
                                        inputProps={{ 'aria-label': 'select all rows' }}
                                    />
                                </TableCell>
                            )}
                            {columns?.map((column, index) => (
                                <TableCell className='ws-data-grid-header'
                                    key={index}
                                    sortDirection={sortConfig.key?.toLowerCase() === column.fieldName.toLowerCase() ? sortConfig.direction : false}
                                >
                                    {column.sortable ? (
                                        <TableSortLabel
                                            active={sortConfig.key?.toLowerCase() === column.fieldName.toLowerCase()}
                                            direction={sortConfig.key?.toLowerCase() === column.fieldName.toLowerCase() ? sortConfig.direction : 'asc'}
                                            onClick={() => handleRequestSort(column.sortKey ? column.sortKey : column.fieldName)}
                                        >
                                            {toUpperCase(column.header)}
                                        </TableSortLabel>
                                    ) : (
                                        toUpperCase(column.header)
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {sortedRows().length > 0 ? (<TableBody>
                        {loading ? (
                            <>
                                {/* Progress bar at the top */}
                                {/* <Grid
                                    display="block"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight={400}
                                    
                                >
                                    <LinearProgress />
                                </Grid> */}

                                {/* Skeleton and loading indication */}
                                <TableRow component="th">
                                    <TableCell
                                        colSpan={columns?.length + (multiSelect ? 1 : 0)}
                                        align="center"
                                    >
                                        <Box
                                            display="flex"
                                            width="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                            minHeight={gridHeight - 200} // Adjust the height based on your grid size
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                                width="90%"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Grid item sm={12}>
                                                    {/* Skeleton loader as a placeholder */}
                                                    <Skeleton width="100%">
                                                        <Typography>.</Typography>
                                                    </Skeleton>

                                                    {/* Rectangular skeleton */}
                                                    <Skeleton
                                                        animation="pulse"
                                                        variant="rectangular"
                                                        width="100%"
                                                    >
                                                        <div style={{ paddingTop: '17%' }} />
                                                    </Skeleton>

                                                    <Skeleton width="100%">
                                                        <Typography>.</Typography>
                                                    </Skeleton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </>
                        )
                            : (sortedRows().map((row: any, index: number) => {
                                const isItemSelected = isSelected(page * rowsPerPage + index);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={() => handleClick(page * rowsPerPage + index)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                    >
                                        {multiSelect && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                        )}
                                        {columns?.map((column, colIndex) => {

                                            if (column.type === 'default' && column.tooltip === true) {
                                                return (<TableCell key={colIndex}>
                                                    <WSView style={{ fontSize: '0.875rem' }}
                                                        title={row[column.fieldName]}
                                                        isTooltip={column.tooltip}
                                                    />
                                                </TableCell>);
                                            } else if (column.type === 'link') {
                                                return (
                                                    <TableCell key={colIndex} >
                                                        <Link href={row[column.fieldName]} target="_blank" rel="noopener noreferrer">
                                                            {row[column.fieldName]}
                                                        </Link>
                                                    </TableCell>
                                                );
                                            } else if (typeof column.type === 'object' && column.type.image) {
                                                return (
                                                    <TableCell key={colIndex}>
                                                        <Grid item container spacing={3}>
                                                            <Grid item sm={4}>
                                                                <img
                                                                    src={row[column.fieldName]}
                                                                    alt="thumbnail"
                                                                    onClick={() => handleImageClick(row[column.fieldName])}
                                                                    style={{ cursor: 'pointer', maxWidth: '100px', height: '40px' }}
                                                                />
                                                            </Grid>
                                                            {column.type.image.suffix1 && <Grid item sm={4}>
                                                                <Typography style={{ fontSize: '12px', paddingTop: !column.type?.image?.suffix2 ? '10px' : '0' }}>{row[column.type.image.suffix1]}</Typography>
                                                                {column.type?.image?.suffix2 && <Typography style={{ fontSize: '9px' }}>{row[column.type?.image?.suffix2]}</Typography>}
                                                            </Grid>}
                                                        </Grid>


                                                    </TableCell>
                                                );
                                            } else if (column.type === 'status') {
                                                return (
                                                    <TableCell key={colIndex}>
                                                        <WSStatus value={row[column.fieldName]} />
                                                    </TableCell>
                                                );
                                            }
                                            else if (column.type === 'date') {
                                                return (
                                                    <TableCell key={colIndex}>
                                                        <Typography style={{ fontSize: '0.875rem' }}>
                                                            {dayjs(row[column.fieldName]).isValid() ? dayjs(row[column.fieldName]).format('DD/MM/YYYY') : 'Invalid Date'}
                                                        </Typography>
                                                    </TableCell>
                                                );
                                            }

                                            // else if (column.type === 'date') {
                                            //     return (
                                            //         <TableCell key={colIndex}>
                                            //             <Typography  style={{fontSize:'0.875rem'}} >{dayjs(row[column.fieldName])?.toDate().toLocaleDateString()}</Typography>
                                            //         </TableCell>
                                            //     );
                                            // }
                                            else if (column.type === 'action' && column?.actions?.length > 0) {
                                                return (
                                                    <TableCell key={colIndex}>
                                                        <ThreeDotMenu
                                                            items={column.actions}
                                                            onAction={(action: string) =>
                                                                column.actionCallback?.(action, row)
                                                            }
                                                        />
                                                    </TableCell>
                                                );
                                            }
                                            else {
                                                return (
                                                    <TableCell key={colIndex} >
                                                        {isArrayOrObject(row[column.fieldName]) ? JSON.stringify(row[column.fieldName]) : row[column.fieldName]}
                                                    </TableCell>
                                                );
                                            }
                                        })}
                                    </TableRow>
                                );
                            }))}
                    </TableBody>) : loading ? (
                            <>
                                  <TableBody>
                            <TableRow>
                                <TableCell colSpan={columns?.length + (multiSelect ? 1 : 0)} align="center">
                                <Box
                                    display="block"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight={400}
                                >
                                    <LinearProgress />
                                </Box>

                                </TableCell>
                            </TableRow>
                        </TableBody>
                                {/* <Box
                                    display="block"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight={400}
                                >
                                    <LinearProgress />
                                </Box> */}

                                {/* Skeleton and loading indication */}
                           
                            </>
                        ): (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={columns?.length + (multiSelect ? 1 : 0)} align="center">
                                    <Typography variant="h6" style={{ padding: '20px', fontSize: '1.15rem' }}>
                                        No Records to Show
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
                {/* )} */}
                {showpagination === true && <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />}
            </Paper>
            </Grid>
            </Grid>
            <Dialog open={showImagePopup} onClose={() => setShowImagePopup(false)} maxWidth="xs">
                <DialogTitle>Image</DialogTitle>
                <DialogContent>
                    <img src={popupImageURL} alt="Large Preview" style={{ maxWidth: '100%' }} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default WSDataGrid;

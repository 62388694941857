import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useForm } from 'react-hook-form';
import WSBackButton from '../BackButton/BackButton';
import WSButton from '../Button/WSButton';

interface IWSBigCalendarProps {
  id: string;
  title: string;
  icon?: React.ElementType;
  iconColor?: string;
  events: any[];
  defaultValue?:any,
  onSelectEvent?: (event: any) => void;
  onSelectSlot?: (slotInfo: any) => void;
  onEventSave?: (event: any) => void;
  profileHeight?: number;
  dialog?:boolean
  toolbar?:boolean
}

const localizer = momentLocalizer(moment);

const WSBigCalendar: React.FC<IWSBigCalendarProps> = ({
  id,
  title,
  icon: Icon,
  iconColor,
  events,
  onSelectEvent,
  onSelectSlot,
  onEventSave,
  profileHeight,
  defaultValue,
  dialog=true,
  toolbar=true
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      start: '',
      end: ''
    },
  });

  const handleClickOpen = (event: any) => {
    setSelectedEvent(event);
    setDialogOpen(true);
    reset({
      title: event?.title || '',
      description: event?.description || '',
      start: event?.start ? moment(event.start).format('YYYY-MM-DDTHH:mm') : '',
      end: event?.end ? moment(event.end).format('YYYY-MM-DDTHH:mm') : '',
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedEvent(null);
    reset();
  };

  const onSubmit = (data: any) => {
    const eventToSave = {
      ...selectedEvent,
      title: data.title,
      description: data.title,
      start: new Date(data.start),
      end: new Date(data.end),
    };
    onEventSave?.(eventToSave); // Save the event with the new values
    handleClose();
  };

  const handleSlotSelect = (slotInfo: any) => {
    handleClickOpen({
      title: '',
      start: slotInfo.start,
      end: slotInfo.end,
      description: '',
    });
    onSelectSlot && onSelectSlot(slotInfo);
  };
  return (
    <Paper elevation={1} variant="elevation" style={{marginTop:2}}>
      <Box mt={2} height="100%" display="flex" flexDirection="column">
        <Card>
          <CardHeader
            title={
              <Grid container spacing={2} alignItems="center">
                {Icon && (
                  <Grid item sm={1}>
                    <Icon style={{ color: iconColor ? iconColor : 'black' }} />
                  </Grid>
                )}
                <Grid item sm={Icon ? 11 : 12}>
                  <Typography
                    variant="h6"
                    style={{
                      marginBottom: 5,
                      fontWeight: 'bold',
                      paddingLeft: 0,
                      fontSize: '1.15rem',
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          <CardContent sx={{ flexGrow: 1 }}>
            <Calendar
              popup
              selectable
              localizer={localizer}
              toolbar={toolbar}
              events={events}
              defaultView="month"
              scrollToTime={new Date(1970, 1, 1, 6)}
              defaultDate={defaultValue || new Date()}
              onSelectEvent={handleClickOpen}
              onSelectSlot={handleSlotSelect}
              style={{ height: profileHeight || 500 }}
            />
          </CardContent>
        </Card>

      {dialog &&  <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>
            {selectedEvent?._id ? 'Edit Event' : 'Schedule Event'}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField size='small'
                label="Event Title"
                {...register('title', { required: 'Event title is required' })}
                error={!!errors.title}
                helperText={errors.title ? errors.title.message : ''}
                fullWidth
                margin="dense"
              />
              {/* <TextField
                label="Event Description"
                {...register('description', { required: 'Description is required' })}
                error={!!errors.description}
                helperText={errors.description ? errors.description.message : ''}
                fullWidth
                margin="dense"
              /> */}
              <TextField
                label="Start Date" size='small'
                type="datetime-local"
                {...register('start', { required: 'Start date is required' })}
                error={!!errors.start}
                helperText={errors.start ? errors.start.message : ''}
                fullWidth
                margin="dense"
              />
              {/* <TextField
                label="End Date"
                type="datetime-local"
                {...register('end', { required: 'End date is required' })}
                error={!!errors.end}
                helperText={errors.end ? errors.end.message : ''}
                fullWidth
                margin="dense"
              /> */}
              <Grid item container spacing={2} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                <Grid item>
                  <WSButton id='btnSaveCalander' label='Save' type='submit' size='medium' />
                  {/* <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button> */}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>}
      </Box>
    </Paper>
  );
};

export default WSBigCalendar;

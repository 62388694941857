import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import WSAutocompleteField from "../../Components/AutoComplete/AutoComplete";
import WSTextField from "../../Components/TextBox/WSTextBox";
import { callAPI, IsSmallScreen, Method } from "../../commonFunctions/CommonFunctions";
interface DesignationProps {
  control: Control<any>;
  errors: FieldErrors;
  register: UseFormRegister<any>;
  id: any;
  reset: UseFormReset<any>;
  designationId?: string;
  setValue: UseFormSetValue<any>;
}

const Designation: React.FC<DesignationProps> = ({
  control, errors, register, id, reset, designationId, setValue,
}) => {
  const isMobile=IsSmallScreen()
  useEffect(() => {
    reset();
  }, [id, reset]);

  useEffect(() => {
    /**
     * edit function
     * @param id 
     */
    async function getDesignation(id: any) {
      try {
        const response: any = await callAPI("designation", Method.GET, {}, id);
        if (response?.data) {
          setValue("designationName", response.data.designationName);
          setValue("workLocation", response.data.workLocation.id);
          setValue("designationId", response.data.id);
        }

      } catch (error) {
        console.log("failed to retrieve data", error);

      }
    }
    if (designationId) {
      getDesignation(designationId);

    }
  }, [designationId]);

  const source = {
    url: "worklocation/search",
    page: 1,
    pageSize: 10000,
    filter: [],
    sort: [],
  };
  const transFormData = (data: any) => {
    let newData: Array<any> = [];
    data.map((item: any) => {
      newData.push({ label: item.workLocationName, value: item.id });
    });
    return newData;
  };

  return (
    <Grid >
      {<Grid className="modal-input" container spacing={2}>
        <Grid item xs={12}>
          <WSTextField
            control={control}
            id="designationName"
            {...register("designationName")}
            rules={{ required: true }}
            name="designationName"
            label="Designation Name *"

          />
          {errors.description && (
            <Typography variant="body2" color="error">
              Designation Name is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>

          <WSAutocompleteField
            id="workLocation"
            {...register("workLocation")}
            label="Work Location"
            source={source}
            transFormData={transFormData}
            control={control}
            rules={{ required: true }}
          />

        </Grid>
        <Grid style={{ display: "none" }}>
          <WSTextField
            control={control}
            id="designationId"
            {...register("designationId")}
            rules={{ required: false }}
            name="designationId"
            label=""
          />
        </Grid>
      </Grid>}
    </Grid>
  );
};
export default Designation;

import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { truncateString } from "../../commonFunctions/CommonFunctions";

// Define an interface for your component props
interface StatusProps {
    value: string;
    toolTip?: boolean;
    style?: React.CSSProperties;
}

// Function to determine the color based on the status value
const getStatusColor = (value: string): string => {
    switch (value?.toLowerCase()) {
        case 'approved':
        case 'active':
            return 'green';

        case 'rejected':
            return '#D2122E'; //red shade
        case 'absent':

            return '#AA0000'; //red shade
        case 'in active':
        case 'inactive':
            return '#A9A9A9'

        case 'pending':
            return 'orange';
        default:
            return 'black'; // Default color if the status doesn't match any case
    }
};

// Define and export the memoized component in one line
export const WSStatus: React.FC<StatusProps> = React.memo(({ value, toolTip = false, style }) => {
    const statusColor = getStatusColor(value);

    return (
        <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
            {/* Dot prefix with the status color */}
            <span
                style={{
                    backgroundColor: statusColor,
                    borderRadius: '50%',
                    width: 8,
                    height: 8,
                    marginRight: 6,
                    display: 'inline-block',
                }}
            />
            {toolTip ? (
                <Tooltip title={value} placement="top">
                    <Typography style={{ color: statusColor, fontSize: '0.875rem', ...style }}>
                        {truncateString(value, 20)}
                    </Typography>
                </Tooltip>
            ) : (
                <Typography style={{ color: statusColor, fontSize: '0.875rem', ...style }}>
                    {value}
                </Typography>
            )}
        </div>
    );
});

import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getFullName, IsSmallScreen } from "../../commonFunctions/CommonFunctions";
import WSDataGrid, { IColumns } from "../../Components/DataGrid/WSDataGrid";
import WSMobViewDataGrid from "../../Components/DataGrid/WSMobViewDataGrid";
import { Header } from "../Header/Header";

export const LeaveApprovedList: React.FC<any> = React.memo((props) => {
    const navigate = useNavigate()
    const source = {
        url: "LeaveApply/search",
        page: 1,
        pageSize: 10000,
        filter: [{"field":"Status","operator":"eq","value":"Approved"}],
        sort: [],
    };
    const columns: IColumns[] = [
        {
            header: "Requested by",
            fieldName: "employee",
            type:'default',
        },
        {
            header: "Leave Type",
            fieldName: "leaveName",
            tooltip: true,
            type:'default',
        },
        {
            header: "Status",
            fieldName: "statusCode",
            type: 'status',
            
        },
        {
            header: "From",
            fieldName: "dateFrom",
            type: 'date'

        },
        {
            header: "To",
            fieldName: "dateTo",
            type: 'date'
        },
        {
            header: "Reason",
            fieldName: "reason",
            type: 'default',
            tooltip: true,
        },
    ];

    const handleClick = () => {
        navigate('/leave/request')
    }
    const transFormData = (data: any) => {
        if (data && data.length > 0) {
            data.map((item: any) => {
                item.leaveName = item?.leaveType?.name;
                   item.employee=`${getFullName(item?.employee?.firstName, item?.employee?.lastName, item?.employee?.middleName)}`
            })

        }
        return data
    }
    return (<>
        
        <Grid container spacing={2}>
        {!IsSmallScreen() ?<Grid item xs={12}>
                <WSDataGrid
                    id="leaveapprovalGrid"
                    title="Leave Approval"
                    columns={columns}
                    source={source}
                    transFormData={transFormData}
                />
            </Grid>:<Grid item xs={12}>
                <WSMobViewDataGrid
                    id="leaveapprovalGrid"
                    title="Leave Approval"
                    columns={columns}
                    source={source}
                    transFormData={transFormData}
                />
            </Grid>}
        </Grid></>

    )
})
import React from 'react';
import { Controller } from 'react-hook-form';

interface WSHiddenFieldProps {
  name: string;
  control: any;
  defaultValue?: any;
  setValue?: (name: string, value: any, options?: object) => void;
}

const WSHiddenField: React.FC<WSHiddenFieldProps> = ({
  name,
  control,
  defaultValue = '',
  setValue,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <input type="hidden" {...field}  value={field.value} />
      )}
    />
  );
};

export default WSHiddenField;

/**
 * Component  : EmployeeBasicDetailsComponent
 * Usage      : Employee add screen
 */
import { Alert, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { callAPI, clearRequest, customValidation, EmployeeStorageVariable, EPhone, formatPhoneNumber, getEmployeeLocalValue, IsSmallScreen, localStorageVariable, Method, validations } from "../../commonFunctions/CommonFunctions";
import WSAutocompleteField from "../../Components/AutoComplete/AutoComplete";
import WSSCheckBoxField from "../../Components/CheckBox/WSCheckBox";
import WSDateField from "../../Components/DateField/WSDateField";
import { openPopUp } from "../../Components/Dialog/OpenNewModal";
import { IMetaData } from "../../Components/Dialog/WSCommonDialog";
import WSHiddenField from "../../Components/HiddenField/WSHiddenField";
import OtpModal from "../../Components/Otp/OtpModal";
import WSPhone from "../../Components/Phone/WSPhone";
import WSSelectField from "../../Components/Select/WSSelect";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import WSTextField from "../../Components/TextBox/WSTextBox";
import config from '../../Config/clientConfig.json';
import AddDepartment from "../AddDepartmentListing/AddDepartment";
import Designation from "../DesignationList/Designation";
import EditWorkLocation from "../EditWorkLocationList/EditWorkLocation";
import OtpSend from "../OtpSend/OtpSend";
interface IEmployeeDetailsProps {
    control: any;
    register: any;
    errors: any
    reset: any
    setValue?: any
    name?: string
    formState?: any
}
const EmployeeBasicDetailsComponent: React.FC<IEmployeeDetailsProps> = React.memo((props) => {
    const { control, register, reset, setValue } = props;
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [otp, setOtp] = useState(''); // Initial OTP state
    const [phone, setPhone] = useState<string>('');
    const source = 'https://yourapi.com'; // Replace with your API URL
    const [reloadWorkLoacation, setReloadWorkLoacation] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [empBaicId, setBasicId] = useState(getEmployeeLocalValue(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.basicId) || null)
    const { showMessage } = useSnackbar();
    const isMobile=IsSmallScreen()
    useEffect(() => () => {
        reset()
    }, [reset])
    useEffect(() => {
        /**
         * Method to get the data for editing pupose
         */
        try {


            async function getData() {
                let response = await callAPI(`employee/empbasicinfo`, Method.GET, {}, empBaicId)
                const empbasicinfo: any = response?.data
                if (setValue && empbasicinfo) {
                    setValue("id", empbasicinfo.id)
                    setValue("firstName", empbasicinfo.firstName)
                    setValue("middleName", empbasicinfo?.middleName)
                    setValue("lastName", empbasicinfo.lastName)
                    setValue("empId", formatPhoneNumber(empbasicinfo.empId, EPhone.AddHyphen))
                    setValue("dateOfJoining", empbasicinfo.dateOfJoining)
                    setValue("email", empbasicinfo.email)
                    setValue("gender", empbasicinfo.gender)
                    setValue("portalAccess", empbasicinfo.portalAccess)
                    setValue("professionalTax", empbasicinfo.professionalTax)
                    setValue("workLocationId", empbasicinfo.workLocation?.id)
                    setValue("designationId", empbasicinfo.designation?.id)
                    setValue("departmentId", empbasicinfo.department?.id)
                }
            }
            if (empBaicId) {
                getData();
            }
        } catch (e) {

        }
    }, [empBaicId, setValue])
    const designationSource = {
        url: 'designation/search',
        page: 1,
        pageSize: 100000,
        filter: [],
        sort: []
    }
    const workLocaionSource = {
        url: 'worklocation/search',
        page: 1,
        pageSize: 100000,
        filter: [],
        sort: []
    }
    const departmentSource = {
        url: 'department/search',
        page: 1,
        pageSize: 100000,
        filter: [],
        sort: []
    }
    const transFormDesignationData = (data: any) => {
        let newData: Array<any> = []
        data.map((item: any) => {
            newData.push({ label: item.designationName, value: item.id })
        })
        return newData;
    }
    const transFormWorkLocationData = (data: any) => {
        let newData: Array<any> = []
        data.map((item: any) => {
            newData.push({ label: item.workLocationName, value: item.id })
        })
        return newData;
    }
    const transFormDepartmentData = (data: any) => {
        let newData: Array<any> = []
        data.map((item: any) => {
            newData.push({ label: item.departmentName, value: item.id })
        })
        return newData;
    }
    const handleSuffixClick = async (value: any, length: number) => {
        setOpen(true)
        const phoneNumber: any = formatPhoneNumber(value, EPhone.RemoveHyphen)
        setPhone(phoneNumber)
        //callSMSAPI(phoneNumber)
        // const otpData = await callAPI('OTP/send', Method.POST, { phoneNumber: `91${phoneNumber}` })  //Sms/send
        // if (otpData && otpData?.otp) {
        //     showMessage(`OTP send to your Phone Number ( ${phoneNumber} ) ${otpData.otp} `, 'success')
        //     setOtp(otpData.otp)
        // }
        // else {
        //     showMessage(`OTP could not send to your Phone Number`, 'error')
        // }
        // openPopUp(otpMetadata, handleOtpData)

    }
    const handleOtpData = (data: any) => {
        if (otp === data) {
            setIsVerified(true)
        }
        else {
            setIsVerified(false)
        }
    }
    const handleOnChange = (data: any) => {

    }

    const otpMetadata: IMetaData = {
        title: "Enter OTP",
        hideActions: false,
        content: OtpSend,
        onChange: handleOnChange,
        actions: [
            { label: 'Cancel', className: "ws-cancel", type: "button" },
            { label: 'submit', onClick: handleOtpData, type: 'submit', autoFocus: true },
        ],
        isDragable: true,
    };
    /**
    * submitting data
    * @param data 
    */
    const handleData = async (data: any) => {
        try {
            let request: any = {
                workLocationName: data.worklocationname,
                addresses: [{
                    id: data.addressId,
                    addressLine1: data.address,
                    addressLine2: data.addressline2,
                    state: data.selectstate,
                    city: data.district,
                    pincode: data.postalcode,
                }]
            };
            let newRequest: any = clearRequest(request);
            if (data?.id && data?.id !== "") {
                const response = await callAPI("worklocation/" + data.id, Method.PUT, newRequest)
                showMessage(response?.message, response?.success === true ? "success" : 'error')
                setReloadWorkLoacation(true)
            }
            else {
                const response = await callAPI("worklocation", Method.POST, newRequest);
                showMessage(response?.message, response?.success === true ? "success" : 'error')
                setReloadWorkLoacation(true)
            }

        } catch (error) {
            console.error("Failed to create Work Location", error);
        }
        finally {
            setReloadWorkLoacation(false)
        }

    };
    /**
     * Add department
     * @param data 
     */
    const createDepartment = async (data: any) => {
        try {
            let request: any = {
                departmentName: data.departmentName,
                departmentCode: data.departmentCode,
                departmentHead: data.departmentHead,
                reportTo: data.reportTo,
                maxLeaveAllowed: data.maxLeaveAllowed,
                description: data.description,
            };

            let newRequest: any = clearRequest(request);
            const response = await callAPI("department", Method.POST, newRequest);
            showMessage(
                response?.message,
                response?.success == true ? "success" : "error"
            );

        } catch (error) {
            console.error("Failed to create Depar")

        }
    };
    /**
     * Add designation
     * @param data 
     */
    const createDesignation = async (data: any) => {
        try {
            let request: any = {
                designationName: data.designationName,
                workLocationId: data.workLocation,
            };
            let newRequest: any = clearRequest(request);
            const response = await callAPI(
                "designation",
                Method.POST,
                newRequest
            );
            showMessage(response?.message, response?.success === true ? "success" : "error");
        } catch (error) {
            console.error("Failed to create designation", error);
        }
    }

    const metadataLocation: IMetaData = {
        title: " Work Location",
        hideActions: false,
        content: (props: any, row: any) => (
            <EditWorkLocation {...props} />
        ),
        actions: [
            {
                label: "Cancel",
                // onClick: handleCloseDialog,
                className: "ws-cancel",
                type: "button",
            },
            {
                label: "Submit",
                onClick: handleData,
                type: "submit",
                autoFocus: true,
            },
        ],
        isDragable: true,
    };
    const metadataDepartment: IMetaData = {
        title: "Add Department",
        hideActions: false,
        content: (props: any, row: any) => (
            <AddDepartment {...props} />
        ),
        actions: [
            {
                label: "Cancel",
                // onClick: handleCloseDialog,
                className: "ws-cancel",
                type: "button",
            },
            {
                label: "Submit",
                onClick: createDepartment,
                type: "submit",
                autoFocus: true,
            },
        ],
        isDragable: true,
    };
    const metadataDesignation: IMetaData = {
        title: "Add Designation",
        hideActions: false,
        content: (props: any, row: any) => (
            <Designation {...props} />
        ),
        actions: [
            {
                label: "Cancel",
                // onClick: handleCloseDialog,
                className: "ws-cancel",
                type: "button",
            },
            {
                label: "Submit",
                onClick: createDesignation,
                type: "submit",
                autoFocus: true,
            },
        ],
        isDragable: true,
    };
    const handleClick = () => {
        openPopUp(metadataLocation, handleData, "40rem");
    };
    const handleAddDepartment = () => {
        openPopUp(metadataDepartment, createDepartment, "40rem");
    }
    const handleAddDesignation = () => {
        openPopUp(metadataDesignation, createDesignation, "40rem");
    }
    const handleOtpSubmit = (otp: string) => {
        if (otp === otp) {
            setIsVerified(true)
        }
        else {
            setIsVerified(false)
        }
        handleClose();
    };

    const handleResendOtp = () => {
       // callSMSAPI(phone)

    };
    async function callSMSAPI(value: string) {
        const phoneNumber = formatPhoneNumber(value, EPhone.RemoveHyphen)
        const otpData = await callAPI('OTP/send', Method.POST, { phoneNumber: `91${phoneNumber}` })  //Sms/send
        if (otpData && otpData?.otp) {
            showMessage(`OTP send to your Phone Number ( 91${phoneNumber} ) ${otpData.otp} `, 'success')
            setOtp(otpData.otp)
        }
        else {
            showMessage(`OTP could not send to your Phone Number`, 'error')
        }
        
    }

    return (
            <>
            <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                    <WSTextField
                        id="firstName"
                        label="First Name"
                        control={control}
                        {...register('firstName')}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <WSTextField
                        id="middleName"
                        {...register('middleName')}
                        label="Middle Name"
                        control={control}
                        rules={{ required: false }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <WSTextField
                        id="lastName"
                        {...register('lastName')}
                        label="Last Name"
                        control={control}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <WSPhone
                        id="empId"
                        //  labelType="Normal"
                        {...register('empId')}
                        label="Employee ID ( Mobile Number ) *"
                        control={control}
                        suffix={{ label: 'Verify', type: "Link" }}
                        rules={{ required: true }}
                        handleSuffixClick={handleSuffixClick}
                        isSuccess={isVerified}
                    />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <WSDateField
                        id='dateOfJoining'
                        name='dateOfJoining'
                        //labelType='Normal'
                        label={'Date of Joining'}
                        dateFormat="MM/DD/YYYY"
                        setValue={setValue}
                        control={control}
                        defaultValue={null}
                        denyFuturOrPast="future"
                        rules={{ required: true }}
                        className="WSTextField"
                    />

                </Grid>
                <Grid item sm={12} xs={12}>
                    <WSTextField
                        id="email"
                        {...register('email')}
                        label="Work Email"
                        control={control}
                        rules={customValidation(true, validations.email)}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <WSSelectField
                        id="gender"
                        {...register('gender')}
                        label="Gender"
                        options={config.genderConfig.staticOptionData}
                        isMobile={isMobile}
                        control={control}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <WSAutocompleteField
                        id="workLocationId"
                        {...register('workLocationId')}
                        label="Work Location"
                        source={workLocaionSource}
                        transFormData={transFormWorkLocationData}
                        handleAddButtonClick={handleClick}
                        addButton={true}
                        control={control}
                        reLoad={reloadWorkLoacation}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <WSAutocompleteField
                        id="designationId"
                        {...register('designationId')}
                        label="Designation"
                        source={designationSource}
                        transFormData={transFormDesignationData}
                        addButton={true}
                        control={control}
                        handleAddButtonClick={handleAddDesignation}
                        rules={{ required: true }}
                    />

                </Grid>
                <Grid item sm={6} xs={12}>
                    <WSAutocompleteField
                        id="departmentId"
                        {...register('departmentId')}
                        label="Department"
                        //  options={options}
                        source={departmentSource}
                        transFormData={transFormDepartmentData}
                        addButton={true}
                        control={control}
                        //defaultValue={10}
                        handleAddButtonClick={handleAddDepartment}
                        rules={{ required: true }}
                    />
                    {/* {openModal(metadata, open, handleCloseDialog)} */}
                </Grid>
                <Grid item sm={6} xs={12}>
                    <WSSCheckBoxField
                        id="portalAccess"
                        {...register('portalAccess')}
                        label="Enable Portal Access"
                        control={control}
                        isMobile={isMobile}
                        rules={{ required: false }}
                        defaultValue={false}
                    />
                    <Alert severity="info" sx={{ mt: 2 }}>
                        The employee will be able to view payslips, submit their IT declaration and create reimbursement claims through the employee portal.
                    </Alert>

                </Grid>
                <Grid item sm={6} xs={12}>
                    <WSSCheckBoxField
                        id="professionalTax"
                        {...register('professionalTax')}
                        label="Professional Tax"
                        control={control}
                        setValue={setValue}
                        isMobile={isMobile}
                        rules={{ required: false }}
                        defaultValue={false}
                    />
                    <Alert severity="info" sx={{ mt: 2 }}>
                        Enable the necessary benefits and tax applicable for this employee
                    </Alert>
                </Grid>
                <WSHiddenField
                    name="hiddenField"
                    control={control}
                    defaultValue={empBaicId}
                    setValue={setValue}
                />
            </Grid>
            <OtpModal
                open={open}
                onClose={handleClose}
                onOtpSubmit={handleOtpSubmit}
                onResendOtp={handleResendOtp}

            />
            {/* {openModal(otpMetadata, open, handleCloseDialog)} */}
            </>
    );
})
export default EmployeeBasicDetailsComponent;

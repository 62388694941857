import React, { useEffect, useState } from 'react';
import { callAPI, EmployeeStorageVariable, getEmployeeLocalValue, IsSmallScreen, localStorageVariable, Method } from '../../commonFunctions/CommonFunctions';
import WSMultiField, { Irows } from '../../Components/Multifields/WSMultiFields';
import { TextType } from '../../Components/TextBox/WSTextBox';
interface IBankInfo {
    control: any;
    register: any;
    errors: any;
    reset?: any;
    watch: any;
    rows: Irows[][];
    rules?: any
    setRows: React.Dispatch<React.SetStateAction<Irows[][]>>;
    setValue?: any
    unregister: any;
    // getMultiFieldData: (data:any) => any[];
}

const LeavTypeInfo: React.FC<IBankInfo> = React.memo((props) => {
    const { control, register, rows, setRows, setValue, unregister } = props;
    const [leavInfoId, setLeavInfoId] = useState(getEmployeeLocalValue(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.leaveInfoId))
    const [leaveTypeRows, setLeaveTypeRows] = useState<Irows[][]>([]);
    const [leaveTypes, setLeaveTypes] = useState<any>([])
    const isMobile=IsSmallScreen()
    useEffect(() => {
        /**
  * Method to binf leavetype dropdown
  */
        try {
            async function getLeveTypes() {
                const source = {
                    page: 1,
                    pageSize: 1000000,
                    filter: [],
                    sort: [],
                };
                const response = await callAPI('leavetype/search', Method.POST, source);
                if (response?.data) {
                    let dataArray: Array<any> = []
                    response.data.map((item: any) => {
                        dataArray.push({ label: item?.name, value: item?.id })
                    })
                    getData(dataArray);
                    setLeaveTypes(dataArray)
                }
            }

            /**
    * Method to get the data for editing pupose
    */
            async function getData(dataArray: any) {
                let response = await callAPI(`employee/leaveinfo`, Method.GET, {}, leavInfoId)
                const leaveInfo: any = response?.data
                const leaveEditFields: any = getFieldsForLeaves(leaveInfo, dataArray)
                setLeaveTypeRows(leaveEditFields)
            }
            if (leavInfoId) {
                getLeveTypes()
                // getData();
            }
        } catch (e) {

        }
    }, [leavInfoId])
    const getFieldsForLeaves = (data: Array<any>, leaveArray: any) => {
        let initialFields: Irows[] = []
        data.map((item: any) => {
            let arrayObj: any = [
                {
                    control: control,
                    label: 'Leave Types',
                    name: 'leaveTypes',
                    rules: { required: true },
                    register: register,
                    defaultValue: item?.leaveType?.id,
                    options: leaveArray,
                    type: "SelectField"
                },
                {
                    control: control,
                    label: 'Leave',
                    name: 'leave',
                    register: register,
                    rules: { required: true },
                    defaultValue: item?.leave,
                    type: "TextField",
                    fieldtype: TextType.Number
                },

            ]
            initialFields.push(arrayObj)
        })
        return initialFields;
    }
    return (
        <>
            {!leavInfoId && <WSMultiField isMobile={isMobile} id="leave" control={control} setValue={setValue} rows={rows} setRows={setRows} fields={rows[0]} unregister={unregister} />}
            {leavInfoId && <WSMultiField isMobile={isMobile} id="leave" control={control} setValue={setValue} rows={leaveTypeRows} setRows={setLeaveTypeRows} fields={leaveTypeRows[0]} unregister={unregister} />}
        </>
    );
});

export default LeavTypeInfo;

import React, { useState } from 'react';
import { Controller, FieldError, UseControllerProps } from 'react-hook-form';
import { Button, FormControl, FormHelperText, FormLabel, IconButton, Box, Typography, Grid } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import decodeBase64Image from '../../commonFunctions/CommonFunctions';

interface WSFileUploadProps extends UseControllerProps {
    label: string;
    id: string;
    name: string;
    className?: string;
    variant?: 'standard' | 'filled' | 'outlined';
    disabled?: boolean;
    acceptFileTypes?: string;
    maxFileSizeMB?: number;
    multiple?: boolean;
    image?: boolean;
    onChange?: (files: File[] | null) => void;
    align?: "Normal" | "Start"
    imgUrl?:any
}

const WSFileUpload: React.FC<WSFileUploadProps> = ({
    id,
    label,
    className,
    control,
    name,
    rules,
    variant = 'outlined',
    disabled = false,
    acceptFileTypes = ".jpg,.bmp,.pdf",
    maxFileSizeMB = 1,
    multiple = false,
    image = false,
    onChange,
    align = "Normal",
    imgUrl=undefined
}) => {
    const [previews, setPreviews] = useState<string[]>([]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, field: any, formState: any) => {
        const newFiles = event.target.files ? Array.from(event.target.files) : [];

        if (newFiles.length === 0) {
            return; // Return early if no new files are selected
        }

        const invalidFiles = newFiles.filter(file => file.size > maxFileSizeMB * 1024 * 1024);

        if (invalidFiles.length > 0) {
            field.onChange([]);
            formState.errors[name] = { type: 'validate', message: `Each file should be less than ${maxFileSizeMB}MB` } as FieldError;
        } else {
            const updatedFiles = multiple ? [...(field.value || []), ...newFiles] : newFiles;
            field.onChange(updatedFiles);

            if (onChange) {
                onChange(updatedFiles);
            }

            if (image) {
                const imagePreviews = updatedFiles.map(file => {
                    if (file.type.startsWith('image/')) {
                        return URL.createObjectURL(file);
                    }
                    return '';
                });
                setPreviews(imagePreviews);
            }
        }
    };

    const handleRemoveFile = (index: number, field: any) => {
        const updatedFiles = field.value.filter((_: File, i: number) => i !== index);
        field.onChange(updatedFiles);

        if (image) {
            const updatedPreviews = previews.filter((_, i) => i !== index);
            setPreviews(updatedPreviews);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState, formState }) => (
                <FormControl className={className} disabled={disabled} error={!!fieldState.error} fullWidth>
                    <FormLabel sx={{fontSize:'0.875rem',color:'black'}}>{label}</FormLabel>
                    <Grid item container spacing={2} flexDirection={'row'}>
                        {align && align === 'Start' && <Grid item sm={3}>
                            <Box mt={2} border={'1px solid gray'} height={'6rem'} width={'5rem'}>
                            {imgUrl && decodeBase64Image(imgUrl)}
                                {!imgUrl && field.value && field.value.length > 0 && field.value.map((file: File, index: number) => (
                                    <>
                                        <Box key={index} display="flex" alignItems="center" justifyContent="space-between">
                                     
                                            {field.value && field.value.length > 0 && image && previews[index] && (
                                                <img
                                                    src={previews[index]}
                                                    alt="preview"
                                                    style={{ width: '5rem', height: '6rem', marginRight: '8px', objectFit: 'fill' }}
                                                />
                                            )}
                                            <IconButton style={{ marginTop: '2rem' }} color="secondary" onClick={() => handleRemoveFile(index, field)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </>
                                ))}
                            </Box>
                        </Grid>}
                        <Grid item sm={9} style={{ marginTop: '20px' }}>
                            <input
                                accept={acceptFileTypes}
                                id={id}
                                type="file"
                                style={{ display: 'none' }}
                                multiple={multiple}
                                onChange={(event) => handleFileChange(event, field, formState)}
                            /></Grid>
                            <Grid item sm={3} style={{ marginTop: '10px',marginBottom:10 }}>
                            <label htmlFor={id}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                >
                                    {multiple ? 'Upload' : 'Upload'}
                                </Button>
                            </label>
                        </Grid>
                    </Grid>
                    {align && align === 'Normal' && field.value && field.value.length > 0 && (
                        <Box mt={2}>
                             {imgUrl && decodeBase64Image(imgUrl)}
                            {!imgUrl && field.value.map((file: File, index: number) => (
                                <Box key={index} display="flex" alignItems="center">
                                   
                                    {image && previews[index] && (
                                        <img
                                            src={previews[index]}
                                            alt="preview"
                                            style={{ width: '50px', height: '50px', marginRight: '8px' }}
                                        />
                                    )}
                                    <Typography variant="body1" style={{ marginRight: '8px' }}>{file.name}</Typography>
                                    <IconButton color="secondary" onClick={() => handleRemoveFile(index, field)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    )}
                    {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                </FormControl>
            )}
        />
    );
};

export default WSFileUpload;

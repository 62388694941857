import { Grid } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WSDataGrid, { IColumns } from "../../Components/DataGrid/WSDataGrid";
import WSMobViewDataGrid from "../../Components/DataGrid/WSMobViewDataGrid";
import { openPopUp } from "../../Components/Dialog/OpenNewModal";
import { IMetaData } from "../../Components/Dialog/WSCommonDialog";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import { resetDataComponent } from "../../StateManagement/actions";
import { callAPI, clearRequest, IRoleMain, IsSmallScreen, MasterPages, Method, } from "../../commonFunctions/CommonFunctions";
import PermissionDenied from "../../commonFunctions/PermissionDenied";
import { getPermissions } from "../../commonFunctions/PermissionManger";
import { Header } from "../Header/Header";
import LeaveTypeMaster from "./LeaveTypeMaster";
import { IRows } from "../../Components/Filter/WSFilterComponent";

export const LeaveTypeMasterListingComponent: React.FC<any> = React.memo(
  (props) => {
    const { showMessage } = useSnackbar();
    const dispatch = useDispatch();
    const isMobileScreen = IsSmallScreen()
    const navigate = useNavigate();
    const source = {
      url: "leavetype/search",
      page: 1,
      pageSize: 10000,
      filter: [],
      sort: [],
    };
    const { view, exports, add, edit } = getPermissions(MasterPages.LeaveTypes, IRoleMain.Masters)
    /**
     * converting data
     * @param data
     * @returns
     */


    const handleClick = () => {
      openPopUp(metadata, handleData, "40rem");
    };

    /**
     * Edit function
     * @param action
     * @param row
     */
    const handleAction = (action: string, row: any) => {
      if (action === "Edit" && !isMobileScreen) {
        const leaveId: any = row.id;
        const metadataLeaveType: IMetaData = {
          title: "Edit Leave Type",
          hideActions: false,
          content: (props: any, row: any) => (
            <LeaveTypeMaster {...props} leavetypeId={leaveId} />
          ),
          actions: [
            {
              label: "Cancel",
              className: "ws-cancel",
              type: "button",
            },
            {
              label: "submit",
              onClick: handleData,
              type: "submit",
              autoFocus: true,
            },
          ],
          isDragable: true,
        };
        openPopUp(metadataLeaveType, handleData, "40rem");
      } else if (action === "Edit" && isMobileScreen) {
        navigate(`/leave-type/edit/${row?.id}`)
      }
    };
    /**
     * submitting data
     * @param data
     */
    const handleData = async (data: any) => {
      try {
        const splitLeaveMaster = data?.leaveMasterId?.split(',') || []
        if (splitLeaveMaster.length === 0) {
          showMessage('Laeve method Code is reqired.', 'error')
          return;
        }

        let request = {
          name: data?.leavetype,
          leave: data?.leavecount,
          leaveMasterId: splitLeaveMaster[0],
          code: splitLeaveMaster[1]
        };
        let newRequest: any = clearRequest(request);
        if (data?.leavetypeId && data?.leavetypeId !== "") {
          const response = await callAPI(
            "leavetype/" + data.leavetypeId,
            Method.PUT,
            newRequest
          );
          dispatch(resetDataComponent('leavetypemasterGrid'));
          showMessage(
            response?.message,
            response?.success === true ? "success" : "error"
          );
        } else {
          const response = await callAPI("leavetype", Method.POST, newRequest);
          dispatch(resetDataComponent('leavetypemasterGrid'));
          showMessage(
            response?.message,
            response?.success === true ? "success" : "error"
          );
        }
      } catch (error) {
        console.error("Failed to post data", error);
      }
    };

    const metadata: IMetaData = {
      title: "Add Leave Type",
      hideActions: false,
      content: (props: any, row: any) => (
        <LeaveTypeMaster {...props} leavetypeId={row?.id} />
      ),
      actions: [
        {
          label: "Cancel",
          className: "ws-cancel",
          type: "button",
        },
        {
          label: "submit",
          onClick: handleData,
          type: "submit",
          autoFocus: true,
        },
      ],
      isDragable: true,
    };

    const columns: IColumns[] = [
      {
        header: "Leave Type",
        fieldName: "name",
        tooltip: true,
        type: 'default',
      },
      {
        header: "Leave Count",
        fieldName: "leave",
        tooltip: true,
        type: 'default',
      },
      {
        header: "Actions",
        fieldName: "actions",
        type: "action",
        actions: [edit ? "Edit" : undefined],
        actionCallback: (action, row) => handleAction(action, row),
      },
    ];
    const handleMobClick = () => {
      navigate('/leave-type/add')
    }
    const filterRows: IRows[] = [
      {
        label: "Leave Type",
        fieldName: "Name",
        type: "text",
        operator: 'like'
      },

    ];
    return (
      <>
        <Header title="Leave Type" type="simple" isDivider={true} />
        {view ?
          <Grid container spacing={2}>
            {!IsSmallScreen() ? <Grid item xs={12}>
              <WSDataGrid
                id="leavetypemasterGrid"
                title="Leave Type "
                filterFields={filterRows}
                columns={columns}
                source={source}
                exportCsv={exports}
                addButton={add ? { label: "", color: "secondary" } : undefined}
                onAddButtonClick={handleClick}
              />
            </Grid> : <Grid item xs={12}>
              <WSMobViewDataGrid
                id="leavetypemasterGrid"
                title="Leave Type "
                filterFields={filterRows}
                columns={columns}
                source={source}
                exportCsv={exports}
                addButton={add ? { label: "", color: "secondary" } : undefined}
                onAddButtonClick={handleMobClick}
              />
            </Grid>}
          </Grid>
          : <PermissionDenied />}
      </>
    );
  }
);

import CheckIcon from '@mui/icons-material/Check';
import {
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { TextFieldPropsSizeOverrides } from '@mui/material/TextField';
import { OverridableStringUnion } from '@mui/types';
import React, { useEffect, useState } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';

interface BankDetails {
  BANK: string;
  BRANCH: string;
  ADDRESS: string;
  CITY: string;
  DISTRICT: string;
  STATE: string;
}

interface FormInputs {
  ifscCode: string;
}

interface IfscSearchProps extends UseControllerProps<FormInputs> {
  id: string;
  label?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  placeholder?: string;
  fullWidth?: boolean;
  required?: boolean;
  adornment?: string;
  control: any;
  name: any;
  rules: any;
  size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
  className?: string;
  showBankDetails?: boolean;
  getValues: any, setValue: any, trigger: any
  defaultValue?: any
}

const IfscSearch: React.FC<IfscSearchProps> = ({
  id,
  label = "Enter IFSC Code",
  variant = "outlined",
  placeholder = "IFSC Code",
  fullWidth = true,
  required = true,
  adornment = "IFSC",
  control,
  name,
  rules,
  size = "small",
  className,
  showBankDetails = false,
  getValues, setValue, trigger, defaultValue,
  ...rest
}) => {
  //  const { getValues, setValue, trigger } = useForm<FormInputs>();
  const [bankDetails, setBankDetails] = useState<BankDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [ifscValid, setIfscValid] = useState(false);

  const handleSearch = async (ifscCode: string) => {
    if (!ifscCode) {
      setBankDetails(null);
      setIfscValid(false);
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`https://ifsc.razorpay.com/${ifscCode}`);
      if (!response.ok) {
        throw new Error('IFSC code not found');
      }
      const bankData: BankDetails = await response.json();
      setBankDetails(bankData);
      setIfscValid(true);
      setValue(name, ifscCode);
      trigger(name);
    } catch (error) {
      console.error('Error:', error);
      setBankDetails(null);
      setIfscValid(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const ifscCode = getValues(name);
      if (ifscCode) {
        handleSearch(ifscCode);
      }
    }, 1000);

  }, [getValues, name, setValue]);

  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{
          ...rules,
          validate: {
            validIfsc: (value) => {
              if (ifscValid && bankDetails) {
                return true;
              }
              return 'Please enter a valid IFSC code';
            },
          },
        }}
        render={({ field, fieldState }) => (
          <TextField
            id={id}
            {...field}
            size={size}
            label={label}
            variant={variant}
            className={fieldState.invalid ? 'WSError' : className}
            placeholder={placeholder}
            defaultValue={defaultValue}
            fullWidth
            error={fieldState.invalid}
            helperText={fieldState.error ? fieldState.error.message : ''}
            onChange={(event) => {
              field.onChange(event.target.value);
              setIfscValid(false);
              handleSearch(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {ifscValid ? <CheckIcon color="success" /> : null}
                  {adornment ? <InputAdornment position="end">{adornment}</InputAdornment> : null}
                </>
              ),
            }}
            {...rest}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '0.875rem',
              },
              '& .MuiFormHelperText-root': {
                fontSize: '0.75rem',
              },
              '& .MuiInputLabel-root': {
                fontSize: '0.875rem',
              },
            }}
          />
        )}
      />

      {bankDetails && showBankDetails && (
        <Typography style={{ fontSize: '0.7rem', color: 'gray', padding: 3 }}>
          {`${bankDetails.BANK}, ${bankDetails.BRANCH}, ${bankDetails.CITY}, ${bankDetails.DISTRICT}, ${bankDetails.STATE}`}
        </Typography>
      )}
    </div>
  );
};

export default IfscSearch;

import { FormControl, FormHelperText, TextFieldPropsSizeOverrides, Typography } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs'; // Import dayjs for date formatting
import React, { useEffect } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
interface ControlledDateFieldProps {
    label: string;
    id: string;
    name: string;
    className?: string;
    variant?: 'standard' | 'filled' | 'outlined';
    disabled?: boolean;
    control: any;
    defaultValue?: any;
    rules: UseControllerProps['rules'];
    onChange?: (date: Date | null) => void;
    required?: boolean;
    setValue?: any;
    dateFormat?: string;
    denyFuturOrPast?: 'none' | 'future' | 'past' | 'custom';
    description?: IDescription;
    labelType?: "Material" | "Normal";
    dateSize?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
}

interface IDescription {
    text: string | any;
    align: "Top" | "Bottom";
    style?: React.CSSProperties;
    className?: string;
}

const WSDateField: React.FC<ControlledDateFieldProps> = ({
    id,
    label,
    className,
    control,
    name,
    rules,
    variant = 'outlined',
    disabled = false,
    defaultValue = null,
    setValue,
    onChange,
    dateFormat = 'MM/DD/YYYY', // Provide default format here
    denyFuturOrPast,
    labelType = "Material",
    description,
    dateSize = "small"
}) => {
    useEffect(() => {
        if (setValue && defaultValue) {
            setValue(name, dayjs(defaultValue, dateFormat).format(dateFormat));
        }
    }, [setValue, name, defaultValue, dateFormat]);

    const maxDate = denyFuturOrPast === 'future' ? dayjs().toDate() : undefined;
    const minDate = denyFuturOrPast === 'past' ? dayjs().toDate() : denyFuturOrPast === 'custom' ? defaultValue : undefined;
   
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field, fieldState }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl className={className} disabled={disabled} error={!!fieldState.error} fullWidth  >
                        {description && description.align === "Top" && (
                            <Typography style={description.style}>{description.text}</Typography>
                        )}
                        {/* {labelType === 'Normal' && (
                            <label style={{ display: 'block', marginBottom: '14px',fontSize:'14px' }} htmlFor={id}>
                                {label}
                            </label>
                        )} */}
                        {/* {labelType === 'Material' && (
                            <Label>{label}</FormLabel>
                        )} */}
                        <DatePicker sx={fieldState.error ? {
                            '& .MuiInputBase-root': {
                                borderColor: 'red', // Adjust the border color
                                borderWidth: 1, // Optional: Adjust the border width
                                borderStyle: 'solid', // Optional: Adjust the border style

                            },

                        } : {
                            '& .MuiInputBase-input': {
                                fontSize: '0.875rem', // Reduce the font size
                            },
                            '& .MuiFormHelperText-root': {
                                fontSize: '0.75rem', // Reduce the font size of the error message
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '0.875rem', // Reduce the font size of the label
                            },
                        }

                        }

                            slotProps={{ textField: { size: dateSize } }}
                            value={field.value ? dayjs(field.value, dateFormat) : null}
                            format={dateFormat}
                            label={label}
                            maxDate={maxDate ? dayjs(maxDate) : undefined}
                            minDate={minDate ? dayjs(minDate) : undefined}
                            onChange={(date) => {
                                const formattedDate: any = date ? dayjs(date).format(dateFormat) : null;
                                field.onChange(formattedDate);
                                if (onChange) {
                                    onChange(formattedDate);
                                }
                            }}

                        />
                        {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                        {description && description.align === "Bottom" && (
                            <Typography style={description.style} className={description?.className}>
                                {description.text}
                            </Typography>
                        )}
                    </FormControl>
                </LocalizationProvider>
            )}
        />
    );
};

export default WSDateField;

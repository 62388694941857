import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { UserDashboard } from './Pages/UserDashboard/UserDashboard';
import DashboardLayout from './Pages/Layout/DashboardLayout';
import PrivateRoute from './PrivateRoute';
import http from './commonFunctions/httpClient';
import { CssBaseline } from '@mui/material';
import LoginForm from './Pages/Login/Login';
import Logout from './Pages/Logout/LogOut';
import theme from './Config/themes.json';
import Layout from './Pages/Layout/Layout';
import config from './Config/config.json';
const SESSION_TIMEOUT = config.sessionTimeOut * 60 * 1000; // 15 minutes

const App: React.FC = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [lastActivity, setLastActivity] = useState<number>(Date.now());

  useEffect(() => {
    if (token) {
      http.setAuthToken(token);
    }
  }, [token]);

  useEffect(() => {
    if (!token) return;

    const handleActivity = () => {
      setLastActivity(Date.now());
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    const checkTimeout = () => {
      if (Date.now() - lastActivity > SESSION_TIMEOUT) {
        handleLogout(); // Logout the user after inactivity
        window.location.reload();
      }
    };

    const intervalId = setInterval(checkTimeout, 1000); // Check for timeout every second

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [lastActivity, token]);

  const handleLogin = (newToken: string) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
    http.setAuthToken(newToken);
  };

  const handleLogout = useCallback(() => {
    setToken(null);
    localStorage.removeItem('token');
    http.setAuthToken(null);
  }, []);

  return (
    <Router>
      <CssBaseline />
      <Routes>
        <Route path="/login" element={token ? <Navigate to="/" /> : <LoginForm onLogin={handleLogin} />} />
        <Route path="/logout" element={<Logout />} />

        {/* Wrap all routes in AdminLayout */}
        <Route path="/" element={token ? (theme.systemSettings.theme.dark ? <DashboardLayout /> : <Layout />) : <Navigate to="/login" />}>
          <Route index element={<UserDashboard />} />
          <Route path="/*" element={<PrivateRoute token={token} />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;

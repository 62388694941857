import { Grid } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WSDataGrid, { IColumns } from "../../Components/DataGrid/WSDataGrid";
import WSMobViewDataGrid from "../../Components/DataGrid/WSMobViewDataGrid";
import { openPopUp } from "../../Components/Dialog/OpenNewModal";
import { IMetaData } from "../../Components/Dialog/WSCommonDialog";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import { resetDataComponent } from "../../StateManagement/actions";
import { callAPI, clearRequest, IRoleMain, IsSmallScreen, MasterPages, Method, } from "../../commonFunctions/CommonFunctions";
import PermissionDenied from "../../commonFunctions/PermissionDenied";
import { getPermissions } from "../../commonFunctions/PermissionManger";
import { Header } from "../Header/Header";
import Designation from "./Designation";
import { IRows } from "../../Components/Filter/WSFilterComponent";

export const DesignationListingComponent: React.FC<any> = React.memo(
  (props) => {
    const { showMessage } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobileScreen = IsSmallScreen()
    const source = {
      url: "designation/search",
      page: 1,
      pageSize: 10,
      filter: [],
      sort: [],
    };
    const { view, exports, add, edit } = getPermissions(MasterPages.Designations, IRoleMain.Masters)
    /**
     * converting data
     * @param data
     * @returns
     */
    const transFormData = (data: any) => {
      return data.map((item: any) => {
        item.workLocationName = item?.workLocation?.workLocationName;
        return item;
      });
    };

    const handleClick = () => {
      openPopUp(metadata, handleData, "40rem");
    };

    /**
     * edit function
     * @param action
     * @param row
     */
    const handleAction = (action: string, row: any) => {
      if (action === "Edit" && !isMobileScreen) {
        const desigId: string = row.id;
        const metadataDesignation: IMetaData = {
          title: "Edit Designation",
          hideActions: false,
          content: (props: any, row: any) => (
            <Designation {...props} designationId={desigId} />
          ),
          actions: [
            {
              label: "Cancel",
              className: "ws-cancel",
              type: "button",
            },
            {
              label: "submit",
              onClick: handleData,
              type: "submit",
              autoFocus: true,
            },
          ],
          isDragable: true,
        };
        openPopUp(metadataDesignation, handleData, "40rem");
      } else if (action === "Edit" && isMobileScreen) {
        navigate(`/designations/edit/${row?.id}`)
      }
    };
    /**
     * submitting data
     * @param data
     */
    const handleData = async (data: any) => {
      try {
        let request: any = {
          designationName: data.designationName,
          workLocationId: data.workLocation,
        };
        let newRequest: any = clearRequest(request);

        if (data?.designationId && data?.designationId !== "") {
          const response = await callAPI(
            "designation/" + data.designationId,
            Method.PUT,
            newRequest
          );
          dispatch(resetDataComponent('designationGrid'));
          showMessage(
            response?.message,
            response?.success === true ? "success" : "error"
          );
        } else {
          const response = await callAPI(
            "designation",
            Method.POST,
            newRequest
          );
          dispatch(resetDataComponent('designationGrid'));
          showMessage(
            response?.message,
            response?.success === true ? "success" : "error"
          );
        }
      } catch (error) {
        console.error("Failed to create designation", error);
      }
    };

    const metadata: IMetaData = {
      title: "Add Designation",
      hideActions: false,
      content: (props: any, row: any) => (
        <Designation {...props} designationId={row?.id} />
      ),
      actions: [
        {
          label: "Cancel",
          className: "ws-cancel",
          type: "button",
        },
        {
          label: "submit",
          onClick: handleData,
          type: "submit",
          autoFocus: true,
        },
      ],
      isDragable: true,
    };

    const columns: IColumns[] = [
      {
        header: "Designation Name",
        fieldName: "designationName",
        tooltip: true,
        type: 'default',
      },
      {
        header: "Work Location",
        fieldName: "workLocationName",
        tooltip: true,
        type: 'default',
      },
      {
        header: "Actions",
        fieldName: "actions",
        type: "action",
        actions: [edit ? "Edit" : undefined],
        actionCallback: (action, row) => handleAction(action, row),
      },
    ];
    const handleMobClick = () => {
      navigate('/designations/add')
    }
    const filterRows: IRows[] = [
      {
        label: "Name",
        fieldName: "DesignationName",
        type: "text",
        operator: 'like'
      },

    ];
    return (
      <>  <Header title="Designations" type="simple" isDivider={true} />
        {view ?
          <Grid container spacing={2}>
            {!IsSmallScreen() ? <Grid item xs={12}>
              <WSDataGrid
                transFormData={transFormData}
                id="designationGrid"
                title="Designations"
                filterFields={filterRows}
                columns={columns}
                source={source}
                exportCsv={exports}
                addButton={add ? { label: "", color: "secondary" } : undefined}
                onAddButtonClick={handleClick}
              />
            </Grid> : <Grid item xs={12}><WSMobViewDataGrid
              transFormData={transFormData}
              id="designationGrid"
              title="Designations List"
              filterFields={filterRows}
              columns={columns}
              source={source}
              exportCsv={exports}
              addButton={add ? { label: "", color: "secondary" } : undefined}
              onAddButtonClick={handleMobClick}
            /></Grid>}
          </Grid>
          : <PermissionDenied />}
      </>
    );
  }
);

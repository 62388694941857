import React from 'react';
import { Modal, Button, IconButton, Divider } from '@mui/material'; // Ensure you have @mui/material installed
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon
import OTPInput from './Otp';

interface OtpModalProps {
  open: boolean;
  onClose: () => void;
  onOtpSubmit: (otp: string) => void;
  onResendOtp: () => void;
}

const OtpModal: React.FC<OtpModalProps> = ({ open, onClose, onOtpSubmit, onResendOtp }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="otp-modal-title"
      aria-describedby="otp-modal-description"
      style={styles.modalContainer} // Center the modal
    >
      <div style={styles.modalContent}>
        {/* Close button at the top right corner */}
        <IconButton 
          onClick={onClose} 
          style={styles.closeButtonIcon} 
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        <h2 id="otp-modal-title" style={styles.modalTitle}>Enter OTP</h2>
        <Divider style={{marginBottom:20}}/>
        <OTPInput numberOfColunms={4} onSubmit={onOtpSubmit} onResend={onResendOtp} />
      </div>
    </Modal>
  );
};

// Styles for the modal and content
const styles: { [key: string]: React.CSSProperties } = {
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    padding: '30px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
    maxWidth: '400px',
    width: '90%',
    textAlign: 'center',
    position: 'relative',
  },
  modalTitle: {
    marginBottom: '40px',
    fontSize: '1.15rem',
    fontWeight: '500',
    color: '#333',
  },
  buttonContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  closeButton: {
    backgroundColor: '#f44336',
    color: '#fff',
    textTransform: 'none',
    padding: '10px 20px',
    borderRadius: '6px',
    boxShadow: 'none',
    transition: 'background-color 0.3s ease',
  },
  closeButtonIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#f44336', // Optional: change color to match the close button
  },
};

export default OtpModal;

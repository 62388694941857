import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentsIcon from '@mui/icons-material/Payments';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import { Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { callAPI, clearRequest, EmployeeStorageVariable, EPhone, formatPhoneNumber, getEmployeeLocalValue, localStorageVariable, Method, setEmployeeLocalValues } from "../../commonFunctions/CommonFunctions";
import { Irows } from "../../Components/Multifields/WSMultiFields";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import WSStepper from "../../Components/Stepper/WSStepper";
import { TextType } from "../../Components/TextBox/WSTextBox";
import { IEmployeeBankInfo, IEmployeeBasic, IEmpPersonalInfo, ILeaveInfo, ISalaryInfo } from "../Interfaces/Interfaces";
import BankInfo from "./BankInfo";
import EmployeeBasicDetailsComponent from "./EmployeeBasicDetails";
import LeavTypeInfo from "./LeavTypeInfo";
import PersonalDetails from "./PersonalDetails";
import SalaryDetailsComponent from "./SalaryDetails";
import { Header } from '../Header/Header';
const EmployeeWizard: React.FC<any> = React.memo(() => {
    const { control, reset, handleSubmit, register, formState: { errors, isSubmitted, isSubmitting, }, trigger, watch, setValue, getValues, unregister } = useForm<any>();
    const { showMessage } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false)
    const [leaveTypes, setLeaveTypes] = useState<any>([])
    // const [leaveSubmitData, setLeaveSubmitData] = useState([])
    const [leaveTypeRows, setLeaveTypeRows] = useState<Irows[][]>([]);
    const steps = ['Basics', 'Payment Info', 'Personal Info', 'Salary Details', 'Leave info'];
    const navigate = useNavigate();
    const [isSuccess, setSuccess] = useState(false)
    const stepperRef = useRef<{ handleNext: () => Promise<void> }>(null);

    useEffect(() => {
        /**
         * Method to get the leavetypes to bind in the dropdown
         */
        try {
            async function getLeveTypes() {
                const source = {
                    page: 1,
                    pageSize: 1000000,
                    filter: [],
                    sort: [],
                };
                const response = await callAPI('leavetype/search', Method.POST, source);
                if (response?.data) {
                    let dataArray: Array<any> = []
                    response.data.map((item: any) => {
                        dataArray.push({ label: item?.name, value: item?.id })
                    })
                    setLeaveTypes(dataArray)
                    const initialFields: Irows[] = [
                        {
                            control: control,
                            label: 'Leave Types',
                            name: 'leaveTypes',
                            rules: { required: true },
                            register: register,
                            options: dataArray,
                            type: "SelectField"
                        },
                        {
                            control: control,
                            label: 'Leave',
                            name: 'leave',
                            register: register,
                            rules: { required: true },
                            type: "TextField",
                            fieldtype: TextType.Number
                        },

                    ];
                    setLeaveTypeRows([initialFields])
                }
            }
            getLeveTypes();
        }
        catch (e) {

        }
    }, [])

    /**
     * Method to set the Levve info request
     * @param data 
     * @param empId 
     * @returns 
     */
    function setEmployeeLeavInfoRequet(data: any, empId: any) {
        const leaveIfodata: any = getLeaveNodes(data, empId);
        return leaveIfodata
    }
    /**
     * Get the leave nodes
     * @param data 
     * @param empId 
     * @returns 
     */
    function getLeaveNodes(data: any, empId: any): ILeaveInfo[] {
        const leaveNodes: ILeaveInfo[] = [];

        const multiFieldData = leaveTypeRows.map((row, rowIndex) => {
            return row.reduce((acc: any, field) => {
                const fieldName = `${field.name}_${rowIndex}`;
                acc[field.name] = data[fieldName]; // Access the form values
                return acc;
            }, {});
        });

        const finalData = { ...data, multiFieldRows: multiFieldData };
      
        Object.keys(data).forEach(key => {
            if (key.startsWith('leaveTypes_')) {
                const index = key.split('_')[1];
                const leaveTypeId = data[key];
                const leave = Number(data[`leave_${index}`]);
                const employeeId = empId;
                leaveNodes.push({ leaveTypeId, leave, employeeId });
            }
        });

        return leaveNodes;
    }
    const onSubmit = async (data: any, stepInfo: string, stepIndex?: Number) => {


        const resp: any = await toCallAPI(stepInfo, data)
        if (stepIndex == steps.length - 1 && resp) {
            navigate('/employee/list')
        }
    };
    /**
     * Set Basic request
     * @param data 
     * @returns 
     */
    function setEmployeeBasicDetailsRequet(data: any) {
        let reauest: IEmployeeBasic = {
            firstName: data.firstName,
            middleName: data?.middleName,
            lastName: data.lastName,
            empId: formatPhoneNumber(data.empId, EPhone.RemoveHyphen),
            dateOfJoining: data.dateOfJoining,
            email: data.email,
            gender: data.gender,
            workLocationId: data.workLocationId,
            designationId: data.designationId,
            departmentId: data.departmentId,
            portalAccess: data.portalAccess,
            professionalTax: data.professionalTax
        }
        return clearRequest(reauest);
    }
    /**
     * Set Salary request
     * @param data 
     * @param empId 
     * @returns 
     */
    function setSalaryInfoRequet(data: any, empId: string) {
        let reauest: ISalaryInfo = {
            payType: data.payType,
            payCalculation: "1", //data?.payCalculation?.toString(),
            pf: data.PF,
            esi: data.ESI,
            monthlyAmount: data.monthlyAmount,
            amount: data.amount,
            annualAmount: data.annualAmount,
            employeeId: empId
        }
        return clearRequest(reauest);
    }
    function setEmployeeBankInfoRequet(data: any, empId: any) {
        let reauest: IEmployeeBankInfo = {
            accountName: data.accountName,
            accountNumber: data?.accountNumber,
            bankType: data.bankType,
            ifsc: data.ifsc,
            bankName: data.bankName,
            empId: empId
        }
        return clearRequest(reauest);
    }
    /**
     * Set Personal request
     * @param data 
     * @param empId 
     * @returns 
     */
    function setEmployeePersonalDetailsRequet(data: any, empId: any) {
        let reauest: IEmpPersonalInfo = {
            email: data.email,
            mobile: data.mobile,
            dob: data.dob,
            fatherName: data.fatherName,
            age: Number(data.age),
            pan: data.pan,
            emergencyContact: formatPhoneNumber(data?.emergencyContact, EPhone.RemoveHyphen),
            employeeId: empId,
            residentialAddress: [{
                addressLine1: data.addressLine1,
                addressLine2: data.addressLine2,
                city: data.city,
                state: data.state,
                pincode: data.pincode
            }],
            emergencyAddress: [{
                addressLine1: data.eAddressLine1,
                addressLine2: data.eAddressLine2,
                city: data.eCity,
                state: data.eState,
                pincode: data.ePincode
            }]
        }
        if (data.addressId) {
            reauest.residentialAddress[0].id = data.addressId;
        } if (data.eAddressId) {
            reauest.emergencyAddress[0].id = data.eAddressId;
        }
        return clearRequest(reauest);
    }
    // const handleLeaveData=(data:any):any=>{
    //     setLeaveSubmitData(data)
    // }
    const callNextPage = async (response: any) => {
        if (stepperRef.current && response.success) {
            await stepperRef.current.handleNext(); // Call the handleNext function from WSStepper
        }
    }
    /**
     * Call API based on the tabs
     * @param stepInfo 
     * @param data 
     * @returns 
     */
    async function toCallAPI(stepInfo: string, data: any) {
        let id: string = '';
        let success = false;
        const empId: any = getEmployeeLocalValue(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.basicId)
        try {
            setIsLoading(true)
            switch (stepInfo) {
                case 'Basics':
                    const basicRequest: any = setEmployeeBasicDetailsRequet(data)
                    if (!empId) {
                        const basicResponse: any = await callAPI('employee/empbasicinfo', Method.POST, basicRequest)
                        if (basicResponse && basicResponse.data && basicResponse.data.id) {
                            showMessage(basicResponse?.message, "success")
                            id = basicResponse.data.id
                            setSuccess(true)
                            callNextPage(basicResponse)
                        } else {
                            const errorMessage = basicResponse?.message || 'Something went wrong';
                            showMessage(errorMessage, 'error');
                            setSuccess(false)
                            return;
                        }
                        setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.basicId, id)
                        // dispatch(storeDataComponent("EmployeeBasicDetails",{basicId:id}));
                    } else {
                        const basicResponse: any = await callAPI(`employee/empbasicinfo/${empId}`, Method.PUT, basicRequest)

                        if (basicResponse) {
                            showMessage(basicResponse?.message, basicResponse.success ? "success" : "error")
                            setSuccess(basicResponse.success)
                            callNextPage(basicResponse)
                        }
                    }
                    setIsLoading(false)
                    break;
                case 'Payment Info':
                    const bankInfoId: any = getEmployeeLocalValue(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.bankInfoId)
                    const bankRequest: any = setEmployeeBankInfoRequet(data, empId)
                    if (!bankInfoId) {
                        const bankResponse: any = await callAPI('employee/empbankinfo', Method.POST, bankRequest)
                        if (bankResponse && bankResponse.data && bankResponse.data.id) {
                            showMessage(bankResponse?.message, "success")
                            id = bankResponse.data.id
                            setSuccess(true)
                            callNextPage(bankResponse)
                        } else {
                            const errorMessage = bankResponse?.message || 'Something went wrong';
                            showMessage(errorMessage, 'error');
                            return;
                        }
                        setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.bankInfoId, id)
                    } else {
                        const bankResponse: any = await callAPI(`employee/empbankinfo/${bankInfoId}`, Method.PUT, bankRequest)
                        if (bankResponse) {
                            showMessage(bankResponse?.message, bankResponse.success ? "success" : "error")
                            setSuccess(bankResponse.success)
                            callNextPage(bankResponse)
                        }
                    }
                    setIsLoading(false)
                    break;
                case 'Personal Info':
                    const personalInfoId: any = getEmployeeLocalValue(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.personalInfoId)
                    const personalRequest: any = setEmployeePersonalDetailsRequet(data, empId)
                    if (!personalInfoId) {
                        const personalResponse: any = await callAPI('employee/emppersonalinfo', Method.POST, personalRequest)
                        if (personalResponse && personalResponse.data && personalResponse.data.id) {
                            showMessage(personalResponse?.message, "success")
                            id = personalResponse.data.id
                            setSuccess(true)
                            callNextPage(personalResponse)
                        } else {
                            const errorMessage = personalResponse?.message || 'Something went wrong';
                            showMessage(errorMessage, 'error');
                            return;
                        }
                        setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.personalInfoId, id)
                    } else {
                        const personalResponse: any = await callAPI(`employee/emppersonalinfo/${personalInfoId}`, Method.PUT, personalRequest)
                        if (personalResponse) {
                            showMessage(personalResponse?.message, personalResponse.success ? "success" : "error")
                            setSuccess(personalResponse.success)
                            callNextPage(personalResponse)
                        }
                    }
                    setIsLoading(false)
                    break;
                case 'Leave info':
                    const leaveInfoId: any = getEmployeeLocalValue(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.leaveInfoId)
                    const leaveRequest: any = setEmployeeLeavInfoRequet(data, empId)
                    if (!leaveInfoId) {
                        const leaveResponse: any = await callAPI('employee/leaveinfo', Method.POST, leaveRequest)
                        if (leaveResponse?.data) {
                            const responeData: any = Array.isArray(leaveResponse.data) ? leaveResponse.data[0] : leaveResponse?.data
                            showMessage(leaveResponse?.message, "success")
                            id = responeData.groupId
                            success = leaveResponse?.success
                            setSuccess(true)
                            callNextPage(leaveResponse)
                        } else {
                            const errorMessage = leaveResponse?.message || 'Something went wrong';
                            showMessage(errorMessage, 'error');
                            return;
                        }
                        setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.leaveInfoId, id)
                    } else {
                        const leaveResponse: any = await callAPI(`employee/leaveinfo/${leaveInfoId}`, Method.PUT, leaveRequest)

                        if (leaveResponse?.data) {
                            const responeData: any = Array.isArray(leaveResponse.data) ? leaveResponse.data[0] : leaveResponse?.data
                            showMessage(leaveResponse?.message, leaveResponse?.success ? "success" : "error")
                            id = responeData.groupId
                            success = leaveResponse?.success
                            setSuccess(true)
                            setSuccess(leaveResponse.success)
                            callNextPage(leaveResponse)
                        }
                        setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.leaveInfoId, id)
                    }
                    setIsLoading(false)
                    break;
                case 'Salary Details':
                    const salaryInfoId: any = getEmployeeLocalValue(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.salaryInfoId)
                    const salaryInfoRequest: any = setSalaryInfoRequet(data, empId)
                    if (!salaryInfoId) {
                        const salaryInfoResponse: any = await callAPI('employee/salaryinfo', Method.POST, salaryInfoRequest)
                        if (salaryInfoResponse && salaryInfoResponse.data && salaryInfoResponse.data.id) {
                            showMessage(salaryInfoResponse?.message, "success")
                            id = salaryInfoResponse.data.id
                            setSuccess(true)
                            callNextPage(salaryInfoResponse)
                        } else {
                            const errorMessage = salaryInfoResponse?.message || 'Something went wrong';
                            showMessage(errorMessage, 'error');
                            return;
                        }
                        setEmployeeLocalValues(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.salaryInfoId, id)
                    } else {
                        const salaryInfoResponse: any = await callAPI(`employee/salaryinfo/${salaryInfoId}`, Method.PUT, salaryInfoRequest)
                        if (salaryInfoResponse) {
                            showMessage(salaryInfoResponse?.message, salaryInfoResponse.success ? "success" : "error")
                            setSuccess(salaryInfoResponse.success)
                            callNextPage(salaryInfoResponse)
                        }
                    }
                    setIsLoading(false)
                    break;
            }
        } catch (e: any) {
            showMessage(e, "error")
            setIsLoading(false)
        }
        finally {
            setIsLoading(false)
        }
        return success
    }

    const stepComponents = [
        <div key="step-1">
            <EmployeeBasicDetailsComponent name="basicDetails" control={control} formState={{ errors, isSubmitted }} register={register} errors={errors} reset={reset} setValue={setValue} />
        </div>,
        <div key="step-2">
            <BankInfo control={control} register={register} errors={errors} reset={reset} watch={watch} getValues={getValues} trigger={trigger} setValue={setValue} />
            {/* <SalaryDetailsComponent id="salaryDetails" control={control} register={register} errors={errors} reset={reset} setValue={setValue} /> */}
        </div>,
        <div key="step-3">
            <PersonalDetails name="personalDetails" control={control} register={register} errors={errors} setValue={setValue} />
        </div>,
        <div key="step-4">
            <SalaryDetailsComponent id="salaryDetails" control={control} register={register} errors={errors} reset={reset} setValue={setValue} />
            {/* <BankInfo control={control} register={register} errors={errors} reset={reset} watch={watch} /> */}
        </div>,
        <div key="step-5">
            {leaveTypes && leaveTypes.length > 0 && <LeavTypeInfo  control={control} register={register} errors={errors} reset={reset} setValue={setValue} watch={watch} rows={leaveTypeRows} unregister={unregister} setRows={setLeaveTypeRows} />}
        </div>
    ];

    const icons = {
        1: <PersonOutlineIcon />,
        2: <PaymentsIcon />,
        3: <SettingsAccessibilityIcon />,
        4: <AccountBalanceIcon />,
        5: <PaymentsIcon />,
    };
    // const { data, isLoading, error } = useSelector((state: RootState) => ({
    //   data: state.components?.['EmployeeBasicDetails']?.data,
    //   isLoading: state.components?.['EmployeeBasicDetails']?.isLoading,
    //   error: state.components?.['EmployeeBasicDetails']?.error,
    // }));


    return (
        <><Header title={"Employee"} type="simple" url="/employee/list" isBackButton={true} isDivider={true}/>
        <Paper elevation={3} style={{ padding: '10px', margin: '0px', border: '1px solid #ccc', borderRadius: '8px' }}>
            
               
                    <WSStepper
                        ref={stepperRef}
                        steps={steps}
                        stepComponents={stepComponents}
                        initialValues={{}}
                        onSubmitValue={onSubmit}
                        icons={icons}
                        trigger={trigger}
                        handleSubmit={handleSubmit}
                        loading={isLoading}
                        isSuccess={isSuccess}
                    />
          
            
        </Paper></>
    );
});

export default EmployeeWizard;

import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import { callAPI, IsSmallScreen, Method } from "../../commonFunctions/CommonFunctions";
import WSSelectField from "../../Components/Select/WSSelect";
import WSTextField, { TextType } from "../../Components/TextBox/WSTextBox";

interface LeaveTypeMasterProps {
  control: Control<any>;
  errors: FieldErrors;
  register: UseFormRegister<any>;
  id: any;
  reset: UseFormReset<any>;
  type: any;
  setValue: UseFormSetValue<any>;
  leavetypeId: any;
}

const LeaveTypeMaster: React.FC<LeaveTypeMasterProps> = ({
  control, errors, register, id, reset, type, setValue, leavetypeId, }) => {
  const [leaveMethods, setLeaveMethods] = useState<any>([])
  const isMobile = IsSmallScreen()
  useEffect(
    () => () => {
      reset();
    },
    [id, reset]
  );
  // ceca1c23-26ae-4e0e-949f-c0e0e5ae8ce5,CL
  useEffect(() => {
    async function getLeaveMethods() {
      const response: any = await callAPI("LeaveMaster", Method.GET, {});

      if (response && response?.length > 0) {
        let leaveMethods: any = []
        response?.map((item: any) => {
          let method: any = {};
          method.label = item?.name;
          method.value = item?.id + ',' + item?.code
          leaveMethods.push(method)
        })

        setLeaveMethods(leaveMethods)
      }
    }
    getLeaveMethods();
  }, [])
  useEffect(() => {
    /**
     * Edit Function
     * @param id
     */
    async function getLeaveType(id: any) {
      try {
        const response: any = await callAPI("leaveType", Method.GET, {}, id);
        if (response?.data) {
          setValue("leavetype", response.data?.name);
          setValue("leavecount", response.data?.leave);
          setValue("leaveMasterId", response.data?.leaveMasterId + ',' + response?.data?.code);
          setValue("leavetypeId", response.data?.id);
          // setValue("leaveMasterId", response.data.id);
        }
      } catch (error) {
        console.log("failed to retrieve data", error);
      }
    }
    if (leavetypeId) {
      getLeaveType(leavetypeId);
    }
  }, [leavetypeId, leaveMethods]);

  return (

      <Grid className="modal-input" container spacing={2}>
        <Grid item xs={12} sm={12}>
          {<WSSelectField
            options={leaveMethods}
            rules={{ required: true }}
            {...register("leaveMasterId")}
            id="leaveMasterId"
            isMobile={isMobile}
            control={control}
            label="Leave Method"
            name="leaveMasterId"
          //labelType="Normal"
          ></WSSelectField>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <WSTextField
            control={control}
            id="leavetype"
            {...register("leavetype")}
            rules={{ required: true }}
            name="leavetype"
            label="Leave Type *"
          //labelType="Normal"
          />
          {errors.description && (
            <Typography variant="body2" color="error">
              Leave Type is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <WSTextField
            control={control}
            id="leavecount"
            {...register("leavecount")}
            rules={{ required: true }}
            name="leavecount"
            label="Leave Count *"
            //labelType="Normal"
            type={TextType.Number}
          />
          {errors.description && (
            <Typography variant="body2" color="error">
              Leave Count is required
            </Typography>
          )}
        </Grid>
        <Grid style={{ display: "none" }} >
          <WSTextField
            control={control}
            id="leavetypeId"
            {...register("leavetypeId")}
            rules={{ required: false }}
            name="leavetypeId"
            label="leavetypeId"
            //labelType="Normal"
            type={TextType.Number}
          />
        </Grid>
      </Grid>

  );
};
export default LeaveTypeMaster;

import React, { useEffect, useState, useCallback } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { Autocomplete, TextField, Typography, IconButton, Grid, FormControl, AutocompletePropsSizeOverrides } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import http from '../../commonFunctions/httpClient';
import store from '../../StateManagement/store';
import { OverridableStringUnion } from '@mui/types';
interface ControlledAutocompleteFieldProps {
  label: any;
  id: string;
  name: string;
  className?: string;
  disabled?: boolean;
  control: any;
  defaultValue?: any;
  rules?: UseControllerProps['rules'];
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  required?: boolean;
  setValue?: any;
  options?: { value: string | number; label: string }[];
  description?: IDescription;
  addButton?: boolean;
  handleAddButtonClick?: (e: any) => void;
  source?: {
    url: string;
    pageSize: number;
    page: number;
    filter: any[];
    sort: string[];
  };
  transFormData?: (data: any) => any;
  controllSize?: OverridableStringUnion<'small' | 'medium', AutocompletePropsSizeOverrides>;
  reLoad?: boolean,
  isMobile?: boolean
}

interface IDescription {
  text: string | any;
  align: "Top" | "Bottom";
  style?: React.CSSProperties;
  className?: string;
}

const WSAutocompleteField: React.FC<ControlledAutocompleteFieldProps> = ({
  id,
  label,
  className,
  control,
  name,
  rules,
  disabled = false,
  defaultValue = '',
  setValue,
  onChange,
  options,
  description,
  addButton,
  handleAddButtonClick,
  source,
  transFormData,
  controllSize = 'small',
  reLoad = false,
  isMobile = false
}) => {
  const [loading, setLoading] = useState<boolean>(source ? true : false);
  const [newOptions, setNewOptions] = useState<any>(options || []);

  useEffect(() => {
    if (setValue) {
      setValue(name, defaultValue);
    }
  }, [setValue, name, defaultValue]);

  const fetchDataFromAPI = async (
    page: number,
    pageSize: number,
    filter: any[] = [],
    sort: any[] = []
  ) => {
    try {
      setLoading(true);
      if (source) {
        const response: any = await http.post(source?.url, {
          page: page,
          pageSize: pageSize,
          filter: filter,
          sort: sort,
        });
        return response;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadPageData = useCallback(async () => {
    try {
      let response = await fetchDataFromAPI(1, 100000, source?.filter);

      if (response?.data && transFormData) {
        setNewOptions(transFormData(response.data));
      } else if (response?.data) {
        setNewOptions(response.data);
      }
    } catch (error) {
      console.error('Error loading page data:', error);
    }
  }, [source, id, transFormData]);

  useEffect(() => {
    if (source && loadPageData && loading) {
      loadPageData();
    } else if (reLoad) {
      loadPageData()

    }
  }, [source, loadPageData, reLoad]);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.components[id]?.data === null) {
        loadPageData();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [id, loadPageData]);

  const handleClick = (e: any) => {
    if (handleAddButtonClick) {
      handleAddButtonClick(e);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => (
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={addButton ? 11 : 12} xs={ addButton?9:12 }>
            <FormControl fullWidth>
              <div className={className}>
                {description && description.align === "Top" && (
                  <Typography style={description.style}>{description.text}</Typography>
                )}
                <Autocomplete
                  id={id}
                  size={controllSize}
                  options={newOptions}
                  getOptionLabel={(option: any) => option.label}
                  value={newOptions.find((option: any) => option.value === field.value) || null}
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '0.875rem', // Reduce the font size
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: '0.75rem', // Reduce the font size of the error message
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '0.875rem', // Reduce the font size of the label
                    },
                  }}
                  onChange={(event: any, value) => {
                    field.onChange(value?.value || null);
                    if (onChange) {
                      onChange(event);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={label}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disabled={disabled}
                      fullWidth
                    />
                  )}
                />
                {description && description.align === "Bottom" && (
                  <Typography style={description.style} className={description?.className}>
                    {description.text}
                  </Typography>
                )}
              </div>
            </FormControl>
          </Grid>
          {addButton && (
            <Grid item sm={1} xs={3} style={{ display: 'flex', alignItems: 'center' }} justifyContent={'end'}>
              <IconButton aria-label="add" onClick={handleClick}>
                <AddIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
    />
  );
};

export default WSAutocompleteField;

import React, { useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getMenu } from '../../Config/menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getLocalValue, IsSmallScreen, LoacalVariables } from '../../commonFunctions/CommonFunctions';

interface MenuItem {
  id: number;
  label: string;
  path?: string;
  icon: React.ElementType;
  children?: MenuItem[];
}

interface SidebarProps {
  isCollapsed: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed }) => {
  const userType: any = getLocalValue(LoacalVariables.UserType);
  const [openChildMenus, setOpenChildMenus] = useState<Record<number, boolean>>({});
  const [hoveredMenuId, setHoveredMenuId] = useState<number | null>(null);
  const [screenHeight, setScreenHeight] = useState(window.outerHeight);
  const location = useLocation();  // Get the current location from React Router
  const navigate = useNavigate()
  const isMobile = IsSmallScreen()
  const handleToggleChildMenu = (id: number) => {
    setOpenChildMenus((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleMouseEnter = (id: number) => {
    if (isCollapsed) {
      setHoveredMenuId(id);
    }
  };

  const handleMouseLeave = () => {
    setHoveredMenuId(null);
  };

  const menu = getMenu(userType);

  // Helper function to determine if the menu item is selected
  const isSelected = (path?: string) => {
    return path && location.pathname === path;
  };

  const renderMenu = (menuItem: MenuItem) => (
    <li
      key={menuItem.id}
      onMouseEnter={() => handleMouseEnter(menuItem.id)}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative' }}
    >
      <ListItemButton
        component={Link}
        to={menuItem.path || '#'}
        onClick={() => menuItem.children && handleToggleChildMenu(menuItem.id)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px 16px',
          backgroundColor: isSelected(menuItem.path) ? '#3e3e4f' : 'transparent', // Change background if selected
          color: isSelected(menuItem.path) ? '#fff' : '#bdbdbd', // Change text color if selected
          '&:hover': {
            backgroundColor: isSelected(menuItem.path) ? '#3e3e4f' : '#3e3e4f', // Keep the same color on hover if selected
          },
        }}
      > {((!isMobile && isCollapsed) || (!isMobile && !isCollapsed) || (isMobile && !isCollapsed)) && (
        <ListItemIcon>

          <menuItem.icon sx={{ color: isSelected(menuItem.path) ? '#fff' : '#bdbdbd' }} />

        </ListItemIcon>

      )}
        {!isCollapsed && (
          <ListItemText
            primary={menuItem.label}
            sx={{
              '& .MuiTypography-root': {
                fontSize: '0.875rem',
                marginLeft: -2,
              },
            }}
          />
        )}
        {menuItem.children && (
          <ListItemIcon sx={{ marginLeft: 'auto' }}>
            {!openChildMenus[menuItem.id] ? (
              <ExpandLessIcon sx={{ color: '#fff', display: isCollapsed ? 'none' : '' }} />
            ) : (
              <ExpandMoreIcon sx={{ color: '#fff', display: isCollapsed ? 'none' : '' }} />
            )}
          </ListItemIcon>
        )}
      </ListItemButton>

      {((!isMobile && isCollapsed) || (!isMobile && !isCollapsed) || (isMobile && !isCollapsed)) &&menuItem.children && isCollapsed && hoveredMenuId === menuItem.id && (
        <ul className="child-menu" style={{
          position: 'absolute',
          left: '60px',
          top: '0px',
          zIndex: 1000,
          backgroundColor: '#2c2c3a',
          padding: '0',
          margin: '0',
          listStyle: 'none',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.6)',
        }}>
          {menuItem.children.map((child) => (
            <li key={child.id} style={{ width: '14rem' }}>
              <ListItemButton
                component={Link}
                to={child.path || '#'}
                sx={{
                  paddingLeft: '24px',
                  padding: '8px',
                  backgroundColor: isSelected(child.path) ? '#3e3e4f' : 'transparent',
                  color: isSelected(child.path) ? '#fff' : '#bdbdbd',
                  '&:hover': {
                    backgroundColor: '#3e3e4f',
                  },
                }}
              >
                <ListItemText
                  primary={child.label}
                  sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
                />
              </ListItemButton>
            </li>
          ))}
        </ul>
      )}

      {menuItem.children && !isCollapsed && (
        <ul className="child-menu" style={{
          transition: 'max-height 0.3s ease-out',
          overflow: 'hidden',
          maxHeight: openChildMenus[menuItem.id] ? '500px' : '0',
          paddingLeft: '20px',
        }}>
          {menuItem.children.map((child) => (
            <li key={child.id} style={{ listStyle: 'none', paddingLeft: 40 }}>
              <ListItemButton
                onClick={() => child.path && navigate(child.path)}
                sx={{
                  paddingLeft: '24px',
                  padding: '8px',
                  backgroundColor: isSelected(child.path) ? '#3e3e4f' : 'transparent',
                  color: isSelected(child.path) ? '#fff' : '#bdbdbd',
                  '&:hover': {
                    backgroundColor: '#3e3e4f',
                  },
                }}
              >
                <ListItemText
                  primary={child.label}
                  sx={{ '& .MuiTypography-root': { fontSize: '0.875rem' } }}
                />
              </ListItemButton>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
  return (
    <Box
      sx={{
        width: isCollapsed ? isMobile ? 0 : '60px' : '12rem',
        height: screenHeight ? screenHeight +1000 : 'auto',
        backgroundColor: '#2c2c3a',
        color: '#fff',
        transition: 'width 0.3s ease',
        position: 'relative',
      }}
    >
      {((!isMobile && isCollapsed) || (!isMobile && !isCollapsed) || (isMobile && !isCollapsed))  && <List component="ul">
        {menu.staticMenu.map((menuItem: MenuItem) => renderMenu(menuItem))}
      </List>}
    </Box>
  );
};

export default Sidebar;

// src/store/index.ts

import { createStore, combineReducers } from 'redux';
import rootReducer from './reducer';


// Define your root state type for TypeScript
export type RootState = ReturnType<typeof rootReducer>;

// Create the Redux store
const store = createStore(rootReducer);

export default store;

import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { Block } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const PermissionDenied: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100%"
      bgcolor={theme.palette.background.default}
      textAlign="center"
    >
      <Box
       className="ws-permission-denied"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 5,
          boxShadow: 3,
          width:'100%',
          bgcolor: theme.palette.background.paper,
          borderRadius: 3,
        }}
      >
        <Block sx={{ fontSize: 72, color: theme.palette.error.main, mb: 1 }} />
        <Typography variant="h4" color="text.primary" fontWeight="bold" gutterBottom>
          Access Denied
        </Typography>
        <Typography  className='ws-permission-denied text' color="text.secondary" maxWidth={320} mb={3}>
          You don't have permission to view this page. If you believe this is an error, please contact support.
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleGoBack}
          sx={{
            textTransform: 'none',
            borderRadius: 2,
            fontWeight: 'bold',
            px: 3,
            py: 1,
          }}
        >
          Go Back
        </Button>
      </Box>
    </Box>
  );
};

export default PermissionDenied;

import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { WSIcons } from '../../commonFunctions/iIcons';
import WSSelectField from '../Select/WSSelect';
import WSTextField, { TextType } from '../TextBox/WSTextBox';

export interface IMultiFieldProps {
    id: string;
    fields: Irows[];
    rows: Irows[][];
    setRows: React.Dispatch<React.SetStateAction<Irows[][]>>;
    setValue?: any
    control: any;
    unregister?: any,
    isMobile?: boolean
    // getMultiFieldData: () => any[]
}

export interface Irows {
    type: "TextField" | "SelectField" | "AutoComplete";
    name: string;
    label: string;
    rules?: any;
    maxLength?: number;
    minLength?: number;
    options?: any;
    disable?: boolean;
    control: any;
    register: any;
    errors?: any;
    reset?: any;
    setValue?: any;
    fieldtype?: TextType
    defaultValue?: any
}

const WSMultiField: React.FC<IMultiFieldProps> = ({ id, fields, rows, setRows, setValue, control, unregister, isMobile = false }) => {
    const addNewRow = () => {
        const newRow = fields.map((field, index) => ({
            ...field,
            defaultValue: ''
        }));

        setRows([...rows, newRow]);


    };
    useEffect(() => {
        rows.forEach((row, rowIndex) => {
            row.forEach((field) => {
                const fieldName = `${field.name}_${rowIndex}`;
                if (field.defaultValue !== undefined && field.defaultValue !== "") {
                    setValue(fieldName, field.defaultValue);
                }
            });
        });
    }, [rows, setValue]);
    const removeRow = (index: number) => {
        // Clear the values of the fields in the row being removed
        rows[index].forEach((field) => {
            const fieldName = `${field.name}_${index}`;
            setValue(fieldName, undefined);
            unregister(fieldName);
        });

        // Remove the row using splice
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    };

    const renderField = (field: Irows, rowIndex: number, fieldIndex: number) => {
        switch (field.type) {
            case "TextField":
                return (
                    <Grid item xs={8} sm={6} md={4} key={`${field.name}_${rowIndex}_${fieldIndex}`}>
                        <WSTextField
                            id={`${field.name}_${rowIndex}`}
                            {...field.register(`${field.name}_${rowIndex}`)}
                            type={field?.fieldtype}
                            label={field.label}
                            //   defaultValue={field.defaultValue}
                            maxLength={field?.maxLength}
                            minLength={field?.minLength}
                            control={control}
                            rules={field?.rules}
                            disabled={field?.disable}
                        />
                    </Grid>
                );
            case "SelectField":
                return (
                    <Grid item xs={8} sm={6} md={4} key={`${field.name}_${rowIndex}_${fieldIndex}`}>
                        <WSSelectField
                            id={`${field.name}_${rowIndex}`}
                            name={`${field.name}_${rowIndex}`}
                            {...field.register(`${field.name}_${rowIndex}`)}
                            label={field.label}
                            isMobile={isMobile}
                            options={field.options}
                            control={control}
                            //  defaultValue={field.defaultValue}
                            rules={field?.rules}
                            disabled={field?.disable}
                        />
                    </Grid>
                );
            case "AutoComplete":
                // Implement your AutoComplete field rendering here
                return null;
            default:
                return null;
        }
    };

    return (
        <>
            <Grid container spacing={1} justifyContent={'center'}>
                {rows.map((row, rowIndex) => (
                    <Grid item sm={9} xs={12} container spacing={1} key={rowIndex} style={{ position: 'relative', marginBottom: '16px', minWidth: isMobile ? undefined : '50rem', }}>
                        {row.map((field, fieldIndex) => renderField(field, rowIndex, fieldIndex))}
                        {rows.length > 1 && <IconButton
                            onClick={() => removeRow(rowIndex)}
                            style={{ position: 'relative', top: 0, right: 0 }}
                        >
                            <DeleteIcon />
                        </IconButton>}
                        {rowIndex === rows.length - 1 && (
                            <IconButton onClick={addNewRow} style={{ position: 'relative', marginRight: 10 }}>
                                <Tooltip title="Add">
                                    <WSIcons.AddIcon />
                                </Tooltip>
                            </IconButton>
                        )}
                    </Grid>
                ))}
                {/* <Grid item container  sm={9} flex={1} justifyContent={'flex-end'}>
                    <IconButton onClick={addNewRow}>
                        <Tooltip title="Add">
                            <WSIcons.AddIcon />
                        </Tooltip>
                    </IconButton>
                </Grid> */}
            </Grid>
        </>
    );
};

export default WSMultiField;

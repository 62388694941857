import { Box, Grid } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

// import { openModal } from "../../Dialog/OpenModal";
import { useNavigate } from "react-router-dom";
import { WSIcons } from "../../commonFunctions/iIcons";
import { WSTabs } from "../../Components/Tabs/WSTabs";
import ThreeDotMenu from "../../Components/ThreeDotMenu/ThreeDotMenu";
import { LeaveApprovedList } from "./LeaveApprovedList";
import { LeavePendingList } from "./LeavePendingList";
import { LeaveRejectedList } from "./LeaveRejectedList";
import { Header } from "../Header/Header";
interface FormData {
  departmentname: string;
  departmentcode: string;
  description: string;
}

const LeaveMain: React.FC<any> = React.memo(() => {
   const [value, setsValue] = React.useState(0);
  const navigate = useNavigate();

  const { control, reset, handleSubmit, register, formState: { errors } } = useForm<any>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setsValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }


  const handleSubmit1 = (values: any) => {
  };

  const tabsData: any[] = [
    { label: 'Pending', content: <LeavePendingList/> },
    { label: 'Approved', content: <LeaveApprovedList/> },
    { label: 'Rejected', content:<LeaveRejectedList/>},
  ];

  const handleAction = (action: string) => {
    if (action === 'Edit') {
    }
  }
  const actionsDummy = [{ label: <WSIcons.Edit style={{ fill: "black" }} />, onClick: (e: any) => { } }, { label: <ThreeDotMenu items={['Edit', 'Delete']} onAction={handleAction} align='vertical'></ThreeDotMenu>, onClick: (e: any) => { } }]

  return (
    <div>
       <Header title={'Leave Requested'} type="simple" url="/home" isBackButton={true} isDivider={true} />
      <Grid container spacing={2}>
        <WSTabs id="adasd" control={control} name="tabs" tabs={tabsData} />
    

      </Grid>

    </div>
  );
});

export default LeaveMain;

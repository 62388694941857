import { ChevronLeft, ExpandLess, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMenu } from '../../Config/menu';
import { getLocalValue, LoacalVariables } from '../../commonFunctions/CommonFunctions';

const drawerWidth = 240;

const StandardSidebar: React.FC = () => {
  const [open, setOpen] = React.useState<number | null>(null);
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const userType:any = getLocalValue(LoacalVariables.UserType)
  const handleClick = (id: number) => {
    setOpen(open === id ? null : id);
  };
  const menu=getMenu(userType)
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    setOpen(null); // Close all open items when collapsing
    setAnchorEl(null); // Close popover when collapsing
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>, item: any) => {
    if (collapsed && item.children) {
      setAnchorEl(event.currentTarget);
      setOpen(item.id);
    }
  };

  const handleMouseLeave = () => {
    if (collapsed) {
      setAnchorEl(null);
      setOpen(null);
    }
  };

  const renderMenuItems = () => {
    return menu.staticMenu.map((item: any) => (
      <React.Fragment key={item.id}>
        <ListItem
          button
          onClick={() => (!item.children ? navigate(item.path) : handleClick(item.id))}
          selected={location.pathname === item.path}
          onMouseEnter={(e) => handleMouseEnter(e, item)}
        // onMouseLeave={handleMouseLeave}
        >
          {item.icon && <ListItemIcon>{<item.icon sx={{'&.MuiSvgIcon-root':{
            height:25
          }}} />}</ListItemIcon>}
          {!collapsed && <ListItemText sx={{
            '& .MuiTypography-root': {
              fontSize: '0.875rem',
              marginLeft:-3
            }
          }} primary={item.label} />}
          {item.children && !collapsed ? (open === item.id ? <ExpandLess /> : <ExpandMore />) : null}
        </ListItem>
        {item.children && (
          <Collapse in={open === item.id && !collapsed} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.children.map((subItem: any) => (
                <ListItem
                  key={subItem.id}
                  button
                  onClick={() => navigate(subItem.path)}
                  selected={location.pathname === subItem.path}
                  sx={{
                    height: 30,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    paddingLeft: 10
                  }}
                >
                  {!collapsed && <ListItemText sx={{
            '& .MuiTypography-root': {
              fontSize: '0.875rem'
            }
          }}  primary={subItem.label} />}
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: collapsed ? 64 : drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: collapsed ? 64 : drawerWidth,
            boxSizing: 'border-box',
            transition: 'width 0.3s',

            marginTop: '64px', // Move the sidebar below the AppBar
            height: 'calc(100% - 64px)', // Adjust the height to account for AppBar
          },
        }}

      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            {!collapsed && <Typography variant="h6">HRMS</Typography>}
          </Box>
          <IconButton onClick={toggleCollapse}>
            {collapsed ? <MenuIcon /> : <ChevronLeft />}
          </IconButton>
        </Toolbar>
        <Divider />
        <List >{renderMenuItems()}</List>
      </Drawer>

      <Popover style={{ marginLeft: 40 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMouseLeave}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: 200, // Adjust the width as needed
            maxHeight: '400px', // Optional: add a max height to handle overflow
            overflowY: 'auto', // Optional: allow scrolling if content overflows
          },
        }}
      >
        <List style={{ marginLeft: 20 }}>
          {menu.staticMenu
            .find((item: any) => item.id === open)
            ?.children.map((subItem: any) => (
              <ListItem sx={{
                height: 30,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                // paddingLeft:10
              }}
                key={subItem.id}
                button
                onClick={() => {
                  navigate(subItem.path);
                  handleMouseLeave();
                }}
                selected={location.pathname === subItem.path}
              >
                <ListItemText sx={{
            '& .MuiTypography-root': {
              fontSize: '0.875rem'
            }
          }}   primary={subItem.label} />
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  );
};

export default StandardSidebar;

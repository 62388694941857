// src/HeaderConfig.ts
import React from "react";

// Define the type for header keys

export const enum WSHeaders {
    DepartMentList = 'header-department-list',
    WorkLocationList = 'header-work-location-list',
    DesignationList = 'header-designation-list',
    LeaveTypeList = 'header-leave-type-list',
    ScheduleMeetinglist = 'header-schedule-meeting-list',
    AnnouncementList = 'header-announcement-list',
    EmployeesList = 'header-employees-list',
    LeavePendingList = 'header-leave-pending-list',
    LeaveApprovedList = 'header-leave-approved-list',
    LeaveRejectedList = 'header-leave-rejected-list',
    UsersList = 'header-users-list',
    HolidayList = 'header-holiday-list',
}

import React, { useEffect, useState } from 'react';
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Table, TableCell, TableHead, TableRow, TableBody } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toTitleCase } from '../../commonFunctions/CommonFunctions';
import WSSCheckBoxField from '../CheckBox/WSCheckBox';
import _ from 'lodash'; // lodash for deep equality check
import { CRoles, IPermission, IRole, IRoleProps } from './RoleInterface';
import { v4 as uuidv4 } from 'uuid';


const WSRolesNew: React.FC<CRoles> = ({ control, id, name, roles, setValue, disabled, onSubmit, reset,isMobile=false }) => {
    const [roleState, setRoleState] = useState<IRoleProps[]>(roles || []);
    useEffect(() => {
        if (!_.isEqual(roles, roleState)) {
            setRoleState(roles || []);
        }
    }, [roles, roleState]);
   
    const handleCheckAll = (evt: any, permissions: IPermission[], key: string, roleIndex: number, pageIndex: number) => {
        const isChecked = evt?.target?.checked;
        const updatedRoles = [...roleState];
        updatedRoles[roleIndex].pages[pageIndex].permissions.forEach((permission) => {
            permission.permission = isChecked;
        });
        permissions?.forEach((item: IPermission) => {
            // Handle each permission object of type IPermission
         
            // Example of setting the permission to the checkbox state
            setValue(`chk-${item.label}-${key}`, isChecked);
        });


        (onSubmit(roles))
    }
    const handleCheckboxChange = (key: string, checked: boolean, keyMain: string, roleIndex: number,pageIndex: number,permissionIndex: number ) => {

        const updatedRoles = [...roleState];
        updatedRoles[roleIndex].pages[pageIndex].permissions[permissionIndex].permission = checked;
        if (checked === false) {
            setValue(keyMain, false);
        } 
        else
            if (checked === true && updatedRoles[roleIndex].pages[pageIndex].permissions.every((item: IPermission) => item.permission === true)) {
                setValue(keyMain, true);
            }
        // Update state and trigger onSubmit callback
        setRoleState(updatedRoles);
        onSubmit(updatedRoles);
        (onSubmit(roles))
    }
    function generateUID(): string {
        return uuidv4(); // Generates a unique string (UUID)
      }
      
    const makeRoles = (data: IRoleProps[]) => {
        return (
            <>{data?.length > 0 && <div >
                {data?.map((role: IRoleProps, index: number) => {
                    const roleKey = role?.type?.toLowerCase();
                    const rolePages = role.pages || [];
                    const operations = role?.operations || [];
                
                    return (
                        <Accordion key={index} defaultExpanded >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                sx={{
                                    backgroundColor: '#414A4C',
                                    borderTopLeftRadius: '5px',
                                    '& .MuiAccordionSummary-content': {
                                        margin: 0,
                                    },
                                    borderTopRightRadius: '5px',

                                    '& .MuiButtonBase-root': {
                                        minHeight: '10px !important',
                                    },
                                    '&.Mui-expanded': {
                                        minHeight: '10px !important',
                                        height: 50,
                                    },
                                }}
                            >
                                <Typography variant="subtitle1" sx={{ color: 'white', fontSize: '1rem' }}>
                                    {toTitleCase(roleKey)}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ marginLeft: 0 }} >
                                <Table >

                                    <TableHead>
                                        <TableCell style={{ width: '1px', marginLeft: 0 }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem', }}></Typography>

                                        </TableCell>
                                        <TableCell >
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                                                {'Page'}  {/* Displaying the actual operation name */}
                                            </Typography>
                                        </TableCell>
                                        {operations?.map((header: string, headerIndex: number) => (
                                            <TableCell key={headerIndex}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                                                    {toTitleCase(header)}  {/* Displaying the actual operation name */}
                                                </Typography>
                                            </TableCell>
                                        ))}

                                    </TableHead>
                                    <TableBody>
                                        {rolePages.map((page: any, pageIndex: number) => {
                                            const permissions: IPermission[] = page?.permissions || [];
                                            const key = page?.type?.toLowerCase();
                                            
                                            const defaultValue = permissions.every((item: IPermission) => item.permission === true);
                                         
                                            const guid=generateUID()
                                            return (
                                                <TableRow key={pageIndex}>
                                                    <TableCell>
                                                        <WSSCheckBoxField
                                                            control={control}
                                                            id={`chkall-${key}-${key}${guid}`}
                                                            defaultValue={defaultValue|| false}
                                                            name={`chkall-${key}-${key}${guid}`}
                                                        
                                                            disabled={disabled}
                                                            rules={{ required: false }}
                                                            onChange={(event) => handleCheckAll(event, permissions, key, index, pageIndex)}
                                                        />
                                                    </TableCell>
                                                    {/* Add additional TableCells here if needed */}
                                                    <TableCell>
                                                        {/* Example of rendering more data related to the page */}
                                                        <Typography style={{ fontSize: '0.875rem' }}>{toTitleCase(key)}</Typography>
                                                    </TableCell>

                                                    {permissions.map((permission: IPermission, permissionIndex: number) => (
                                                        <TableCell>
                                                            <WSSCheckBoxField
                                                                control={control}
                                                                id={`chk-${permission.label}-${key}`}
                                                                defaultValue={permission.permission || false}
                                                                name={`chk-${permission.label}-${key}`}
                                                                setValue={setValue}
                                                                disabled={disabled}
                                                                rules={{ required: false }}
                                                                onChange={(event) =>
                                                                    handleCheckboxChange(`chk-${permission.label}-${key}`, event.target.checked, `chkall-${key}-${key}${guid}`, index, pageIndex, permissionIndex)
                                                                }
                                                            />
                                                        </TableCell>
                                                    ))}

                                                    {/* Add other cells as necessary for other permissions or data */}
                                                </TableRow>
                                            );

                                        })}
                                    </TableBody>
                                </Table>

                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
            } </>);
    };

    return <>{makeRoles(roleState)}</>;
};

export default WSRolesNew;

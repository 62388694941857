import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GridOnIcon from '@mui/icons-material/GridOn';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import SettingsIcon from '@mui/icons-material/Settings';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AnnouncementIcon from '@mui/icons-material/Announcement';
export const WSIcons = {
  Edit: EditOutlinedIcon,
  Home: HomeIcon,
  Person: PersonIcon,
  TrendingUp: TrendingUpIcon,
  Annual: DashboardIcon,
  Masters: GridOnIcon,
  LogOut: LogoutIcon,
  AddIcon: AddIcon,
  UserGroup: GroupAddIcon,
  RolesIcon: RoomPreferencesIcon,
  ManageAccountIcon: ManageAccountsIcon,
  MedicalServicesIcon: MedicalServicesIcon,
  WithoutPay: SubtitlesOffIcon,
  ArrowRight: KeyboardArrowRightIcon,
  PersonOffIcon: PersonOffIcon,
  SettingsIcon:SettingsIcon,
  HolidayVillageIcon:HolidayVillageIcon,
  ScheduleIcon:ScheduleIcon,
  AnnouncementIcon:AnnouncementIcon,
};

import { Box, Button, Divider, Grid, Popover, Typography } from '@mui/material';
import React, { useState, MouseEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import WSTextField from '../TextBox/WSTextBox';
import WSSelectField from '../Select/WSSelect';
import WSDateField from '../DateField/WSDateField';
import WSMultiCheckBox from '../CheckBox/WSMultiCheckBox';
import { IOptions } from '../Lib/Lib';
import WSRadioField from '../Radio/WSRadio';
import { IsSmallScreen } from '../../commonFunctions/CommonFunctions';

interface IFilterProps {
    rows: IRows[];
    onFilterChange: (filters: any) => void;
    alignment?: 'left' | 'center' | 'right';
}

export interface IRows {
    type: string;
    label: string;
    fieldName: string;
    options?: IOptions[];
    range?: boolean;
    operator?: string;
    align?: 'horizontal' | 'vertical';
    rowDivision?: 6 | 12;
}

interface IFilter {
    field: string;
    operator: Operator | string;
    value: string | number;
}

export enum Operator {
    EqualTo = 'eq',
    NotEqualTo = 'neq',
    GreaterThan = 'gt',
    LessThan = 'lt',
    Like = 'like',
}

export const WSFilterComponent: React.FC<IFilterProps> = React.memo(({ rows, onFilterChange, alignment = 'left' }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const methods = useForm();
    const { register, handleSubmit, control, reset, formState: {} } = methods;
    const isMobile = IsSmallScreen();
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        reset();
    };

    const handleReset = () => {
        
        const emptyValues = rows.reduce((acc, row) => {
            acc[row.fieldName] = ''; // or [] for multi-select/checkbox fields
            return acc;
        }, {} as Record<string, any>);
    
        // Reset the form with empty values
        reset(emptyValues);
    
        // Clear filters
        onFilterChange([]);
        reset();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;

    const removeEmptyFilters = (filters: any) => {
        return Object.keys(filters).reduce((acc, key) => {
            const value = filters[key];
            if (value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0)) {
                acc[key] = value;
            }
            return acc;
        }, {} as any);
    };

    const onSubmit = (data: any) => {
        const cleanedFilters = removeEmptyFilters(data);
        const appliedFilters = rows.reduce((acc, column) => {
            if (cleanedFilters[column.fieldName]) {
                acc.push({
                    field: column.fieldName,
                    operator: column.operator || Operator.EqualTo,
                    value: cleanedFilters[column.fieldName],
                });
            }
            return acc;
        }, [] as IFilter[]);
        onFilterChange(appliedFilters);
       // handleClose();
       setAnchorEl(null);
    };

    return (
        <>
            <Box display="flex" justifyContent={alignment} sx={{ height: isMobile ? 33 : 32, marginTop: isMobile ? -2.2 : undefined }}>
                <Button aria-describedby={id} variant="contained" onClick={handleClick} style={{ fontSize: '0.75rem' }}>
                    Filter
                </Button>
            </Box>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{ maxWidth: 1000, marginTop: 5 }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                className="popover-main"
            >
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} className="popover-content">
                        <Grid container alignItems="center" style={{ padding: 12,marginLeft:10, backgroundColor: '#f5f5f5' }}>
                            <Typography  style={{ fontWeight: 'bold', color: '#333' ,fontSize:'1rem'}}>
                                Filter Options
                            </Typography>
                        </Grid>
                        <Divider  style={{ marginLeft:10, }} />

                        <Grid item style={{ margin: 10 }}>
                            <Grid container spacing={1}>
                                {rows.map(({ type, fieldName, label, options, align, rowDivision }) => (
                                    <Grid item sm={rowDivision ? rowDivision : 12} xs={12} key={fieldName}>
                                        {type === 'text' && (
                                            <WSTextField
                                                control={control}
                                                id={fieldName}
                                                {...register(fieldName)}
                                                name={fieldName}
                                                label={label}
                                            />
                                        )}
                                        {type === 'dropdown' && (
                                            <WSSelectField
                                                options={options}
                                                {...register(fieldName)}
                                                id={fieldName}
                                                control={control}
                                                isMobile={isMobile}
                                                label={label}
                                                name={fieldName}
                                            />
                                        )}
                                        {type === 'date' && (
                                            <WSDateField
                                                id={fieldName}
                                                name={fieldName}
                                                label={label}
                                                dateFormat="D/MM/YYYY"
                                                control={control}
                                                defaultValue={null}
                                                rules={{ required: false }}
                                            />
                                        )}
                                        {type === 'checkbox' && (
                                            <WSMultiCheckBox
                                                id={fieldName}
                                                label={label}
                                                name={fieldName}
                                                options={options || []}
                                                align={align}
                                                defaultValue={[]}
                                                rules={{ required: false }}
                                            />
                                        )}
                                        {type === 'radio' && (
                                            <WSRadioField
                                                id={fieldName}
                                                label={label}
                                                name={fieldName}
                                                options={options || []}
                                                defaultValue={[]}
                                                rules={{ required: false }}
                                                control={control}
                                            />
                                        )}
                                    </Grid>
                                ))}
                            </Grid>

                            <Box>
                                <br />
                                <Divider />
                            </Box>

                            <Grid container justifyContent="flex-end" spacing={1} style={{ paddingTop: 20 }}>
                                <Grid item sm={2} xs={6} style={{ marginRight: !isMobile ? 20 : undefined }} >
                                    <Button sx={{ height: 32, width: isMobile ? 20 : undefined }} type="submit" variant="contained" color="primary">
                                        Apply
                                    </Button>
                                </Grid>
                                <Grid item sm={2} xs={6} >
                                    <Button sx={{ height: 32, width: isMobile ? 20 : undefined }} type="button" onClick={handleReset} variant="contained" color="primary">
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Popover>
        </>
    );
});

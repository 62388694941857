import { Card, CardHeader, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import WSBackButton from "../../Components/BackButton/BackButton";
import { getComponentProps, unRegisterComponent, updateOrRegisterComponent } from "../../StateManagement/RegisterComponent";
import { WSHeaders } from "../../commonFunctions/HeaderConfig/HeaderConfig";

interface IHeaderProps {
    title: string;
    type?: 'simple' | 'card';
    children?: React.ReactNode;
    isBackButton?: boolean;
    url?: string;
    id?: WSHeaders | any;
    isDivider?: boolean
}

export const Header: React.FC<IHeaderProps> = React.memo((props) => {
    const [newUrl, setUrl] = useState<string | undefined>(props?.url);
    const [hasBackButton, setBackButton] = useState<boolean | undefined>(props?.isBackButton);

    // Register the component with initial props
    useEffect(() => {
        updateOrRegisterComponent(props?.id, props);
    }, []);
    useEffect(() => () => {
        unRegisterComponent(props?.id);
    }, []);
    // Handle updates from other components
    useEffect(() => {
        const propsed = getComponentProps(props?.id); // Retrieve the updated props
        if (propsed) {
            setUrl(propsed.url);
            setBackButton(propsed.isBackButton);
        }
    }, [props?.id]);

    return (
        <>
            {props?.type === 'card' && (
                <Paper style={{ backgroundColor: '#F8F8FF' }}>
                    <Card style={{
                        backgroundColor: '#ffffff',
                        borderRadius: '8px',
                        padding: '5px',
                        width: '100%',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #ffffff'
                    }}>
                        <CardHeader
                            title={
                                <Typography id={props?.id} variant="h6" style={{
                                    fontWeight: 'bold',
                                    color: '#333',
                                    fontSize: '1.25rem'
                                }}>
                                    {props?.title}
                                </Typography>
                            }
                            style={{ padding: '16px' }}
                        />
                    </Card>
                    {props?.children}
                </Paper>
            )}
            {props?.type === 'simple' && (
                <Grid item container spacing={2} >
                    <Grid item sm={12} className="header-text">
                        <Typography className="header-text" >
                            {hasBackButton && <WSBackButton id="btnScheduleBack" url={newUrl} />}
                            {props?.title}
                        </Typography>
                    </Grid>
                </Grid>

            )}
           {!props?.isDivider && <br/>}
            {props?.isDivider && <Grid item sm={12} xs={12} style={{ marginTop: 10 }}>
                <Divider />
                <br />
            </Grid>}
        </>
    );
});

// src/store/actions.ts

import { DATA_COMPONENT_RESET, DATA_COMPONENT_STORE,DATA_COMPONENT_UPDATE } from './types';

export const resetDataComponent = (componentId: string) => ({
  type: DATA_COMPONENT_RESET,
  payload: { componentId },
});

export const storeDataComponent = (componentId: string, data: any) => ({
  type: DATA_COMPONENT_STORE,
  payload: { componentId, data },
});
export const updateDataComponent = (componentId: string, data: any) => ({
  type: DATA_COMPONENT_UPDATE,
  payload: { componentId, data },
});

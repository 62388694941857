import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getLocalValue, IsSmallScreen, LoacalVariables } from "../../commonFunctions/CommonFunctions";
import WSDataGrid, { IColumns } from "../../Components/DataGrid/WSDataGrid";
import { Header } from "../Header/Header";
import WSMobViewDataGrid from "../../Components/DataGrid/WSMobViewDataGrid";

export const LeaveApprovalList: React.FC<any> = React.memo((props) => {
  const navigate = useNavigate()
  const UserId=getLocalValue(LoacalVariables.UserId);
  const source = {
    url: "LeaveApply/search",
    page: 1,
    pageSize: 10000,
    filter: [{"field":"EmployeeId","operator":"eq","value":UserId}],
    sort: [],
  };
  const columns: IColumns[] = [
    {
      header: "Leave Type",
      fieldName: "leaveName",
      tooltip: true,
      type:'default',
    },
    {
      header: "Status",
      fieldName: "statusCode",
      type:'status',
      
    },
    {
      header: "From",
      fieldName: "dateFrom",
      type: 'date'

    },
    {
      header: "To",
      fieldName: "dateTo",
      type: 'date'
    },
    {
      header: "Reason",
      fieldName: "reason",
      tooltip: true,
      type:'default',
    },
    // {
    //   header: "Actions",
    //   fieldName: "actions",
    //   type: "action",
    //   actions: ["View"],
    // },
  ];
  const transFormData = (data: any) => {
    if (data && data.length > 0) {
      data.map((item: any) => {
        item.leaveName = item?.leaveType?.name
      })

    }
    return data
  }
  const handleClick = () => {
    navigate('/leave/request')
  }

  return (<>
    <Header title="Leave" type="simple" isDivider={true} />
    <Grid container spacing={2}>
    {!IsSmallScreen() ?<Grid item xs={12}>
        <WSDataGrid
          id="leaveapprovalGrid"
          title="Leave Requested"
          columns={columns}
          source={source}
          addButton={{ label: ' ', color: 'secondary' }}
          onAddButtonClick={handleClick}
          transFormData={transFormData}
        />
      </Grid>:<Grid item xs={12}>
        <WSMobViewDataGrid
          id="leaveapprovalGrid"
          title="Leave Requested"
          columns={columns}
          source={source}
          addButton={{ label: ' ', color: 'secondary' }}
          onAddButtonClick={handleClick}
          transFormData={transFormData}
        />
      </Grid>}
    </Grid></>

  )
})
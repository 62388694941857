// httpClient.ts
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import config from '../Config/config.json'

const baseURL = config.apiBaseUrl; // Replace with your API base URL
const httpClient = axios.create({
  baseURL,
});

// Add a function to set the token in the headers
const setAuthToken = (token: string | null): void => {
  if (token) {
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete httpClient.defaults.headers.common['Authorization'];
  }
};

const get = async function <T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await httpClient.get<T>(url, config);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const post = async function <T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await httpClient.post(url, data, config);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const del = async function <T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await httpClient.delete(url, config);
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};
const put = async function <T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await httpClient.put<T>(url, data, config);
    return response.data;
  } catch (error) {
    console.error('Error putting data:', error);
    throw error;
  }
};

const http = {
  get,
  post,
  del,
  put,
  setAuthToken
};

export default http;

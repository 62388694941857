import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  callAPI,
  customValidation,
  getLocalValue,
  IsSmallScreen,
  LoacalVariables,
  Method,
  validations,
} from "../../commonFunctions/CommonFunctions";
import WSButton from "../../Components/Button/WSButton";
import WSDateField from "../../Components/DateField/WSDateField";
import WSHiddenField from "../../Components/HiddenField/WSHiddenField";
import WSSelectField from "../../Components/Select/WSSelect";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import WSTextField, { TextType } from "../../Components/TextBox/WSTextBox";
import WSTextAreaField from "../../Components/WSTextArea/WSTextArea";
import { Header } from "../Header/Header";


const LeaveRequestForm: React.FC<any> = React.memo((props) => {
  const { register, handleSubmit, control, setValue, unregister, formState: { errors, isSubmitSuccessful }, } = useForm();
  const { showMessage } = useSnackbar();
  const location = useLocation();
  const [indexCount, setIndexCount] = useState<any>(0)
  const leaveType = location.state?.leaveType || ''; // Get the passed type from state
  const [leaveFields, setLeaveFields] = useState<any[]>([]);
  const [holidaysList, setHolidayList] = useState<any>([])
  const isMobile=IsSmallScreen()
  useEffect(() => {
    async function getHolidays() {
      const response: any = await callAPI('NationalHoliday/all', Method.GET);
      if (response && response?.data) {
        setHolidayList(response?.data)
      }
    }
    getHolidays();
  }, [])
  useEffect(() => {
    if (leaveType) {
      setValue("leavetype", leaveType); // Pre-select the leave type if it's passed
    }
  }, [leaveType, setValue]);
  const source = {
    url: "leavetype/search",
    page: 1,
    pageSize: 10000,
    filter: [],
    sort: [],
  };
  const navigate = useNavigate()
  const sourceDepartment = {
    url: "department/search",
    page: 1,
    pageSize: 10000,
    filter: [],
    sort: [],
  };
  const [fromDate, setFromDate] = useState<string>()
  const [toDate, setTodate] = useState<string>()
  /**
   * converting data
   * @param data
   * @returns
   */
  const transFormData = (data: any) => {
    let newData: Array<any> = [];
    data.map((item: any) => {
      newData.push({ label: item.name, value: item.id });
    });
    return newData;
  };

  const transFormDataDepartment = (data: any) => {
    let newData: Array<any> = [];
    data.map((item: any) => {
      newData?.push({ label: item?.departmentName, value: item?.id });
    });
    return newData;
  };
  useEffect(() => {
    if (location?.state?.leaveId) {
      setValue('leavetype', location.state.leaveId);
    }
  }, [location?.state?.leaveId, source, transFormData]);

  useEffect(() => {



    if (fromDate && toDate) {
      unregisterLeaveFields()
      const fields = generateLeaveFields(fromDate, toDate); // Call helper function to generate fields
      setLeaveFields(fields); // Set the generated leave fields in state
      setIndexCount(fields.length);
    }
  }, [fromDate, toDate]);

  /**
   * submitting data
   * @param data
   */
  const handleData = async (data: any) => {
    try {
      const leaveCnt = getLeaveCountWithDate(data)
      const fromDate = dayjs(data.fromdate).format('YYYY-MM-DDTHH:mm:ss.SSS')
      const toDate = dayjs(data.todate).format('YYYY-MM-DDTHH:mm:ss.SSS')
      const userId = getLocalValue(LoacalVariables.UserId, false)
      const status = "false";
      let request = {
        leaveTypeId: data.leavetype,
        fromDate: fromDate,
        toDate: toDate,
        leaveTaken:leaveCnt?.count || 0,
        email: data.email,
        reason: data.reasonforleave,
        employeeId: userId,
        dateTime: toDate,
        departmentId: data.departmentid,
        status: status,
      };
      
      const response = await callAPI("LeaveApply/apply", Method.POST, request);
      if (response && (response?.isApproved === true || response?.success === true)) {
        showMessage(response?.message, 'success')
        navigate('/leave')
      }
      
      if (response && response?.isApproved === false) {
        // Call another API if the leave is rejected
        const additionalResponse = await callAPI(
          "LeaveApply",
          Method.POST,
          request
        );
        if (additionalResponse && additionalResponse?.message) {
          showMessage(additionalResponse.message, 'success')
          navigate('/leave')
        }
      }

    } catch (error: any) {

      showMessage(error?.message, 'error');
    }
  };
  const handleCancelClick = () => {
    navigate('/leave')
  }
  const fromDateChanged = (event: any) => {

    setFromDate(event)
  }
  const toDateChanged = (event: any) => {

    setTodate(event)
    

  }
  const unregisterLeaveFields = async () => {
    for (let i = 0; i < indexCount; i++) {
      const fieldName = `leaveTaken_${i}`;
      const fieldName2 = `dateHidden_${i}`;

      unregister(fieldName);
      unregister(fieldName2)

    }
    setIndexCount(0)
  };
  function getLeaveCountWithDate(data: any) {
    let leaveCount = 0;
    let leaveDate = []
    for (let i = 0; i < indexCount; i++) {
      const fieldName = `leaveTaken_${i}`;
      const fieldName2 = `dateHidden_${i}`;
      const dayCount = data[fieldName] === 'fh' || data[fieldName] === 'sh' ? 0.5 : data[fieldName] === 'hd' ? 0 : Number(data[fieldName])
      leaveCount += dayCount;
      
      if (dayjs(data[fieldName2]).day() !== 6 && dayjs(data[fieldName2]).day() !== 0) {
        leaveDate.push(data[fieldName2])
      }

    }
    return { count: leaveCount, leaveDate: leaveDate }
  }
  const generateLeaveFields = (start: string, end: string) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const fields = [];
    let index = 0;

    for (let date = startDate; date.isBefore(endDate) || date.isSame(endDate); date = date.add(1, 'day')) {
      const isWeekend = date.day() === 6 || date.day() === 0; // Check if it's a weekend
      const formattedCurrentDate = date.format('YYYY-MM-DD');
      const isHoliday = holidaysList.some((item: any) => dayjs(item.date).format('YYYY-MM-DD') === formattedCurrentDate);
      const holidayObj = holidaysList.find((item: any) => dayjs(item.date).format('YYYY-MM-DD') === formattedCurrentDate)
      fields.push(
        <React.Fragment key={index}>
          <Grid item sm={6}>
            <Typography style={{ fontSize: '0.875rem' }}>{date.format('ddd D-MMM -YYYY')}</Typography>
            <WSHiddenField
              name={`dateHidden_${index}`}
              control={control}
              defaultValue={date.format("YYYY-MM-DDTHH:mm:ss.SSS")}
              setValue={setValue}
            />
          </Grid>
          <Grid item sm={6}>
            <WSSelectField
              rules={{ required: false }}
              {...register(`leaveTaken_${index}`, { value: isWeekend ? '0' : isHoliday ? 'hd' : '1' })} // Register with a default value
              id={`leaveTaken_${index}`}
              control={control}
              label="Leave Type:"
              options={[
                { label: 'Full day', value: '1' },
                { label: 'First half', value: 'fh' },
                { label: 'Second half', value: 'sh' },
                { label: 'WeeKend', value: '0', disabled: true },
                isHoliday && { label: holidayObj?.name, value: 'hd', disabled: true }
              ]}
              disabled={isWeekend || isHoliday}
            />
          </Grid>
        </React.Fragment>
      );
      index++;
    }

    setIndexCount(index); // Update index count after generating fields
    return fields;
  };

  return (<>
    <Header title={'Leave Request'} type="simple" url="/leave" isBackButton={true} isDivider={true} />
       
            <Paper elevation={1} style={{ paddingLeft: 10, paddingRight: 0, marginTop: 0, paddingBottom: 20, border: '1px solid #ccc', borderRadius: '8px' }}>

      {/* <Grid item container spacing={1} >
        <Grid item sm={12}>
          <Typography className="header-text" >
            Leave Request Form
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Divider sx={{ marginY: 3 }} />

        </Grid>
      </Grid> */}
      <form onSubmit={handleSubmit(handleData)}>
      <Grid xs={12} className="modal-input" container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12}>

            <WSSelectField
              rules={{ required: true }}
              {...register("leavetype")}
              id="leavetype"
              isMobile={isMobile}
              control={control}
              label="Leave Type:"
              // labelType="Normal"
              name="leavetype"
              source={source}
              transFormData={transFormData}
            />

          </Grid>
          <Grid item xs={12} sm={6}>
            <WSDateField
              id="fromdate"
              name="fromdate"
              label="From Date *"
              dateFormat="YYYY/MM/D"
              control={control}
              rules={{ required: "Date is required" }}
              denyFuturOrPast="past"
              onChange={fromDateChanged}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WSDateField
              id="todate"
              name="todate"
              label="To Date *"
              dateFormat="YYYY/MM/D"
              defaultValue={fromDate}
              setValue={setValue}
              denyFuturOrPast="custom"
              onChange={toDateChanged}
              control={control}
              rules={{ required: "Date is required" }}
            />
          </Grid>
          {leaveFields && leaveFields.length > 0 && <Box
            sx={{
              maxHeight: '300px',
              overflowY: 'auto',
              width: '100%',
              padding: 2,  // Optional, to add some spacing
              border: '1px solid #e0e0e0',  // Optional, for visual reference
              marginTop: 2,
              marginLeft: 2
            }}
          >
            <Grid container spacing={2}>
              {leaveFields}
            </Grid>
          </Box>}

          <Grid item xs={12} sm={6}>
            <WSTextField
              control={control}
              id="email"
              {...register("email")}
              type={TextType.Text}
              rules={customValidation(true, validations.email)}
              name="email"
              label="Team Email ID "
            //  labelType="Normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <WSSelectField
              rules={{ required: true }}
              isMobile={isMobile}
              {...register("departmentid")}
              id="departmentid"
              control={control}
              label="Department"
              //  labelType="Normal"
              name="departmentid"
              source={sourceDepartment}
              transFormData={transFormDataDepartment}
            />
          </Grid>

          <Grid item xs={12}>
            <WSTextAreaField
              control={control}
              id="reasonforleave"
              name="reasonforleave"
              label="Enter reason "
              rules={{ required: true }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
          <Grid  item sx={{ marginRight: 1 }}>
            <WSButton id='submit' label={"Submit"} type="submit" size="medium" loading={isSubmitSuccessful} />
          </Grid>
          <Grid  item sx={{ marginRight: 2 }}>
            <WSButton id='cancel' label={"Cancel"} type="button" onClick={handleCancelClick} size="medium" className="ws-cancel" />
          </Grid>
        </Grid>
      </form>
    </Paper></>
  );
});
export default LeaveRequestForm;

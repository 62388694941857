// Utility function outside of PermissionManager
import { getLocalValue, IRoleMain, LoacalVariables, MasterPages } from "./CommonFunctions";

export interface IPermission {
    view?: boolean;
    add?: boolean;
    edit?: boolean;
    list?: boolean;
    delete?: boolean;
    exports?: boolean;
}



export function getPermissions(page: string | MasterPages, pageType: IRoleMain): IPermission {
    const userType: any = getLocalValue(LoacalVariables.UserType);
    if (userType !== 'admin') {
        return {
            view: true,
            add: true,
            edit: true,
            list: true,
            delete: true,
            exports: true
        };
    }

    let objPermissions: IPermission = {
        view: false,
        add: false,
        edit: false,
        list: false,
        delete: false,
        exports: false
    };

    const roles: any = getLocalValue(IRoleMain.Roles, true);
    const pageTypes: Array<any> = roles?.roles
        ?.filter((page: any) => page?.type?.toLowerCase() === pageType?.toLowerCase());

    const subPage: Array<any> = pageTypes && pageTypes[0]?.pages
        ?.filter((sub: any) => sub?.type?.toLowerCase() === page?.toLowerCase());

    const permissions: Array<any> = subPage && subPage.length > 0 && subPage[0].permissions;

    if (permissions && permissions.length > 0) {
        permissions.forEach((permission: any) => {
            const label = permission.label?.toLowerCase() as keyof IPermission;
            if (objPermissions.hasOwnProperty(label)) {
                objPermissions[label] = permission.permission;
            }
        });
    }

    return objPermissions;
}



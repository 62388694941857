// SnackbarContext.tsx
import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { WSSnackBar } from './WSSnackbar'; // Adjust the import according to your file structure

interface SnackbarContextProps {
  showMessage: (message: string, severity?: "error" | "warning" | "info" | "success") => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(undefined);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<"error" | "warning" | "info" | "success">("info");

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const showMessage = useCallback((message: string, severity: "error" | "warning" | "info" | "success" = 'info', autoClose: boolean = true) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
    if (autoClose === true) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
  }, []);

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <WSSnackBar
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={handleClose}
        severity={snackbarSeverity}
      />
    </SnackbarContext.Provider>
  );
};

import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';



interface IBackButton {
  url?: string;
  id: string; // Each back button will have a unique ID
}

const WSBackButton: React.FC<IBackButton> = ({ url: initialUrl, id }) => {
  const navigate = useNavigate();
  const [url, setUrl] = useState(initialUrl); // Local state for URL

  // useEffect(() => {
  //   // Register this component in the global registry with a setProps function
  //   registerComponent(id, {
  //     setProps: (newProps: any) => {
  //       if (newProps.url) {
  //         setUrl(newProps.url);
  //       }
  //     },
  //   });
  // }, [id]);

  const handleBack = () => {
    if (url) {
      navigate(url); // Navigate to the updated URL
    } else {
      navigate(-1); // Navigate to the previous page
    }
  };

  return (
    <IconButton
      color="primary"
      onClick={handleBack}
      id={id} // Assign the ID to the element
    >
      <ArrowBackIcon style={{ color: 'black' }} />
    </IconButton>
  );
};

export default WSBackButton;

import { Grid } from "@mui/material";
import React from "react";
import { getLocalValue, IRoleMain, LoacalVariables, MasterPages } from "../../commonFunctions/CommonFunctions";
import WSDataGrid from "../../Components/DataGrid/WSDataGrid";

import PermissionDenied from "../../commonFunctions/PermissionDenied";
import { getPermissions } from "../../commonFunctions/PermissionManger";
import { Header } from "../Header/Header";

import moment from 'moment';
import { useNavigate } from "react-router-dom";

export const HolidayList: React.FC<any> = React.memo(
    (props) => {
        const navigate = useNavigate();
        const source: any = {
            url: "NationalHoliday/all",
            page: 1,
            pageSize: 10,
            filter: [],
            sort: [],
            type: 'GET'
        };
        const { view, exports ,edit,add} = getPermissions(MasterPages.Holidays, IRoleMain.Masters)
        const userType: any = getLocalValue(LoacalVariables.UserType);
        /**
         * converting data
         * @param data 
         * @returns 
         */
        const transFormData = (data: any) => {
            const newList: Array<any> = []
            data.map((item: any) => {
                const month = moment.utc().month();
                const year = moment.utc().year();
                // month === moment(item.date).month() && 
               if (year === moment(item.date).year()) {
                    newList.push({ name: item?.name, date: item?.date ,id:item?.id,isPublicHoliday:item?.isPublicHoliday})
               }


            });
            return newList;
        };



        const columns: any[] = [
            {
                header: "Name",
                fieldName: "name",
                tooltip: true,
                sortable: true,
            },
            {
                header: "Date",
                fieldName: "date",
                type: 'date',
            },
            ...(edit && userType === 'admin' ? [{
                header: "Actions",
                fieldName: "actions",
                type: "action",
                actions: ["Edit"],
                actionCallback: (action:any, row:any) => handleAction(action, row),
            }] : []),
        ];
        

        /**
         * Edit function
         * @param action 
         * @param row 
         */
        const handleAction = (action: string, row: any) => {
            if (action === "Edit") {
                navigate(`/holiday/edit/${row?.id}`)
            }
        };

        const handleClick=()=>{
            navigate('/holiday/create')
        }
        return (
            <>
                <Header title="Holidays" type="simple" isDivider={true} />
                {(view || userType!=='admin') ?
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <WSDataGrid
                                // transFormData={transFormData}
                                id="holidayGrid"
                                title="Holidays"
                                buttonStyle="Normal"
                                columns={columns}
                                source={source}
                                exportCsv={exports}
                                transFormData={transFormData}
                                showpagination={(add && userType==='admin')}
                                addButton={(add && userType==='admin') ?{  }:undefined}
                                onAddButtonClick={handleClick}
                            />
                        </Grid>
                    </Grid>
                    : <PermissionDenied />}
            </>
        );
    }
);





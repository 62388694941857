import { Grid } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WSDataGrid, { IColumns } from "../../Components/DataGrid/WSDataGrid";
import WSMobViewDataGrid from "../../Components/DataGrid/WSMobViewDataGrid";
import { openPopUp } from "../../Components/Dialog/OpenNewModal";
import { IMetaData } from "../../Components/Dialog/WSCommonDialog";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import { resetDataComponent } from "../../StateManagement/actions";
import { callAPI, clearRequest, IRoleMain, IsSmallScreen, MasterPages, Method, } from "../../commonFunctions/CommonFunctions";
import PermissionDenied from "../../commonFunctions/PermissionDenied";
import { getPermissions } from "../../commonFunctions/PermissionManger";
import { Header } from "../Header/Header";
import AddDepartment from "./AddDepartment";
import { IRows } from "../../Components/Filter/WSFilterComponent";

export const AddDepartmentListingComponent: React.FC<any> = React.memo(
  (props) => {
    const { showMessage } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobileScreen = IsSmallScreen()
    const { view, exports, add, edit } = getPermissions(
      MasterPages.Departments,
      IRoleMain.Masters
    )

    const source = {
      url: "department/search",
      page: 1,
      pageSize: 10000,
      filter: [],
      sort: [],
    };

    /**
     *Transforms the input data by logging each item and returning the original data.
     * @param data
     * @returns
     */

    const handleClick = () => {
      openPopUp(metadata, handleData, isMobileScreen ? '100%' : "40rem");
    };

    /**
     * edit function
     * @param action
     * @param row
     */
    const handleAction = (action: string, row: any) => {
      if (action === "Edit" && !isMobileScreen) {
        const deptId: string = row.id;
        const metadataDepartment: IMetaData = {
          title: "Edit Department",
          hideActions: false,
          content: (props: any, row: any) => (
            <AddDepartment {...props} departmentId={deptId} />
          ),
          actions: [
            {
              label: "Cancel",
              className: "ws-cancel",
              type: "button",
            },
            {
              label: "submit",
              onClick: handleData,
              type: "submit",
              autoFocus: true,
            },
          ],
          isDragable: true,
        };
        openPopUp(metadataDepartment, handleData, "40rem");
      } else if (action === "Edit" && isMobileScreen) {
        navigate(`/departments/edit/${row?.id}`)
      }
    };
    /**
     * submitting data
     * @param data
     */
    const handleData = async (data: any) => {
      try {
        let request: any = {
          departmentName: data?.departmentName,
          departmentCode: data?.departmentCode,
          departmentHead: data?.departmentHead,
          reportTo: data?.reportTo,
          maxLeaveAllowed: data?.maxLeaveAllowed,
          description: data?.description,
        };

        let newRequest: any = clearRequest(request);

        if (data?.departmentId && data?.departmentId !== "") {
          const response = await callAPI(
            "department/" + data.departmentId,
            Method.PUT,
            newRequest
          );
          dispatch(resetDataComponent('departmentGrid'));
          showMessage(
            response?.message,
            response?.success === true ? "success" : "error"
          );
        } else {
          const response = await callAPI("department", Method.POST, newRequest);
          dispatch(resetDataComponent('departmentGrid'));
          showMessage(
            response?.message,
            response?.success === true ? "success" : "error"
          );
        }
      } catch (error) {
        console.error("Failed to create Department ", error);
      }
    };

    const metadata: IMetaData = {
      title: "Add Department",
      hideActions: false,
      content: (props: any, row: any) => (
        <AddDepartment {...props} departmentId={row?.id} />
      ),
      actions: [
        {
          label: "Cancel",
          className: "ws-cancel",
          type: "button",
        },
        {
          label: "submit",
          onClick: handleData,
          type: "submit",
          autoFocus: true,
        },
      ],
      isDragable: true,
    };

    const columns: IColumns[] = [
      {
        header: "Department Name",
        fieldName: "departmentName",
        tooltip: true,
        type: 'default',
      },
      {
        header: "Department Code",
        fieldName: "departmentCode",
        tooltip: true,
        type: 'default',
      },
      {
        header: "Actions",
        fieldName: "actions",
        type: "action",
        actions: [edit ? "Edit" : undefined],
        actionCallback: (action, row) => handleAction(action, row),
      },
    ];
    const handleMobClick = () => {
      navigate('/departments/add')
    }
    const filterRows: IRows[] = [
      {
        label: "Name",
        fieldName: "DepartmentName",
        type: "text",
        operator: 'like'
      },

    ];
    return (
      <>
        <Header id="header-department" title="Departments" type="simple" isDivider={true}/>
        {view ?
          <Grid container spacing={2}>
            {!IsSmallScreen() ? <Grid item xs={12}>
              <WSDataGrid
                id="departmentGrid"
                title="Departments"
                filterFields={filterRows}
                columns={columns}
                source={source}
                exportCsv={exports}
                addButton={add ? { label: "", color: "secondary" } : undefined}
                onAddButtonClick={handleClick}
              />
            </Grid> : <Grid item xs={12}>
              <WSMobViewDataGrid
                id="departmentGrid"
                title="Departments List"
                filterFields={filterRows}
                columns={columns}
                source={source}
                exportCsv={exports}
                addButton={add ? { label: "", color: "secondary" } : undefined}
                onAddButtonClick={handleMobClick}
              />
            </Grid>}
          </Grid>
          : <PermissionDenied />}
      </>
    );
  }
);

import React from 'react';
import { Chart } from "react-google-charts";
import { Card, CardContent, CardHeader, Typography, Grid, Divider } from '@mui/material';

interface CommonProps {
    data: any[];
    title: string;
    label?: string;
}

interface LineChartProps extends CommonProps {
    type: 'line';
    xData: any[];
}

interface PieChartProps extends CommonProps {
    type: 'pie';
    pieVisibility?: 'Straight Angle' | 'Center' | 'None';
    is3D?:boolean
}

interface AreaChartProps extends CommonProps {
    type: 'area';
    xData: any[];
}

type ChartProps = LineChartProps | PieChartProps | AreaChartProps;

const WSGoogleChart: React.FC<ChartProps> = (props) => {


    const size = {
        width: '100%',
        height: 300,
    };

    const renderChart = () => {
        const isEmptyData = props.data.length === 0;

        if (isEmptyData) {
            return (
                <Typography variant="h6" color="textSecondary" align="center" style={{ padding: '20px', paddingTop: 70, fontSize: '1.15rem', verticalAlign: 'center' }}>
                    No Data to Display
                </Typography>
            );
        }

        if (props.type === 'line') {
            const chartData = [
                ['x', 'Value'],
                ...props.xData.map((xVal, i) => [xVal, props.data[i]])
            ];

            return (
                <Chart
                    width={size.width}
                    height={size.height}
                    chartType="LineChart"
                    data={chartData}
                    options={{
                       // title: props.title,
                        hAxis: { title: 'X-axis' },
                        vAxis: { title: 'Y-axis' },
                    }}
                />
            );
        }

        if (props.type === 'pie') {
            const pieData = [
                ['Label', 'Value'],
                ...props.data
            ];

            return (
                <Chart
                    width={size.width}
                    height={size.height}
                    chartType="PieChart"
                    style={{paddingBottom:80}}
                    data={pieData}
                    options={{
                      //  title: props.title,
                      is3D:props?.is3D,
                        pieHole: getPieVisibility(props.pieVisibility),
                        pieSliceText: 'value',
                        sliceVisibilityThreshold: 0.05
                    
                    }}
                />
            );
        }

        if (props.type === 'area') {
            const chartData = [
                ['x', 'Value'],
                ...props.xData.map((xVal, i) => [xVal, props.data[i]])
            ];

            return (
                <Chart
                    width={size.width}
                    height={size.height}
                    chartType="AreaChart"
                    data={chartData}
                    options={{
                        title: props.title,
                        hAxis: { title: 'X-axis' },
                        vAxis: { title: 'Y-axis' },
                        isStacked: true,
                    }}
                />
            );
        }

        return null;
    };

    return (
        <Grid >
            <Card
                sx={{
                    transition: '0.3s',
                    width: '100%',
                    height: size.height,
                    minHeight: 300,
                    '&:hover': {
                        boxShadow: 3,
                    },
                }}
            >
                <CardHeader sx={{ '& .MuiTypography-root': { fontSize: '1rem',fontWeight:'bold' } }} title={props.title} />
                <Divider/>
                <CardContent>
                    {renderChart()}
                </CardContent>
            </Card>
        </Grid>
    );
};

function getPieVisibility(pieVisibility: 'Straight Angle' | 'Center' | 'None' | undefined) {
    switch (pieVisibility) {
        case 'Straight Angle':
            return 0.4; // Create a doughnut chart with a hole
        case 'Center':
            return 0.2; // Smaller hole
        case 'None':
        default:
            return 0; // Normal pie chart
    }
}

export default WSGoogleChart;

import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications'; // Import the notification icon
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Import the logout icon
import theme from '../../Config/themes.json';
import { useNavigate } from 'react-router-dom';

interface NavbarProps {
  toggleSidebar: () => void;
  toggleNotificationDrawer: () => any; // Add this prop to handle the notification drawer
  badgeContent:number
}

const Navbar: React.FC<NavbarProps> = ({ toggleSidebar, toggleNotificationDrawer,badgeContent }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/logout');
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#21212c' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleSidebar}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {theme.systemSettings.name || 'ADMIN'}
        </Typography>

        {/* Notification icon with a badge */}
        <IconButton
          color="inherit"
          aria-label="notifications"
          onClick={toggleNotificationDrawer} // Handle notification drawer toggle
        >
          <Badge badgeContent={badgeContent} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>

        {/* Logout icon */}
        <IconButton
          color="inherit"
          aria-label="logout"
          onClick={handleLogout}
          sx={{ ml: 2 }}
        >
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

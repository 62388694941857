// src/store/reducers.ts

import { AppState, AppActionTypes, DATA_COMPONENT_RESET, DATA_COMPONENT_STORE, DATA_COMPONENT_UPDATE } from './types';

const initialState: AppState = {
  components: {},
};

const rootReducer = (state = initialState, action: AppActionTypes): AppState => {
  switch (action.type) {
    case DATA_COMPONENT_RESET:
      return {
        ...state,
        components: {
          ...state.components,
          [action.payload.componentId]: {
            data: null,
            isLoading: false,
            error: null,
          },
        },
      };
    case DATA_COMPONENT_STORE:
      return {
        ...state,
        components: {
          ...state.components,
          [action.payload.componentId]: {
            ...state.components[action.payload.componentId],
            data: action.payload.data,
            isLoading: false,
            error: null,
          },
        },
      };
      case DATA_COMPONENT_UPDATE:
      return {
        ...state,
        components: {
          ...state.components,
          [action.payload.componentId]: {
            ...state.components[action.payload.componentId],
            data: {
              ...state.components[action.payload.componentId]?.data,
              ...action.payload.data,
            },
          },
        },
      };
    
    default:
      return state;
  }
};

export default rootReducer;

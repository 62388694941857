/**
 * Component   : ScheduleMeeting
 * Description : Schedule a Meeting
 */
import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import moment from "moment";
import { momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import WSBackButton from "../../../Components/BackButton/BackButton";
import { useSnackbar } from "../../../Components/Snackbar/SnackBarContext";
import WSBigCalendar from "../../../Components/WSBigCalander/WSBigCalander";
import { callAPI, clearRequest, Method } from "../../../commonFunctions/CommonFunctions";
import { IDateBigger, IScheduleMeeting } from "../../Interfaces/Interfaces";
export const ScheduleMeeting: React.FC<any> = React.memo((props) => {
    const { showMessage } = useSnackbar();
    const { register, handleSubmit, control, formState: { }, setValue, watch, reset } = useForm();
    const [events, setEvents] = useState<IDateBigger[]>([]);
    //alert(new Date())
    const navigate = useNavigate();
    const params = useParams();
    const id = params?.id;
    useEffect(() => () => {
        reset()
    }, [])
    const localizer = momentLocalizer(moment);



    useEffect(() => {
        /**
         * Method to get the user record by id for edit purpose
         * @param id 
         */
        async function getMeetingById(id: any): Promise<void> {
            try {
                const response: any = await callAPI('meeting', Method.GET, {}, id);

                if (response && response.data) {

                    for (const field in response.data) {

                        setValue(field, (response?.data as any)[field]);

                    }
                    const timeValue: any = {
                        title: response.data?.title,
                        start: new Date(response.data?.meetingDate),
                        end: new Date(response.data?.meetingDate),//.setHours(new Date().getHours() + 1),
                        description: response.data?.title,
                        _id: response.data?.id,
                    }
                    setEvents([timeValue])
                }
            } catch (error) {
                showMessage('Error fetching Meeting:', 'error');
            }
        }
        getMeetingById(id);
    }, [id]);

    const handleCancelClick = () => {
        navigate("/schedule/list");
    };

    /**
     * Method for Add/Update the data
     * @param data 
     */
    const onSubmit = async (data: any) => {
        try {

            const request: any = await setRequest(data);
            if (id) {
                const response: any = await callAPI(`meeting/${id}`, Method.PUT, request);
                if (response) {
                    showMessage(response?.message, response.success === true ? "success" : "error");
                    response.success === true && navigate("/schedule/list");
                }
            } else {
                const response: any = await callAPI('meeting', Method.POST, request);
                if (response) {
                    showMessage(response?.message, response.success === true ? "success" : "error");
                    response.success === true && navigate("/schedule/list");
                }
            }
        } catch (e: any) {
            showMessage(e.error);
        }
    };

    /**
     * Method to set the request for the user
     * @param data 
     * @returns 
     */
    async function setRequestCalander(data: any) {
        let request: any = {
            title: data?.title,
            meetingDate: dayjs(data?.start).format("YYYY-MM-DDTHH:mm:ss.SSS"),
            meetingTime: dayjs(data?.start).format('HH:mm')

        };
        return clearRequest(request);
    }
    /**
     * Method to set the request for the user
     * @param data 
     * @returns 
     */
    async function setRequest(data: IScheduleMeeting) {

        let request: IScheduleMeeting = {
            title: data?.title,
            meetingDate: dayjs(data?.meetingDate).format("YYYY-MM-DDTHH:mm:ss.SSS"),
            meetingTime: data?.meetingTime,

        };
        return clearRequest(request);
    }
    const handleEventSelect = (event: any) => {
    };

    const handleSlotSelect = (slotInfo: any) => {
    };

    const handleEventSave = async (newEvent: any) => {
        // Add or update the event in the events state

        try {

            const req = await setRequestCalander(newEvent)
            if (id) {
                const response: any = await callAPI(`meeting/${id}`, Method.PUT, req);
                if (response) {
                    showMessage(response?.message, response.success === true ? "success" : "error");
                    response.success === true && navigate("/schedule/list");
                }
            } else {
                const response: any = await callAPI('meeting', Method.POST, req);
                if (response) {
                    showMessage(response?.message, response.success === true ? "success" : "error");
                    response.success === true && navigate("/schedule/list");
                }
            }
        } catch (e: any) {
            showMessage(e.error);
        }

        setEvents((prevEvents) => {
            if (newEvent._id) {
                // Editing an existing event
                return prevEvents.map((event) =>
                    event._id === newEvent._id ? newEvent : event
                );
            }
            // Adding a new event
            return [...prevEvents, { ...newEvent, _id: prevEvents.length + 1 }];
        });


    };
    return (
        <>
            {/* <Paper elevation={3} style={{ padding: '25px', margin: '25px', border: '1px solid #ccc', borderRadius: '8px' }}> */}
                <Grid item container spacing={1}>
                    <Grid item sm={12} xs={12}>

                        <Typography className="header-text" >
                            <WSBackButton id="btnScheduleBack" url="/schedule/list" />  {id ? "Edit Meeting" : "Schedule a Meeting"}
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Divider />
                        <br />
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item sm={12}  xs={12}>
                        {!id && <WSBigCalendar
                            events={events}
                            id="scheduleMeeting"
                            title=""
                           
                            onSelectEvent={handleEventSelect}
                            onSelectSlot={handleSlotSelect}
                            onEventSave={handleEventSave} // Pass save event handler
                        />}
                        {id && events && events.length > 0 && <WSBigCalendar
                            events={events}
                            id="scheduleMeeting"
                            title=""
                            defaultValue={new Date(events[0]?.start)}
                            onSelectEvent={handleEventSelect}
                            onSelectSlot={handleSlotSelect}
                            onEventSave={handleEventSave} // Pass save event handler
                        />}
                    </Grid>
                </Grid>
                {/* <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid item container spacing={2}>
                       
                        <Grid item sm={12}>
                            <WSTextField
                                control={control}
                                id="title"
                                {...register("title")}
                                type={TextType.Text}
                                rules={{ required: true }}
                                name="title"
                                label="Title"
                            />

                        </Grid>
                        <Grid item sm={6}>
                            <WSDateField
                                id="meetingDate"
                                name="meetingDate"
                                label="Date *"
                                dateFormat="YYYY/MM/D"
                                control={control}
                                rules={{ required: "Date is required" }}

                            />
                        </Grid>
                        <Grid item sm={6}>
                            <WSTimePicker control={control} id="meetingTime" {...register('meetingTime')} name="meetingTime" rules={{ required: true }} label="Time" />
                        </Grid>

                    </Grid>

                    <Grid item container spacing={2} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                        <Grid item>
                            <WSButton id='submit' label={"Save"} type="submit" size="medium" />
                        </Grid>
                        <Grid item>
                            <WSButton id='cancel' label={"Cancel"} type="button" onClick={handleCancelClick} size="medium" className="ws-cancel" />
                        </Grid>
                    </Grid>
                </form> */}
            {/* </Paper> */}
        </>
    );
});

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import IfscSearch from "../../Components/IFSC/Ifsc";
import WSRadioField from "../../Components/Radio/WSRadio";
import WSTextField from "../../Components/TextBox/WSTextBox";
import config from '../../Config/clientConfig.json';
import { callAPI, EmployeeStorageVariable, getEmployeeLocalValue, localStorageVariable, Method } from "../../commonFunctions/CommonFunctions";
import { IEmployeeBankInfo } from "../Interfaces/Interfaces";
interface IBankIInfo {
  control: any;
  register: any;
  errors: any;
  reset?: any;
  watch: any;
  getValues: any, setValue: any, trigger: any
}

const BankInfo: React.FC<IBankIInfo> = React.memo((props) => {
  const { control, register, reset, setValue, watch, getValues, trigger } = props;
  const [bankId, setBankId] = useState(
    getEmployeeLocalValue(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.bankInfoId) || null
  );
  useEffect(() => () => {
    reset()
  }, [reset])
  useEffect(() => {
    /**
  * Method to get the data for editing pupose
  */
    try {


      async function getData() {
        let response = await callAPI(`employee/empbankinfo`, Method.GET, {}, bankId);
        const empbasicinfo: IEmployeeBankInfo = response?.data;
        if (setValue && empbasicinfo) {
          setValue("id", empbasicinfo.id);
          setValue("accountName", empbasicinfo.accountName);
          setValue("accountNumber", empbasicinfo?.accountNumber);
          setValue("reenterAccountNumber", empbasicinfo?.accountNumber);
          setValue("bankName", empbasicinfo.bankName);
          setValue("bankType", empbasicinfo.bankType);
          setValue("ifsc", empbasicinfo.ifsc);

        }
      }
      if (bankId) {
        getData();
      }
    }
    catch (e) {

    }
   
  }, [bankId, setValue]);

  return (
    <Grid container spacing={3}>
      {/* <Grid item sm={12} justifyContent={'center'}>
        <CircularProgress/>
      </Grid> */}

      <Grid item xs={12}>
        <WSTextField
          control={control}
          id="accountName"
          {...register("accountName", { required: true })}
          name="accountName"
          label="Account Holder Name * "
        // labelType="Normal"
        />
      </Grid>
      <Grid item xs={12}>
        <WSTextField
          control={control}
          id="bankName"
          {...register("bankName", { required: true })}
          name="bankName"
          label="Bank Name* "
        // labelType="Normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WSTextField
          control={control}
          id="accountNumber"
          {...register("accountNumber", { required: true })}
          name="accountNumber"
          label="Account Number* "
        //  labelType="Normal"
        // type={TextType.Number}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WSTextField
          control={control}
          id="reenterAccountNumber"
          {...register("reenterAccountNumber", {
            required: true,
            validate: (value: any) => {
              const accountNumber = watch("accountNumber");
              return value === accountNumber || "Account numbers do not match";
            },
          })}
          name="reenterAccountNumber"
          label="Re-enter Account Number* "
        //labelType="Normal"
        // type={TextType.Number}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <IfscSearch
          id="ifsc"
          label="IFSC Code *"
          variant="outlined"
          adornment="IFSC"
          control={control}
          name="ifsc"
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          rules={{
            required: true,
            minLength: { value: 4, message: 'Minimum 4 characters required' },
          }}
          showBankDetails={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WSRadioField
          id="bankType"
          name="bankType"
          label="Account Type *"
          control={control}
          defaultValue={'current'}
          options={config.accountTypeConfig.staticOptionData}
          rules={{ required: true }}
        />
      </Grid>
      {/* <CircularProgress /> */}
    </Grid>

  );
});

export default BankInfo;

import { Alert, Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI, clearRequest, IsSmallScreen, Method } from "../../commonFunctions/CommonFunctions";
import WSButton from "../../Components/Button/WSButton";
import WSSelectField from "../../Components/Select/WSSelect";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import WSTextField, { TextType } from "../../Components/TextBox/WSTextBox";
import WSTextAreaField from "../../Components/WSTextArea/WSTextArea";
import { Header } from "../Header/Header";

const MobDepartment: React.FC<any> = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue, watch, reset } = useForm();
    const params = useParams();
    const id = params?.id;
    const navigate = useNavigate();
    useEffect(() => () => {
        reset()
    }, [id, reset])
    const [selectedState, setSelectedState] = useState<string>("");
    const { showMessage } = useSnackbar();
    const isMobileScreen = IsSmallScreen()
    useEffect(() => {
        reset();
    }, [id, reset]);

    useEffect(() => {
        /**
         * edit function
         * @param id
         */
        try {
            async function getDepartment(id: any) {
                try {
                    const response: any = await callAPI("department", Method.GET, {}, id);
                    setValue("departmentName", response.data.departmentName);
                    setValue("departmentCode", response.data.departmentCode);
                    setValue("departmentHead", response.data.departmentHead);
                    setValue("reportTo", response.data.reportTo);
                    setValue("maxLeaveAllowed", response.data.maxLeaveAllowed);
                    setValue("description", response.data.description);
                    setValue("departmentId", response.data.id);


                } catch (error) {
                    console.log("failed to retrieve data", error);
                }
            }
            if (id) {
                getDepartment(id);
            }
        }
        catch (e) {
        }
    }, [id]);
    const employeeSource: any = { url: 'employee/empbasicinfo/search', "page": 1, "pageSize": 10000, "filter": [], "sort": [] }
    const transFormData = ((data: any) => {
        let empList: Array<any> = []
        data && data?.map((item: any) => {
            empList.push({ label: `${item?.firstName} ${item?.middleName ? item?.middleName : ''} ${item?.lastName} `, value: item.id })
        })
        return empList
    })
    const onSubmit = async (data: any) => {
        try {
            let request: any = {
                departmentName: data?.departmentName,
                departmentCode: data?.departmentCode,
                departmentHead: data?.departmentHead,
                reportTo: data?.reportTo,
                maxLeaveAllowed: data?.maxLeaveAllowed,
                description: data?.description,
            };

            let newRequest: any = clearRequest(request);

            if (data?.departmentId && data?.departmentId !== "") {
                const response = await callAPI(
                    "department/" + data.departmentId,
                    Method.PUT,
                    newRequest
                );

                showMessage(
                    response?.message,
                    response?.success === true ? "success" : "error"
                );
                navigate('/departments')
            } else {
                const response = await callAPI("department", Method.POST, newRequest);

                showMessage(
                    response?.message,
                    response?.success === true ? "success" : "error"
                );
            }
            navigate('/departments')
        } catch (error) {
            console.error("Failed to create Department ", error);
        }

    };
    const handleCancelClick = () => {
        navigate('/departments')
    }
    return (
        // <Box sx={{ padding: "20px", position: "relative", margin: "auto", marginTop: "50px" }}>
        <> <Header title={id?'Edit Department':'Add Department'} type="simple" url="/designations" isBackButton={true} isDivider={true} />
       
            <Paper elevation={1} style={{ paddingLeft: 10, paddingRight: 10, marginTop: 0, paddingBottom: 20, border: '1px solid #ccc', borderRadius: '8px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid xs={12} className="modal-input" container spacing={2} style={{ marginTop: 10 }}>

                        <Grid item xs={12} sm={6}>
                            <WSTextField
                                control={control}
                                id="departmentName"
                                {...register("departmentName")}
                                rules={{ required: true }}
                                name="departmentName"
                                label="Department Name *"
                            //labelType="Normal"
                            />
                            {errors.departmentname && (
                                <Typography variant="body2" color="error">
                                    Department Name is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <WSTextField
                                control={control}
                                id="departmentCode"
                                {...register("departmentCode")}
                                rules={{ required: true }}
                                name="departmentCode"
                                label="Department Code *"
                            //labelType="Normal"
                            />
                            {errors.departmentcode && (
                                <Typography variant="body2" color="error">
                                    Department Code is required
                                </Typography>
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <WSSelectField
                                // options={config.departmentconfig.staticOptionData}
                                source={employeeSource}
                                transFormData={transFormData}
                                rules={{ required: true }}
                                {...register("departmentHead")}
                                id="departmentHead"
                                control={control}
                                label="Department Head"
                                name="departmentHead"
                                isMobile={isMobileScreen}
                            //labelType="Normal"
                            ></WSSelectField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <WSSelectField
                                // options={config.reportconfig.staticOptionData}
                                source={employeeSource}
                                rules={{ required: true }}
                                {...register("reportTo")}
                                id="reportTo"
                                control={control}
                                label="Report To"
                                name="reportTo"
                                transFormData={transFormData}
                                isMobile={isMobileScreen}
                            //labelType="Normal"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <WSTextField
                                control={control}
                                id="maxLeaveAllowed"
                                {...register("maxLeaveAllowed")}
                                rules={{ required: true }}
                                name="maxLeaveAllowed"
                                label="Max Leave Allowed *"

                                //labelType="Normal"
                                type={TextType.Number}
                            />
                            {errors.departmentcode && (
                                <Typography variant="body2" color="error">
                                    Max Leave is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <WSTextAreaField
                                label="Description"
                                id="description"
                                control={control}
                                {...register("description")}
                                rules={{ required: true }}
                                name="description"
                            />
                        </Grid>
                        <Grid item xs={12} style={{ display: "none" }}>
                            <WSTextField
                                control={control}
                                id="departmentId"
                                {...register("departmentId")}
                                rules={{ required: false }}
                                name="departmentId"
                                label="departmentId"
                                //labelType="Normal"
                                type={TextType.Number}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                        <Grid item sx={{ marginRight: 1 }} >
                            <WSButton id='submit' label={"Save"} type="submit" size="small" />
                        </Grid>
                        <Grid item sx={{ marginRight: 2 }}>
                            <WSButton id='cancel' label={"Cancel"} type="button" onClick={handleCancelClick} size="small" className="ws-cancel" />
                        </Grid>
                    </Grid>
                </form>
                {selectedState === "Kerala" && (
                    <Alert severity="info" sx={{ mt: 2 }}>
                        Labour Welfare Fund is applicable for Kerala. If you've not configured it yet, configure it in Settings &gt; Statutory Components.
                    </Alert>
                )}
            </Paper >
        </>

        // </Box>
    )
}
export default MobDepartment;
import {
    Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, Fab, Grid, IconButton, LinearProgress, Link, Paper, Skeleton, Table, TableBody, TableCell, TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
    createSvgIcon
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import http from '../../commonFunctions/httpClient';
import ThreeDotMenu from '../ThreeDotMenu/ThreeDotMenu';
import { formatAddressObject, toTitleCase, toUpperCase } from '../../commonFunctions/CommonFunctions';
import { WSIcons } from '../../commonFunctions/iIcons';
import { IButton } from '../../Pages/Interfaces/Interfaces';
import { storeDataComponent } from '../../StateManagement/actions';
import store from '../../StateManagement/store';
import { WSFilterComponent } from '../Filter/WSFilterComponent';
import { WSStatus } from '../Status/WSStatus';
import { WSView } from '../View/WSView';
import dayjs from 'dayjs'; // Import dayjs for date formatting
import WSButton from '../Button/WSButton';
interface MobDataGridProps {
    id: string;
    title?: string;
    columns: IColumns[];
    multiSelect?: boolean;
    onSelectionChange?: (selectedRows: any[]) => void;
    source?: {
        url: string;
        pageSize: number;
        page: number;
        filter: any[];
        sort: any[];
        type?: 'POST' | 'GET'
    };
    filters?: any;
    filterFields?: any
    exportCsv?: boolean,
    addButton?: IButton;
    transFormData?: (data: any) => any;
    onAddButtonClick?: () => void;
    buttonStyle?: 'Normal' | 'Fab',
    showpagination?: boolean
}
export interface IColumns {
    header: string;
    fieldName: string;
    type?: 'default' | 'link' | { image: { suffix1?: string; suffix2?: string; } } | 'action' | 'status' | 'date';
    tooltip?: boolean;
    actions?: any;
    defaultSort?: string;
    actionCallback?: (action: string, row: any) => void;
    sortable?: boolean; // Indicate if column is sortable
    sortKey?: string
    isTwoPerRow?: boolean
}

const WSMobViewDataGrid: React.FC<MobDataGridProps> = ({
    id,
    columns,
    multiSelect,
    onSelectionChange,
    source,
    filters,
    filterFields,
    exportCsv = false,
    transFormData,
    title,
    addButton,
    onAddButtonClick,
    buttonStyle = "Normal",
    showpagination = true
}) => {
    const [page, setPage] = useState(0);
    const [_rows, setRows] = useState<any[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [exporting, setExporting] = useState<boolean>(false);
    const [showImagePopup, setShowImagePopup] = useState<boolean>(false);
    const [popupImageURL, setPopupImageURL] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const gridContainerRef = useRef<HTMLDivElement>(null);
    const [gridHeight, setGridHeight] = useState<any>()
    const [newFilters, setFilters] = useState(filters);
    const [screenHeight, setScreenHeight] = useState(window.top);
    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        'SaveAlt',
    );
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' }>({
        key: '',
        direction: 'desc',
    });

    const dispatch = useDispatch();
    const handleImageClick = (imageUrl: string) => {
        setPopupImageURL(imageUrl);
        setShowImagePopup(true);
    };

    const fetchDataFromAPI = async (
        page: number,
        pageSize: number,
        filter: any[] = [],
        sort: any[] = []
    ) => {
        try {
            setLoading(true);
            if (source?.type === 'GET') {
                const response: any = await http.get(source.url);
                return response;
            }
            if (source) {
                const response: any = await http.post(source.url, {
                    page: page,
                    pageSize: pageSize,
                    filter: filter,
                    sort: source.sort.length > 0 ? source.sort : sort,
                });
                return response;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setLoading(false);  // End loading
        }
    };

    const loadPageData = useCallback(async () => {
        try {
            const defaultSortKey = columns?.find((column: any) => column?.defaultSort);
            const sortKey: any = sortConfig.key ? sortConfig.key : defaultSortKey ? defaultSortKey.defaultSort : ''
            const sort = [{ 'field': sortKey, direction: sortConfig.direction }];
            let filter: any = []
            if (source && source?.filter?.length > 0) {
                filter = [...source?.filter]
                if (newFilters) {
                    filter = [...newFilters]
                }
            }
            let response = await fetchDataFromAPI(page + 1, rowsPerPage, filter && filter?.length > 0 ? filter : newFilters, sortKey !== '' ? sort : []);
            //if (response && response.data && _rows && _rows.length > 0 && _rows[0].id !== response.data[0].id) {
            dispatch(storeDataComponent(id, response?.data || []));
            //}
            if (transFormData) {
                setRows(transFormData(response?.data || []));
            } else {
                setRows(response?.data || []);
            }
            setTotalCount(response?.pagination?.totalCount | 0);
        } catch (error) {
            console.error('Error loading page data:', error);
        }
    }, [page, rowsPerPage, source, id, sortConfig, newFilters, transFormData]);

    useEffect(() => {


        loadPageData();

        if (gridContainerRef.current) {
            setGridHeight(gridContainerRef.current.clientHeight);

        }
    }, [page, rowsPerPage, sortConfig, newFilters]);
    useEffect(() => {

        const unsubscribe = store.subscribe(() => {
            const state = store.getState();
            if (state.components[id]?.data === null) {
                loadPageData();
            }
        });

        return () => {
            unsubscribe();
        };
    }, [id, loadPageData]);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page to 0 when rows per page changes
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedRows = _rows.map((_, index) => page * rowsPerPage + index);
            setSelectedRows(newSelectedRows);
            if (onSelectionChange) {
                onSelectionChange(newSelectedRows.map((idx) => _rows[idx % rowsPerPage]));
            }
            return;
        }
        setSelectedRows([]);
        if (onSelectionChange) {
            onSelectionChange([]);
        }
    };

    const handleClick = (index: number) => {
        const selectedIndex = selectedRows.indexOf(index);
        let newSelectedRows: number[] = [];

        if (selectedIndex === -1) {
            newSelectedRows = newSelectedRows.concat(selectedRows, index);
        } else if (selectedIndex === 0) {
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedRows = newSelectedRows.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }
        setSelectedRows(newSelectedRows);

        if (onSelectionChange) {
            onSelectionChange(newSelectedRows.map((idx) => _rows[idx % rowsPerPage]));
        }
    };

    const isSelected = (index: number) => selectedRows.indexOf(index) !== -1;
    const handleRequestSort = (property: string) => {
        const isAsc = sortConfig.key?.toLowerCase() === property && sortConfig.direction === 'asc';
        setSortConfig({ key: property, direction: isAsc ? 'desc' : 'asc' });
        setPage(0);
    };

    const sortedRows = () => {
        if (sortConfig.key) {
            const comparator = (a: any, b: any) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            };
            return [..._rows].sort(comparator);
        }
        return _rows;
    };

    const isArrayOrObject = (value: any) => {
        return value && typeof value === 'object';
    };

    const handleFilterChange = (newFilters: any) => {
        setFilters(newFilters);
    };
    const handleAddButton = () => {
        if (onAddButtonClick) {
            onAddButtonClick();
        }
    }
    const formatExportData = (rows: any[], columns: IColumns[]) => {
        return rows.map((row) => {
            const formattedRow: any = {};
            columns?.forEach((column) => {
                if (column.fieldName.includes('addresses')) {
                    const formattedAddress: any = formatAddressObject(row[column.fieldName]);
                    formattedRow[column.fieldName] = `${formattedAddress.addressLine1 || ''} ${formattedAddress.addressLine2 || ''} ${formattedAddress.city || ''} ${formattedAddress.state || ''} ${formattedAddress.zipCode || ''}`;
                } else {
                    formattedRow[column.fieldName] = row[column.fieldName];
                }
            });
            return formattedRow;
        });
    };

    const handleExportCSV = async () => {
        setExporting(true);
        const exportData = formatExportData(_rows, columns);
        const csvContent = [
            columns?.map((column) => column.header).join(','),
            ...exportData.map((row) =>
                columns?.map((column) => `"${row[column.fieldName] || ''}"`).join(',')
            ),
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `${id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setExporting(false);
    };

    return (
        <div className="data-grid-container" ref={gridContainerRef}>
            <Paper elevation={3} style={{ borderRadius: 10 }}>
                <Grid item container spacing={1} justifyContent={'start'}>
                    {title && (
                        <Grid
                            item
                            xs={12} // Full width on mobile
                            sm={4} // 1/3 width on larger screens
                            style={{ paddingLeft: 20, paddingTop: 15, height: '2rem' }}
                            className='ws-data-grid ws-data-grid-title'
                        >
                            <Typography variant="h6" id="tableTitle" sx={{ fontSize: '1rem' }}>
                                {title}
                            </Typography>
                        </Grid>
                    )}
                    {!title && (
                        <Grid
                            item
                            xs={12} // Full width on mobile
                            sm={4} // 1/3 width on larger screens
                            style={{ paddingLeft: 20, paddingTop: 0, height: '4rem' }}
                            className='ws-data-grid ws-data-grid-title'
                        >
                        </Grid>
                    )}
                    <Grid item xs={12} sm={8} style={{ paddingTop: 0 }} container spacing={1} justifyContent={'end'}>
                        {/* {addButton && (
                            <Grid item style={{ paddingTop: 0 }} >
                                {buttonStyle === "Normal" && (
                                    <IconButton
                                        sx={{
                                            backgroundColor: '#404040',
                                            borderRadius: 1,
                                            width: '24px',
                                            height: '24px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: (!exportCsv || !addButton) ? 1 : 0,
                                            justifyContent: 'center',
                                            '&:hover': {
                                                backgroundColor: '#606060',
                                            },
                                        }}
                                        onClick={handleAddButton}
                                    >
                                        <WSIcons.AddIcon style={{ color: 'white', width: '1rem' }} />
                                    </IconButton>
                                )}

                            </Grid>
                        )} */}
                        {exportCsv && (
                            <Grid item sx={{ paddingRight: !filterFields ? 2 : undefined }} style={{ paddingTop: 0, marginTop: -10 }} >
                                {buttonStyle === 'Normal' && (
                                    <IconButton
                                        sx={{
                                            backgroundColor: '#6082B6',
                                            borderRadius: 1,
                                            width:  '32px' ,
                                            height:  '32px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: (!exportCsv || !addButton) ? 1 : 0,
                                            justifyContent: 'center',
                                            '&:hover': {
                                                backgroundColor: '#606060',
                                            },
                                        }}
                                        onClick={handleExportCSV}
                                        disabled={exporting}
                                        color="primary"
                                    >
                                        <ExportIcon style={{ color: 'white', width:  '1.5rem'  }} />
                                    </IconButton>
                                )}
                            </Grid>
                        )}
                        {filterFields && (
                            <Grid item sx={{ paddingRight: 2 }} style={{ paddingTop: -15 }}>
                                <WSFilterComponent rows={filterFields} alignment="right" onFilterChange={handleFilterChange} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: 15 }} />
                <Grid item container spacing={1} paddingTop={2} paddingBottom={2}>
                    {sortedRows().map((row: any, rowIndex: number) => (
                        <React.Fragment key={rowIndex}>
                            {columns?.map((column, colIndex) => (
                                <>
                                    <Grid item xs={4} >
                                        {column?.header?.toLowerCase() !== 'actions' && <Typography style={{ fontSize: '0.75rem', paddingLeft: 12, fontWeight: 'bold', marginBottom: 0 }}>
                                            {column.header}
                                        </Typography>}
                                    </Grid>

                                    {column.type === 'default' && <Grid item xs={8} >
                                        <Typography style={{ fontSize: '0.75rem', paddingLeft: 0, color: 'gray', paddingTop: 0 }}>
                                            {row[column.fieldName]}
                                        </Typography>
                                    </Grid>}
                                    {column.type === 'date' && <Grid item xs={8} >
                                        <Typography style={{ fontSize: '0.75rem', paddingLeft: 0, color: 'gray', paddingTop: 0 }}>
                                            {dayjs(row[column.fieldName]).isValid() ? dayjs(row[column.fieldName]).format('DD/MM/YYYY') : 'Invalid Date'}
                                        </Typography>
                                    </Grid>}
                                    {column?.type === 'status' && <Grid item xs={8} >
                                        <WSStatus style={{ fontSize: '0.75rem', paddingLeft: 0, paddingTop: 0 }} value={row[column.fieldName]} />
                                    </Grid>}

                                    {column?.header?.toLowerCase() === 'actions' && <Grid item xs={12} container justifyContent={'flex-end'} style={{ marginTop: 0 }}>


                                        <ThreeDotMenu
                                            items={column.actions} align='horizontal'
                                            onAction={(action: string) =>
                                                column.actionCallback?.(action, row)
                                            }
                                        />
                                    </Grid>}</>
                            ))}
                            <Grid item xs={12}>
                                <Divider style={{ marginTop: 5, color: 'red' }} />
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                <Grid item container  >
                    {showpagination === true && <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        labelRowsPerPage="Rows"
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />}
                    {/* <WSButton id='loadMoreGrid' label='Load More' onClick={} size='small' sx={{marginBottom:2}} /> */}

                </Grid>
            </Paper>
            <Grid item xs={12} sm={8} style={{ paddingTop: 0 }} container spacing={1} justifyContent={'end'}>
                {addButton && (
                    <Grid item style={{
                        position: 'fixed',
                        bottom: '20px', // Adjust based on your design
                        right: '20px',  // Adjust based on your design
                        paddingTop: 0,
                    }} >
                        {buttonStyle === "Normal" && (
                            <IconButton
                                sx={{
                                    backgroundColor: 'gray',
                                    borderRadius: 14,
                                    width: '44px',
                                    height: '44px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 20,
                                    justifyContent: 'center',
                                    '&:hover': {
                                        backgroundColor: '#606060',
                                    },
                                }}
                                onClick={handleAddButton}
                            >
                                <WSIcons.AddIcon style={{ color: 'black', width: '3rem' }} />
                            </IconButton>
                        )}

                    </Grid>
                )}

            </Grid>

        </div>
    );

};

export default WSMobViewDataGrid;

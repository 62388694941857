import React, { useCallback, useEffect, useState } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  IconButton,
  Grid,
  InputAdornment,
  InputBasePropsSizeOverrides,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import http from '../../commonFunctions/httpClient';
import { OverridableStringUnion } from '@mui/types'; // Import this from MUI types

import store from '../../StateManagement/store';

type InputLabelPropsSizeOverrides = {};

type ControllSize = OverridableStringUnion<'small' | 'normal', InputLabelPropsSizeOverrides>
type selectSize = OverridableStringUnion<'small' | 'medium', InputBasePropsSizeOverrides>;

interface ControlledSelectFieldProps {
  label: any;
  id: string;
  name: string;
  className?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  disabled?: boolean;
  control: any;
  defaultValue?: any;
  rules?: UseControllerProps['rules'];
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  required?: boolean;
  setValue?: any;
  reset?: any;
  type?: any;
  options?: { value: string | number; label: string, disabled?: boolean }[];
  description?: IDescription;
  labelType?: 'Material' | 'Normal';
  addButton?: boolean;
  handleAddButtonClick?: (e: any) => void;
  source?: {
    url: string;
    pageSize: number;
    page: number;
    filter: any[];
    sort: string[];
  };
  transFormData?: (data: any) => any;
  controllSize?: ControllSize
  selectSize?: selectSize,
  isMobile?:boolean
}

interface IDescription {
  text: string | any;
  align: 'Top' | 'Bottom';
  style?: React.CSSProperties;
  className?: string;
}

const WSSelectField: React.FC<ControlledSelectFieldProps> = ({
  id,
  label,
  className,
  control,
  name,
  rules,
  variant = 'outlined',
  disabled = false,
  defaultValue = '',
  setValue,
  onChange,
  type,
  options,
  description,
  labelType = 'Material',
  addButton,
  handleAddButtonClick,
  source,
  transFormData,
  controllSize = "small",
  selectSize = "small",
  reset,
  isMobile=false
}) => {
  const [loading, setLoading] = useState<boolean>(source ? true : false);
  const [newOptions, setNewOptions] = useState<any>(options || []);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (options) {
      setNewOptions(options);
    }
  }, [options]);
  useEffect(() => {
    if (reset) {
      reset()
    }
  }, [reset]);

  useEffect(() => {
    if (setValue) {
      setValue(name, defaultValue);
    }
  }, [setValue, name, defaultValue]);

  const fetchDataFromAPI = async (
    page: number,
    pageSize: number,
    filter: any[] = [],
    sort: any[] = []
  ) => {
    try {
      // setLoading(true);
      if (source) {
        const response: any = await http.post(source?.url, {
          page: page,
          pageSize: pageSize,
          filter: filter,
          sort: sort,
        });
        return response;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      // setLoading(false); // End loading
    }
  };

  const loadPageData = useCallback(async () => {
    try {

      let response = await fetchDataFromAPI(1, 100000, source?.filter);
      setLoading(false)
      if (transFormData) {
        setNewOptions(transFormData(response?.data));
      } else {
        setNewOptions(response?.data);
      }
    } catch (error) {
      console.error('Error loading page data:', error);
    }
  }, [source, id, transFormData]);

  useEffect(() => {
    if (source && loadPageData && loading) {
      loadPageData();
    }
  }, [source, loadPageData]);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.components[id]?.data === null) {
        loadPageData();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [id, loadPageData]);

  const handleClick = (e: any) => {
    if (handleAddButtonClick) {
      handleAddButtonClick(e);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => (
        <><Grid container spacing={1}>
          {! isMobile && <Grid item sm={addButton ? 11 : 12}>
          
              <FormControl
                variant={variant}
                className={className}
                disabled={disabled}
                fullWidth
                //style={{minWidth:'25rem'}}
                error={!!fieldState.error}
              >
                {labelType === 'Material' && <InputLabel id={id} size={controllSize} sx={{
                  fontSize: '0.875rem', // Reduce the font size of the label
                }}>{label}</InputLabel>}
                {description && description.align === 'Top' && (
                  <Typography style={description.style}>{description.text}</Typography>
                )}
                {labelType === 'Normal' && (
                  <label style={{ display: 'block', marginBottom: '0.875rem', fontSize: '0.875rem' }} htmlFor={id}>
                    {label}
                  </label>
                )}
                {description && description.align === 'Bottom' && (
                  <Typography
                    style={description.style}
                    className={description?.className ? description?.className : undefined}
                  >
                    {description.text}
                  </Typography>
                )}

                <Select
                  labelId={id}
                  id={id}
                  {...field}
                  size={selectSize}
                  sx={{ height: 38 }}
                  onChange={(event: any) => {
                    field.onChange(event);
                    if (onChange) {
                      onChange(event);
                    }
                  }}

                  label={labelType === 'Material' ? label : undefined}
                  inputProps={{
                    endAdornment: addButton ? (
                      <InputAdornment position="end" className="suffixAdornment">
                        <IconButton aria-label="add" onClick={(e: any) => handleClick(e)}>
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,

                  }}
                >
                  {newOptions?.map((option: any) => (
                    <MenuItem disabled={option?.disabled ? option?.disabled : false} key={option.value} value={option.value} sx={{ fontSize: '0.875rem' }}>
                      <Typography sx={{ fontSize: '0.875rem' }}>{option.label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
                {loading && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: 'absolute', // Position the spinner absolutely
                      right: '20px', // Align it to the right of the select box
                      top: '30%', // Vertically center it
                      transform: 'translateY(-50%)',
                    }}
                  />
                )}
                {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
              </FormControl>
            
          </Grid>}

          {/* Render the Add button outside the FormControl */}
          {addButton && (
            <Grid item sm={1} style={{ display: 'flex', alignItems: 'center' }} justifyContent={'end'}>
              <IconButton aria-label="add" onClick={(e: any) => handleClick(e)}>
                <AddIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        {isMobile && <FormControl
          variant={variant}
          className={className}
          disabled={disabled}
          fullWidth
          //style={{minWidth:'25rem'}}
          error={!!fieldState.error}
        >
          {labelType === 'Material' && <InputLabel id={id} size={controllSize} sx={{
            fontSize: '0.875rem', // Reduce the font size of the label
          }}>{label}</InputLabel>}
          {description && description.align === 'Top' && (
            <Typography style={description.style}>{description.text}</Typography>
          )}
          {labelType === 'Normal' && (
            <label style={{ display: 'block', marginBottom: '0.875rem', fontSize: '0.875rem' }} htmlFor={id}>
              {label}
            </label>
          )}
          {description && description.align === 'Bottom' && (
            <Typography
              style={description.style}
              className={description?.className ? description?.className : undefined}
            >
              {description.text}
            </Typography>
          )}

          <Select
            labelId={id}
            id={id}
            {...field}
            size={selectSize}
            sx={{ height: 38 }}
            onChange={(event: any) => {
              field.onChange(event);
              if (onChange) {
                onChange(event);
              }
            }}

            label={labelType === 'Material' ? label : undefined}
            inputProps={{
              endAdornment: addButton ? (
                <InputAdornment position="end" className="suffixAdornment">
                  <IconButton aria-label="add" onClick={(e: any) => handleClick(e)}>
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,

            }}
          >
            {newOptions?.map((option: any) => (
              <MenuItem disabled={option?.disabled ? option?.disabled : false} key={option.value} value={option.value} sx={{ fontSize: '0.875rem' }}>
                <Typography sx={{ fontSize: '0.875rem' }}>{option.label}</Typography>
              </MenuItem>
            ))}
          </Select>
          {loading && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute', // Position the spinner absolutely
                right: '20px', // Align it to the right of the select box
                top: '30%', // Vertically center it
                transform: 'translateY(-50%)',
              }}
            />
          )}
          {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
        </FormControl>}
        </>
        
      )}
    />
  );
};

export default WSSelectField;

import React, { useEffect } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { Radio, RadioGroup, FormControl, FormControlLabel, FormHelperText, FormLabel } from '@mui/material';

interface ControlledRadioFieldProps {
  label?: string;
  id: string;
  name: string;
  className?: string;
  disabled?: boolean;
  control: any;
  defaultValue?: any;
  rules: UseControllerProps['rules'];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  setValue?: any;
  options: any[]; // New options prop
}

const WSRadioField: React.FC<ControlledRadioFieldProps> = ({
  id,
  label,
  className,
  control,
  name,
  rules,
  disabled = false,
  defaultValue = '',
  setValue,
  onChange,
  options,
}) => {
  useEffect(() => {
    if (setValue) {
      setValue(name, defaultValue);
    }
  }, [setValue, name, defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => (
        <FormControl className={className} disabled={disabled} error={!!fieldState.error} component="fieldset">
          <FormLabel sx={{ fontSize: '0.875rem', marginTop: -1 }}>{label}</FormLabel>
          <RadioGroup
            row
            sx={{ fontSize: '0.875rem' }}
            aria-labelledby={id}
            {...field}
            onChange={(event) => {
              field.onChange(event);
              if (onChange) {
                onChange(event);
              }
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '0.875rem', } }} />} // Reduced radio button size
                label={<span style={{ fontSize: '0.875rem' }}>{option.label}</span>} // Reduced label size
                disabled={disabled}
              />
            ))}
          </RadioGroup>
          {fieldState.error && <FormHelperText sx={{ fontSize: '0.875rem' }}>{fieldState.error.message}</FormHelperText>} {/* Reduced error message size */}
        </FormControl>
      )}
    />
  );
};

export default WSRadioField;

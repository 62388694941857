import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { callAPI, customValidation, EmployeeStorageVariable, EPhone, formatPhoneNumber, getEmployeeLocalValue, IsSmallScreen, localStorageVariable, Method, validations } from "../../commonFunctions/CommonFunctions";
import WSDateField from "../../Components/DateField/WSDateField";
import WSHiddenField from "../../Components/HiddenField/WSHiddenField";
import WSPhone from "../../Components/Phone/WSPhone";
import WSSelectField from "../../Components/Select/WSSelect";
import WSTextField, { TextType } from "../../Components/TextBox/WSTextBox";
import config from '../../Config/clientConfig.json';
import { IAddress } from "../Interfaces/Interfaces";

interface IPersonaleDetailsProps {
    control: any;
    register: any;
    errors: any
    name?: string
    setValue: any;
}
const PersonalDetails: React.FC<IPersonaleDetailsProps> = React.memo((props) => {
    const { control, register, setValue } = props;
    const [personalInfoId, setPersonalInfoId] = useState(
        getEmployeeLocalValue(localStorageVariable.EmployeeMaster, EmployeeStorageVariable.personalInfoId) || null
    );
    const isMobile=IsSmallScreen()
    useEffect(() => {
               /**
         * Method to get the data for editing pupose
         */
        try{
        async function getData() {
            let response = await callAPI(`employee/emppersonalinfo`, Method.GET, {}, personalInfoId)
            const empPersonalInfo: any = response?.data
            if (setValue && empPersonalInfo) {
                setValue("email", empPersonalInfo.email)
                setValue("mobile", empPersonalInfo.mobile)
                setValue("dob", empPersonalInfo?.dob)
                setValue("fatherName", empPersonalInfo.fatherName)
                setValue("age", empPersonalInfo.age)
                setValue("pan", empPersonalInfo.pan)
                setValue("emergencyContact", formatPhoneNumber(empPersonalInfo?.emergencyContact,EPhone.AddHyphen))
                if (empPersonalInfo.residentialAddress && empPersonalInfo.residentialAddress.length > 0) {
                    const resiAddress: IAddress = empPersonalInfo.residentialAddress[0];
                    setValue("addressLine1", resiAddress.addressLine1)
                    setValue("addressLine2", resiAddress.addressLine2)
                    setValue("city", resiAddress.city)
                    setValue("state", resiAddress.state)
                    setValue("pincode", resiAddress.pincode)
                    setValue("addressId", resiAddress.id)
                }
                if (empPersonalInfo.emergencyAddress && empPersonalInfo.emergencyAddress.length > 0) {
                    const emergencyAddress: any = empPersonalInfo.emergencyAddress[0];
                    setValue("eAddressLine1", emergencyAddress.addressLine1)
                    setValue("eAddressLine2", emergencyAddress.addressLine2)
                    setValue("eCity", emergencyAddress.city)
                    setValue("eState", emergencyAddress.state)
                    setValue("ePincode", emergencyAddress.pincode)
                    setValue("eAddressId", emergencyAddress.id)
                }
            }
        }
        if (personalInfoId) {
            getData();
        }
    }
    catch (e) {
    }
    }, [personalInfoId, setValue])
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <WSTextField
                    control={control}
                    className="form-control"
                    id="email"
                    {...register("email")}
                    rules={customValidation(true, validations.email)}
                    name="email"
                    label="Personal Email Address "
                //labelType="Normal"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <WSPhone
                    id="mobile"
                    //  //labelType="Normal"
                    {...register('mobile')}
                    label="Mobile Number *"
                    control={control}
                    // suffix={{ label: 'Verify', type: "Link" }}
                    rules={{ required: true }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <WSDateField
                    id="dob"
                    name="dob"
                    label="Date of Birth *"
                    dateFormat="D/MM/YYYY"
                    control={control}
                    defaultValue={null}
                    denyFuturOrPast="future"
                    rules={{ required: true }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <WSTextField
                    control={control}
                    id="fatherName"
                    {...register("fatherName")}
                    rules={{ required: true }}
                    name="fatherName"
                    label="Father's Name *"
                    //labelType="Normal"
                    defaultValue=""
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <WSTextField
                    control={control}
                    id="age"
                    type={TextType.Number}
                    maxLength={2}
                    {...register("age")}
                    rules={{ required: true, min: { value: 21, message: "Minimum age is 21" },
                    max: { value: 100, message: "Maximum age is 100" }, }}
                    name="age"
                    label="Age "
                    //labelType="Normal"
                    defaultValue=""
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <WSTextField
                    control={control}
                    id="pan"
                    {...register("pan")}
                    rules={{ required: true }}
                    name="pan"
                    maxLength={10}
                    label="PAN "
                    //labelType="Normal"
                    defaultValue=""
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <WSPhone
                    id="emergencyContact"
                    //  //labelType="Normal"
                    {...register('emergencyContact')}
                    label="Emergency Contact *"
                    control={control}
                    // suffix={{ label: 'Verify', type: "Link" }}
                    rules={{ required: true }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ fontSize: '0.875rem', color: '#404040' }}>Residential Address</Typography>
            </Grid>
            <Grid item xs={12}>
                <WSTextField
                    control={control}
                    id="addressLine1"
                    {...register("addressLine1")}
                    rules={{ required: true }}
                    name="addressLine1"
                    label="Address line1 *"
                />
            </Grid>

            <Grid item xs={12}>
                <WSTextField
                    control={control}
                    id="addressLine2"
                    {...register("addressLine2")}
                    rules={{ required: false }}
                    name="addressLine2"
                    label="Address line2"
                />
            </Grid>
            <Grid item container xs={12} sm={4}>
                <WSSelectField
                    options={config.stateConfig.staticOptionData}
                    rules={{ required: true }}
                    {...register("state")}
                    id="state"
                    isMobile={isMobile}
                    control={control}
                    defaultValue={"0"}
                    label="State *"
                    name="state"
                ></WSSelectField>

            </Grid>
            <Grid item xs={12} sm={4}>
                <WSTextField
                    control={control}
                    id="city"
                    {...register("city")}
                    rules={{ required: true }}
                    name="city"
                    label="City *"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <WSTextField
                    control={control}
                    id="pincode"
                    {...register("pincode")}
                    rules={{ required: true }}
                    maxLength={6}
                    name="pincode"
                    label="PIN *"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ fontSize: '0.875rem', color: '#404040' }}>Emergency Address</Typography>
            </Grid>
            <Grid item xs={12}>
                <WSTextField
                    control={control}
                    id="eAddressLine1"
                    {...register("address")}
                    rules={{ required: true }}
                    name="eAddressLine1"
                    label="Address line1 *"
                />
            </Grid>

            <Grid item xs={12}>
                <WSTextField
                    control={control}
                    id="eAddressLine2"
                    {...register("eAddressLine2")}
                    rules={{ required: false }}
                    name="eAddressLine2"
                    label="Address line2"
                />
            </Grid>
            <Grid item container xs={12} sm={4}>
                <WSSelectField
                    options={config.stateConfig.staticOptionData}
                    rules={{ required: true }}
                    {...register("eState")}
                    id="eState"
                    control={control}
                    isMobile={isMobile}
                    defaultValue={"0"}
                    label="State *"
                    name="eState"
                ></WSSelectField>

            </Grid>
            <Grid item xs={12} sm={4}>
                <WSTextField
                    control={control}
                    id="eCity"
                    {...register("eCity")}
                    rules={{ required: true }}
                    name="eCity"
                    label="City *"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <WSTextField
                    control={control}
                    id="ePincode"
                    {...register("ePincode")}
                    maxLength={6}
                    rules={{ required: true }}
                    name="ePincode"
                    label="PIN *"
                />
                <WSHiddenField control={control} name="id" defaultValue={personalInfoId} />
                <WSHiddenField control={control} name="addressId" />
                <WSHiddenField control={control} name="eAddressId" />
            </Grid>
        </Grid>
    )
}
)
export default PersonalDetails;
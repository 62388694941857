import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { callAPI, clearLocalValue, fetchRolesById, IRoleMain, LoacalVariables, Method, setLocalValues } from '../../commonFunctions/CommonFunctions';
import WSButton from '../../Components/Button/WSButton';
import WSRadioField from '../../Components/Radio/WSRadio';
import { useSnackbar } from '../../Components/Snackbar/SnackBarContext';
import WSTextField, { TextType } from '../../Components/TextBox/WSTextBox';
import hrmsImage from '../../imgs/hrms.jpg';
import { storeDataComponent } from '../../StateManagement/actions';
// import googleLogo from '../../imgs/google-logo.png'; // Example Google logo

interface LoginFormProps {
    onLogin: (token: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
    const [error, setError] = useState('');
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const { showMessage } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const dispatch=useDispatch();
    const navigate = useNavigate();
    //const [roles, setRoles] = useState<any>(null);
    useEffect(() => {
        setValue("userName", ""); // Clear the username field on mount
        setValue("password", ""); // Clear the password field on mount
    }, []);
    useEffect(() => {

        // const getRoleById = async (id: string) => {
        //     try {
        //         const response = await fetchRolesById(id);

        //         if (response) {
        //             setRoles(response);

        //         }
        //     } catch (error) {
        //         console.error(error);
        //     }
        // };
        // getRoleById('1001');

        // const getData = async () => {
        //     try {
        //         const data = await fetchAdminData('Admin');

        //         setRoles(data);
        //     } catch (error) {
        //         console.error('Error fetching admin data:', error);
        //     }
        // };

        // getData();
    }, []);

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);
            const apiUrl: string = data?.loginOption === 'emp' ? 'EmpLogin/login' : 'User/login'
            if (data?.loginOption === 'admin') {
                const response: any = await callAPI(apiUrl, Method.POST, { userName: data?.userName, password: data?.password });
           
                if(response?.message){
                    showMessage(response?.message, 'error')
                }else
                if (response) {
                    const roles: any = await getRoleById(response?.roleId)
                    clearLocalValue(IRoleMain.Roles);
                    setLocalValues(IRoleMain.Roles, roles ? roles : []);
                    const token = response?.userId;
                    setLocalValues(LoacalVariables.UserId, response?.userId?.toString());
                    setLocalValues(LoacalVariables.UserType, 'admin'); //to do
                    setLocalValues(LoacalVariables.EmployeeInfo, response); //to do encrypt?
                    onLogin(token);
                    showMessage('Login Successfull!', 'success')
                }
                else if (response === undefined) {
                    showMessage('Login Failed', 'error')
                }
            }
            else {
                const response: any = await callAPI(apiUrl, Method.POST, { username: data?.userName, password: data?.password });
                dispatch(storeDataComponent("changePassword",{url:'EmpLogin/ChangePassword',oldPassword:data?.password}));
                if (response) {
                    const empId=response?.empBasicInfo?.id
                    navigate('/changepassword')
                    const token = empId || undefined;
                    if (token) {
                        setLocalValues(LoacalVariables.UserId, empId?.toString());
                        setLocalValues(LoacalVariables.UserType, 'emp'); //to do
                        setLocalValues(LoacalVariables.EmployeeInfo, response); //to do encrypt?
                       
                        onLogin(token);

                        showMessage('Login Successfull!', 'success')
                    }

                }
                else if (response === undefined) {
                    showMessage('Login Failed', 'error')
                }
            }
        } catch (err) {
            setError('Invalid username or password');
        }
        finally {
            setLoading(false)
        }

    };
    async function postLogin(userName: string, password: string) {

    }
    const getRoleById = async (id: string) => {
        try {
            const response = await fetchRolesById(id);
            return response
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Paper  elevation={3} sx={{ width: '100%',  p: 4, borderRadius: '10px',alignContent:'center',justifyContent:'center' }} >
        <Grid container sx={{ minHeight: '89vh' , }}>
            {/* Left Side Panel */}
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderTopLeftRadius:'12px',
                    borderBottomLeftRadius:'12px',
                    backgroundImage: `url(${hrmsImage})`,
                    p: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        color: '#fff',
                    }}
                >
                    {/* Illustration/Content */}
                    <img src={hrmsImage} alt="Illustration" style={{  width: '80%', maxWidth: '400px'  }} />
                    <Typography variant="h5" sx={{ mt: 2, fontWeight: 'bold' }}>
                        HRMS
                    </Typography>
                    {/* <Typography variant="body1" sx={{ mt: 1 }}>
                        Erdum et malesuada fames ac ante ipsum primis in faucibus.
                    </Typography> */}
                </Box>
            </Grid>

            {/* Right Side Panel */}
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 3,
                    backgroundColor: '#B0E0E6',
                    borderTopRightRadius:'12px',
                    borderBottomRightRadius:'12px'
                }}
            >
                <Paper elevation={3} sx={{ width: '100%', maxWidth: '400px', p: 4, borderRadius: '10px' }}>
                    {/* Welcome Header */}
                    <Typography variant="h5" sx={{ textAlign: 'center', mb: 3 }}>
                        Login
                    </Typography>
                    <Divider/>
                    <br></br>

                    {/* Login Form */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <WSRadioField
                                    control={control}
                                    id='loginOption'
                                    name='loginOption'
                                    options={[{ label: 'Employee', value: 'emp' }, { label: 'Admin', value: 'admin' }]}
                                    defaultValue='emp'
                                    rules={{ required: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <WSTextField
                                    id="userName"
                                    {...register("userName", { required: true })}
                                    name="userName"
                                    label="User ID"
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <WSTextField
                                    id="password"
                                    type={TextType.Password}
                                    {...register("password", { required: true })}
                                    name="password"
                                    label="Password"
                                    control={control}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Typography variant="body2" sx={{ textAlign: 'right', mb: 1 }}>
                                    <Link href="#" underline="hover">Forgot password?</Link>
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12}>
                                <WSButton
                                    id="btnlogin"
                                    label="Sign in"
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: '100%', borderRadius: 5 }}
                                    type='submit'
                                    loading={loading}
                                />
                            </Grid>
                        </Grid>
                    </form>

                    <Divider sx={{ my: 3 }} />

                    {/* Sign in with Google */}
                    {/* <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <img src={googleLogo} alt="Google Logo" style={{ width: 20, marginRight: 8 }} />
                        <Typography variant="body2">Sign in with Google</Typography>
                    </Box>
 */}
                    {/* Create Account */}
                    {/* <Typography variant="body2" sx={{ textAlign: 'center' }}>
                        New to Lovebirds? <Link href="#">Create Account</Link>
                    </Typography> */}
                </Paper>
            </Grid>
        </Grid>
        </Paper>
    );
};

export default LoginForm;

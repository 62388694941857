import React, { useEffect } from 'react';
import TextField, { TextFieldPropsSizeOverrides } from '@mui/material/TextField';
import { Controller, UseControllerProps } from 'react-hook-form';
import { Typography, InputAdornment } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { IMaskInput } from 'react-imask';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface ControlledTextFieldBaseProps {
  label: any;
  id: string;
  name: string;
  className?: string;
  variant?: "standard" | "filled" | "outlined";
  disabled?: boolean;
  control: any;
  defaultValue?: any;
  rules?: UseControllerProps['rules'];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTextChange?:(event:any)=> void;
  OnPaste?:any;
  required?: boolean;
  setValue?: any;
  register?: any;
  maxLength?: number;
  minLength?: number;
  labelType?: "Material" | "Normal";
  description?: IDescription;
  suffix?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: any;
  size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
  mask?: string; // Add mask prop
  formatProps?: NumericFormatProps; // Add formatProps for NumericFormat
  autoFocus?:boolean
  sx?:any
}

interface IDescription {
  text: string | any;
  align: "Top" | "Bottom";
  style?: React.CSSProperties;
  className?: string;
}

export enum TextType {
  Text = "text",
  Password = "password",
  Number = "number",
  Email = "email",
  Date = "date",
  Currency = "currency",
  Otp="Otp"
}

export enum CurrencyType {
  Rupee = "₹",
  Dollar = "$"
}

interface CurrencyFieldProps extends ControlledTextFieldBaseProps {
  type: TextType.Currency;
  currency: CurrencyType;
}

interface NonCurrencyFieldProps extends ControlledTextFieldBaseProps {
  type?: Exclude<TextType, TextType.Currency>;
  currency?: never;
}

type ControlledTextFieldProps = CurrencyFieldProps | NonCurrencyFieldProps;

const TextMaskCustom = React.forwardRef<HTMLInputElement, { onChange: (event: { target: { name: string; value: string } }) => void; name: string; mask: string; }>(
  function TextMaskCustom(props, ref) {
    const { onChange, mask, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

const NumericFormatCustom = React.forwardRef<NumericFormatProps, { onChange: (event: { target: { name: string; value: string } }) => void; name: string; formatProps?: NumericFormatProps; }>(
  function NumericFormatCustom(props, ref) {
    const { onChange, formatProps, ...other } = props;
    return (
      <NumericFormat
        {...formatProps}
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  }
);

const WSTextField: React.FC<ControlledTextFieldProps> = ({
  id,
  label,
  className,
  control,
  name,
  rules,
  variant = "outlined",
  disabled = false,
  defaultValue = '',
  setValue,
  onChange,
  onTextChange = () => {},
  OnPaste=()=>{},
  type = TextType.Text,
  maxLength,
  minLength,
  labelType = "Material",
  description,
  currency,
  suffix,
  onKeyDown,
  inputRef,
  size="small",
  mask, // Add mask prop
  formatProps ,// Add formatProps prop
  autoFocus=false,
}) => {
  useEffect(() => {
    if( setValue && defaultValue!==undefined){
      setValue(name,defaultValue)
    }
  }, [defaultValue,setValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => (
        <>
          {description && description.align === "Top" && (
            <Typography style={description.style}>{description.text}</Typography>
          )}
          {labelType === 'Normal' && (
            <label style={{ display: 'block', marginBottom: '0.875rem',fontSize:'0.875rem' }} htmlFor={id}>
              {label}
            </label>
          )}
          {description && description.align === "Bottom" && (
            <Typography style={description.style} className={description?.className ? description?.className : undefined}>
              {description.text}
            </Typography>
          )}
          <TextField
            size={size}
            id={id}
            autoFocus={autoFocus}
            fullWidth
            {...field}
            className={fieldState.invalid ? 'WSError' : className}
            label={labelType === 'Material' ? label : undefined}
            variant={variant}
            disabled={disabled}
            style={{backgroundColor:disabled?'gray':undefined}}
            onKeyDown={onKeyDown ? onKeyDown : undefined}
            type={type !== TextType.Currency ? type : 'text'} // Ensure proper type
            error={fieldState.invalid}
            value={field.value}
            onPaste={OnPaste}
            inputRef={inputRef}
            sx={ {
              '& .MuiInputBase-input': {
                fontSize: '0.875rem', // Reduce the font size
              },
              '& .MuiFormHelperText-root': {
                fontSize: '0.75rem', // Reduce the font size of the error message
              },
              '& .MuiInputLabel-root': {
                fontSize: '0.875rem', // Reduce the font size of the label
                
              },
              
            }}
            helperText={fieldState.error ? fieldState.error.message : ''}
            inputProps={{
              maxLength,
              minLength,
              inputMode: type === TextType.Currency || type === TextType.Number ? 'decimal' : 'text',
              ...(mask ? { mask } : {}), // Add mask if provided
            }}
            InputProps={{
              startAdornment: type === TextType.Currency && currency ? <InputAdornment position="start">{currency}</InputAdornment> : null,
              endAdornment: suffix ? <InputAdornment position="end" className="suffixAdornment">{suffix}</InputAdornment> : null,
              inputComponent: mask ? TextMaskCustom as any : type === TextType.Currency ? NumericFormatCustom as any : undefined, // Choose the right component
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              
              if (onTextChange) {
                onTextChange(e); // Ensure this line is uncommented
              }
              field.onChange(e); // Always call field.onChange to ensure react-hook-form updates
            }}
           //onChange={onTextChange ?onTextChange:undefined}
            // // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // //   if(type === TextType.Currency && onChange) {
            // //     field.onChange(e);
            // //     onChange(e);
            // //   }
            // // }}
          />
        </>
      )}
    />
  );
};

export default WSTextField;

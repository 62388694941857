import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import WSDataGrid, { IColumns } from "../../Components/DataGrid/WSDataGrid";
import { IRows } from "../../Components/Filter/WSFilterComponent";
import { Header } from "../Header/Header";
import { IRoleMain, IsSmallScreen, MasterPages } from "../../commonFunctions/CommonFunctions";
import WSMobViewDataGrid from "../../Components/DataGrid/WSMobViewDataGrid";
import { getPermissions } from "../../commonFunctions/PermissionManger";
import PermissionDenied from "../../commonFunctions/PermissionDenied";
export const UserManagementList: React.FC<any> = React.memo(
    (props) => {
        const navigate = useNavigate();
        const { view, exports, add, edit } = getPermissions(MasterPages.Users, IRoleMain.Masters);
        const source = {
            url: "user/search",
            page: 1,
            pageSize: 10,
            filter: [],
            sort: [],
        };

        /**
         * Edit function
         * @param action 
         * @param row 
         */
        const handleAction = (action: string, row: any) => {
            if (action === "Edit") {
                navigate(`/user/${row?.id}`)
            }
        };

        const columns: IColumns[] = [
            {
                header: "Name",
                fieldName: "name",
                tooltip: true,
                type: 'default'
            },
            {
                header: "User Name",
                fieldName: "userName",
                tooltip: true,
                type: 'default'
            },
            {
                header: "Email",
                fieldName: "email",
                tooltip: true,
                type: 'default'
            },
            {
                header: "Phone",
                fieldName: "phone",
                tooltip: true,
                type: 'default'
            },
            {
                header: "Actions",
                fieldName: "actions",
                type: "action",
                actions: [edit ? "Edit" : undefined],
                actionCallback: (action, row) => handleAction(action, row),
            },
        ];


        const filterRows: IRows[] = [
            {
                label: "First Name",
                fieldName: "FirstName",
                type: "text",
                operator: 'like',
                rowDivision: 6
            },
            {
                label: "Last Name",
                fieldName: "LastName",
                type: "text",
                operator: 'like',
                rowDivision: 6
            },

        ];
        const transFormData = (data: any) => {
            data && data?.map((item: any) => {
                item.name = item?.firstName + ' ' + item?.lastName
            })
            return data
        }
        const handleClick = () => {
            navigate('/user/add')
        }
        return (
            <><Header title="User Management" type="simple" isDivider={true} />
                {view ? <Grid container spacing={2}>
                    {!IsSmallScreen() ? <Grid item xs={12}>
                        <WSDataGrid
                            transFormData={transFormData}
                            id="user-management-list"
                            title="Users"
                            filterFields={filterRows}
                            columns={columns}
                            source={source}
                            exportCsv={exports}
                            addButton={add ? { label: '', color: 'secondary' } : undefined}
                            onAddButtonClick={handleClick}
                        />
                    </Grid> : <Grid item xs={12}>
                        <WSMobViewDataGrid
                            transFormData={transFormData}
                            id="user-management-list"
                            title="Users"
                            filterFields={filterRows}
                            columns={columns}
                            source={source}
                            exportCsv={exports}
                            addButton={add ? { label: '', color: 'secondary' } : undefined}
                            onAddButtonClick={handleClick}
                        />
                    </Grid>}
                </Grid> : <PermissionDenied />}
            </>
        );
    }
);





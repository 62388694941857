import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import http from '../../commonFunctions/httpClient'

const Logout: React.FC = () => {
    const navigate = useNavigate();
    
    const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
    useEffect(() => {
    
        localStorage.removeItem('token');
        localStorage.clear()
        setToken(null)
        http.setAuthToken(null);
        navigate('/login');
        window.location.reload();
    }, [navigate]);

    return <div>Logging out...</div>;
};

export default Logout;


/**
 * Component   : UserDashboard
 * Description : User Dashboard
 */
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { calculateDateDifferenceExcludingWeekends, callAPI, getFullName, getLocalValue, IsSmallScreen, LoacalVariables, Method } from "../../commonFunctions/CommonFunctions";
import { WSHeaders } from "../../commonFunctions/HeaderConfig/HeaderConfig";
import { WSIcons } from '../../commonFunctions/iIcons';
import WSGoogleChart from "../../Components/Chart/WSGoogleChart";
import WSDataGrid, { IColumns } from "../../Components/DataGrid/WSDataGrid";
import WSMobViewDataGrid from "../../Components/DataGrid/WSMobViewDataGrid";
import WSMainCard, { IFields } from "../../Components/MainCard/WSMainCard";
import { IViewAllLinkColumns, WSViewAllLinkGrid } from "../../Components/ViewAllLinkGrid/ViewAllLinkGrid";
import WSCalander from "../../Components/WSCalander/WSCalander";
import WSCard from "../../Components/WSCard";
import { updateOrRegisterComponent } from "../../StateManagement/RegisterComponent";
import { IUserEmployeeBasic, IUserEmployeeLeave } from "../Interfaces/Interfaces";
interface IListData {
    leave: Array<any>,
    holiday: Array<any>
    departmentLeaves: Array<any>
    meeting: Array<any>
    leaveByEmployee: Array<any>
    pendingLeave: Array<any>
    totalLeaveToday:Array<any>
}

export const UserDashboard: React.FC<any> = React.memo((props) => {
    const userType: any = getLocalValue(LoacalVariables.UserType);
    const [userData, setUserData] = useState<any>(getLocalValue(LoacalVariables?.EmployeeInfo, true))
    const [profileData, setProfileData] = useState<IUserEmployeeBasic>()
    const [leaveData, setLeaveData] = useState<IUserEmployeeLeave[]>([]);
    const [leaveCal, setLeaveCal] = useState<any>([])
    const [listData, setListData] = useState<IListData>({ holiday: [], leave: [], departmentLeaves: [], meeting: [], leaveByEmployee: [], pendingLeave: [] ,totalLeaveToday:[]})
    const navigate = useNavigate()

    useEffect(() => {
        const basic: any = userData?.empBasicInfo;
        const personal: any = userData?.empPersonalInfos && userData?.empPersonalInfos.length > 0 ? userData?.empPersonalInfos[0] : undefined
        const leave: any = userData?.leaveInfos && userData?.leaveInfos.length > 0 ? userData?.leaveInfos : undefined
        if (userData) {
            if (userType !== 'admin') {
                const empData: IUserEmployeeBasic = {
                    name: `${basic?.firstName} ${basic?.middleName ? basic?.middleName : ''} ${basic?.lastName}`,
                    dob: personal?.dob,
                    status: personal?.isActive ? 'Active' : 'In Active'
                }
                setProfileData(empData)
            } else {
                const userProfileData: any = {
                    fullName: `${userData?.firstName} ${userData?.middleName ? userData?.middleName : ''} ${userData?.lastName}`,
                    email: userData?.email,
                    phone: userData?.phone
                }
                setProfileData(userProfileData)
            }

            if (leave) {
                const leaveInfoArray: any = []
                leave?.map((item: any) => {
                    const leaveInfo: IUserEmployeeLeave = {
                        categoryName: item.leaveType?.leaveMasterId || '',
                        type: `${item.leaveType?.name} `,// (${item.leave})` || '',
                        availableLeave: ((item.leave ) - (item.leaveTaken )),
                        totalLeave: item.leave || 0,
                        id: item?.leaveType?.id
                    };
                    leaveInfoArray.push(leaveInfo)
                })
                setLeaveData(leaveInfoArray)
            }

        }

    }, [userData])
    useEffect(() => {
        try {
            async function getListData() {
                const response: any = await callAPI('NationalHoliday/all', Method.GET);
                const newList: IListData = {
                    holiday: [],
                    leave: [],
                    departmentLeaves: [],
                    meeting: [],
                    leaveByEmployee: [],
                    pendingLeave: [],
                    totalLeaveToday:[]
                }
                let newArrayDate: any = []
                if (response && response?.data) {

                    response?.data?.map((item: any) => {
                        const month = moment.utc().month();
                        const year = moment.utc().year();

                        if (month === moment(item.date).month() && year === moment(item.date).year()) {
                            newList?.holiday?.push({ name: item?.name, date: item?.date })
                        }
                        newArrayDate.push({ date: item?.date, reason: item?.name, })
                    });
                }
                setLeaveCal(newArrayDate)
                const body = {

                    page: 1,
                    pageSize: 100000,
                    filter: [],
                    sort: [],
                };
                const leaveResponse: any = await callAPI('LeaveApply/search', Method.POST, body);
                if (leaveResponse && leaveResponse?.data) {

                    leaveResponse?.data?.map((item: any) => {
                        item.leaveName = item?.leaveType?.name
                        newList.leave.push({ leaveName: item?.leaveType?.name, dateFrom: item?.dateFrom, dateTo: item?.dateTo, statusCode: item?.statusCode })
                    });
                }
                // userData?.leaveByDepartment?.push(
                //     {
                //         "departmentId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                //         "departmentName": "Operation",
                //         "leaveCount": 2
                //     },
                //     {
                //         "departmentId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                //         "departmentName": "Software",
                //         "leaveCount": 5
                //     }
                // )
                // userData?.leaveByDepartment?.map((item:any)=>{
                //     newList.departmentLeaves.push({label:item?.departmentName,value:item?.leaveCount})
                // })
                userData?.leaveByDepartment?.map((item: any) => {
                    newList.departmentLeaves.push([item?.departmentName, item?.leaveCount])
                   
                })
                
                userData?.leaveByEmployee?.map((item: any) => {
                    const name = item?.employee ? `${item?.employee?.firstName} ${item?.employee?.middleName ? item?.employee?.middleName : ''} ${item?.employee?.lastName}` : ''
                    newList.leaveByEmployee.push({ fullName: name, status: 'Absent' })
                })
                if (userData?.pendingLeave?.length > 0) {
                    newList.pendingLeave = userData?.pendingLeave;
                }
                setListData(newList)
            }
            getListData()
        } catch (e) { }
    }, [])
    const handlyAppy = (type: string, id: string) => {
        navigate("/leave/request", { state: { leaveType: type, leaveId: id } });
    };
    const rows: IFields[] = userType !== 'admin' ? [
        {
            field: { name: 'name' },
            text: { label: 'Name' },
            type: 'Text'
        },
        {
            field: { name: 'dob' },
            text: { label: 'Date of Birth' },
            type: 'Text'
        },
        {
            field: { name: 'status' },
            text: { label: 'Status' },
            type: 'Status'
        }
    ] : [
        {
            field: { name: 'fullName' },
            text: { label: 'Name' },
            type: 'Text'
        },
        {
            field: { name: 'email' },
            text: { label: 'Email' },
            type: 'Text'
        },
        {
            field: { name: 'phone' },
            text: { label: 'Phone' },
            type: 'Text'
        }
    ]

    const columns: IViewAllLinkColumns[] = [
        {
            fieldName: 'name',
            header: 'Name'
        },
        {
            fieldName: 'date',
            header: 'Date',
            type: 'date'
        },

    ]
    const leaveColumns: IColumns[] = [
        {
            header: "Requested by",
            fieldName: "employee",
            type: 'default'

        },
        {
            header: "From",
            fieldName: "fromDate",
            type: 'date'

        },
        {
            header: "To",
            fieldName: "toDate",
            type: 'date'
        },

        {
            header: "Status",
            fieldName: "statusCode",
            type: 'status'
        },
        {
            header: "Actions",
            fieldName: "actions",
            type: "action",
            actions: ["View"],
            actionCallback: (action, row) => handleAction(action, row),
        },

    ]
    // const handleAction = (action: string, row: any) => {
    //     if (action === 'View') {
    //         const leaveRequsetedDays = calculateDateDifferenceExcludingWeekends(row?.fromDate, row?.toDate)
    //         let leaveData: any = { ...row }
    //         leaveData.statusCode = row?.status;
    //         leaveData.employee = `${getFullName(row?.employee?.firstName, row?.employee?.lastName, row?.employee?.middleName)}`
    //         leaveData.leaveRequsetedDays = leaveRequsetedDays + (leaveRequsetedDays <= 1 ? ' Day' : ' Days')
    //         switch (action) {
    //             case 'View':
    //                 navigate('/leave/details', { state: { leaveData: leaveData } })
    //                 break;

    //         }
    //     }
    // }
    const handleAction = (action: string, row: any) => {
        const leaveRequsetedDays = calculateDateDifferenceExcludingWeekends(row?.dateFrom, row?.dateTo)
        let leaveData: any = { ...row }
        leaveData.leaveRequsetedDays = leaveRequsetedDays + (leaveRequsetedDays <= 1 ? ' Day' : ' Days')
        switch (action) {
            case 'View':
                navigate('/leave/details', { state: { leaveData: leaveData } })
                break;

        }
    }
    const mettingColumns: IViewAllLinkColumns[] = [
        {
            header: "Title",
            fieldName: "title",
            type: 'default'
        },
        {
            header: "Meeting Date",
            fieldName: "meetingDate",
            type: 'date'

        },
        {
            header: "Meeting Time",
            fieldName: "meetingTime",
            type: 'time'
        }

    ]
    const empAbsentCoulmns: IViewAllLinkColumns[] = [
        {
            header: "Employee",
            fieldName: "fullName",
            type: 'default'
        },
        {
            header: "Status",
            fieldName: "status",
            type: 'status'

        },


    ]
    const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
    const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
    const LineData: any = [
        { data: pData, label: 'pv', yAxisKey: 'leftAxisId' },
        { data: uData, label: 'uv', yAxisKey: 'rightAxisId' },
    ]
    const handleMeetingClick = () => {
        updateOrRegisterComponent(WSHeaders.ScheduleMeetinglist, { url: '/home', isBackButton: true });
        navigate('/schedule/list')

    }
     const source = {
        url: "LeaveApply/search",
        page: 1,
        pageSize: 10000,
        filter: [{ "field": "Status", "operator": "eq", "value": "Pending" }],
        sort: [],
    };
    const transFormData = (data: any) => {
        if (data && data.length > 0) {
            data.map((item: any) => {
                item.leaveName = item?.leaveType?.name;
                item.employee = `${getFullName(item?.employee?.firstName, item?.employee?.lastName, item?.employee?.middleName)}`
            })

        }
        return data
    }
    return (
        <>
            <Grid item container spacing={2}>
                <Grid item sm={12} style={{ padding: 20 }} xs={12}>
                    <Typography className="header-text">Dashboard </Typography>
                </Grid>
            </Grid>
            {userType !== 'admin' && <Grid item container spacing={2}>
                <Grid item sm={12}>
                    {/* <br></br> */}
                    <Grid item container spacing={2} flex={1}>
                        <Grid item sm={8} xs={12}>
                            <Grid item container spacing={2} flex={1}>
                                {leaveData && leaveData?.map((item: IUserEmployeeLeave, index: number) => (
                                    (< Grid item sm={4}  xs={12}>
                                        <WSCard data={item} id={`ws-small-card${index}`} title={item?.type} icon={WSIcons.Annual} iconColor="green" text="Available" value={`${item?.availableLeave} Days`} actionButtonText="Apply" onClick={(e) => handlyAppy(item?.type?.toLowerCase(), item?.id)} />
                                    </Grid>)
                                ))}

                                <Grid item sm={12} mt={2}  xs={12}>
                                    <WSViewAllLinkGrid title="Meeting" rowsCount={5} onClick={handleMeetingClick} id="Meeting" columns={mettingColumns} rows={userData?.meetings || userData?.meeting || []} />
                                </Grid>
                                <Grid item sm={12} mt={2}>
                                    {/* <br></br> */}
                                    {/* <WSViewAllLinkGrid title="Leave Requests" rowsCount={5} viewAllLink="/leave" id="laveRequests" columns={leaveColumns} rows={listData.leave} /> */}
                                  
                                </Grid>
                                <Grid item sm={12} mt={2}  xs={12}>
                                    {/* <br></br> */}
                                    <WSViewAllLinkGrid title={`Holidays (${moment.utc().format('MMMM')})`} rowsCount={5} viewAllLink="/holiday" id="dashboardHoliday" columns={columns} rows={listData.holiday} />
                                </Grid>

                            </Grid>

                        </Grid>
                        <Grid item sm={4} xs={12}>
                        <WSMainCard profileHeight={300} id="125" title="Profile" type="Rows" data={profileData} fields={rows} />
                        <br></br>
                            <WSCalander leaveDates={leaveCal} id="caldashboard" title="Calander" />
                           
                        

                        </Grid>
                    </Grid>
                </Grid>
            </Grid >}


            {userType === 'admin' && <Grid item container spacing={2}>
                <Grid item sm={12} xs={12}>
                    <Grid item container spacing={2} flex={1}>
                        <Grid item sm={8} xs={12}>
                        
                                < Grid item sm={12} xs={12}>
                                    <WSGoogleChart is3D={true} pieVisibility="Center" title={"Department Leaves Chart"} type="pie" data={listData?.departmentLeaves} />
                                </Grid>

                                <Grid item sm={12} mt={2} xs={12}>
                                    <WSViewAllLinkGrid title="Meeting" rowsCount={5} onClick={handleMeetingClick} id="Meeting" columns={mettingColumns} rows={userData?.meetings || []} />
                                </Grid>
                               {IsSmallScreen() ? <Grid item sm={12} mt={2} xs={12}>
                                    {/* <WSViewAllLinkGrid title="Leave Requests" rowsCount={5} viewAllLink="/leave/actions" id="laveRequests" columns={leaveColumns} rows={listData.pendingLeave} /> */}
                                    <WSMobViewDataGrid
                                        id="leaveapprovalGrid"
                                        title="Leave Requested (Pending)"
                                        columns={leaveColumns}
                                        source={source}
                                        transFormData={transFormData}
                                    /> 
                                </Grid>:<Grid item sm={12} mt={2} xs={12}>
                                    {/* <WSViewAllLinkGrid title="Leave Requests" rowsCount={5} viewAllLink="/leave/actions" id="laveRequests" columns={leaveColumns} rows={listData.pendingLeave} /> */}
                                    <WSDataGrid
                                        id="leaveapprovalGrid"
                                        title="Leave Requested"
                                        columns={leaveColumns}
                                        source={source}
                                        transFormData={transFormData}
                                    /> 
                                </Grid>}

                                <Grid item sm={12} mt={2} xs={12}>
                                    <WSViewAllLinkGrid title={`Holidays (${moment.utc().format('MMMM')})`} rowsCount={5} viewAllLink="/holiday" id="dashboardHoliday" columns={columns} rows={listData.holiday} />
                                </Grid>
                            </Grid>
                      
                        <Grid item sm={4} xs={12}>
                        <WSMainCard profileHeight={300} id="125" title="Profile" type="Rows" data={profileData} fields={rows} />
                        <Grid item sm={12} mt={4} xs={12}>
                                <WSViewAllLinkGrid title="Absent Today" rowsCount={500} id="absentToday" columns={empAbsentCoulmns} hideViewAllLink={true} rows={listData?.leaveByEmployee || []} />
                            </Grid>
                        {/* <WSBigCalendar toolbar={false} dialog={false} events={[]} id="caldashboard" title="Calander" /> */}
                        <br></br>
                        <WSCalander leaveDates={leaveCal} id="caldashboard" title="Calander" />
                            
                         
                         
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >}

        </>
    );
});
const styleForCard: { wsCard: React.CSSProperties } = {
    wsCard: {
        backgroundImage: 'linear-gradient(to right, #ffffff, #e6e6e6)', // Example gradient
        borderRadius: '8px',
        width: '100%',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        border: '1px solid #e6e6e6',
    },
};
// src/store/types.ts

// Define action types
export const DATA_COMPONENT_RESET = 'DATA_COMPONENT_RESET';
export const DATA_COMPONENT_STORE = 'DATA_COMPONENT_STORE';
export const DATA_COMPONENT_UPDATE = 'DATA_COMPONENT_UPDATE';
// Define state shape
export interface AppState {
  components: {
    [componentId: string]: {
      data: any;
      isLoading: boolean;
      error?: string | null;
    };
  };
}

// Define action interfaces
interface DataComponentResetAction {
  type: typeof DATA_COMPONENT_RESET;
  payload: {
    componentId: string;
  };
}

interface DataComponentStoreAction {
  type: typeof DATA_COMPONENT_STORE;
  payload: {
    componentId: string;
    data: any;
  };
}
interface DataComponentUpdateAction {
  type: typeof DATA_COMPONENT_UPDATE;
  payload: {
    componentId: string;
    data: any;
  };
}
export type AppActionTypes = DataComponentResetAction | DataComponentStoreAction |DataComponentUpdateAction;

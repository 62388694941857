
/**
 * Component   : RolesManagement
 * Description : Adding a new Role
 */
import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { fetchRolesById, IRoleMain, IsSmallScreen, MasterPages } from "../../commonFunctions/CommonFunctions";
import WSButton from "../../Components/Button/WSButton";
import WSRolesNew from "../../Components/Roles/WSRoleNew";
import WSSelectField from "../../Components/Select/WSSelect";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import clientConfig from '../../Config/clientConfig.json';
import config from '../../Config/config.json';
import { Header } from "../Header/Header";
import { getPermissions } from "../../commonFunctions/PermissionManger";
import PermissionDenied from "../../commonFunctions/PermissionDenied";
export const RolesManagement: React.FC<any> = React.memo((props) => {
    const { showMessage } = useSnackbar();
    const { register, handleSubmit, control, formState: { isSubmitSuccessful }, setValue, watch, reset } = useForm();
    const navigate = useNavigate();
    const params = useParams();
    const id = params?.id;
    const [rolesData, setRolesData] = useState<any>(null);
    const [role, setRole] = useState<any>(null);
    const [roles, setRoles] = useState<any>(null);
    const isMobile=IsSmallScreen()
    const { view, edit } = getPermissions(MasterPages.Roles, IRoleMain.Masters);
    useEffect(() => {
        const getRoleById = async (id: string) => {
            try {
                const response = await fetchRolesById(id);
                if (response) {
                    setRoles(response);
                    setRole({ id: response?.id, name: response?.name })
                }
            } catch (error) {
                console.error(error);
            }
        };
        getRoleById('1001');
    }, []);
    const handleCancelClick = () => {
        navigate("/");
    };

    /**
     * Method for Add/Update the data
     * @param data 
     */
    const onSubmit = async (data: any) => {
        try {
            let newJson: any = {
                id: role?.id,
                name: role?.name,
                roles: rolesData,
            };

            const response = await fetch(`${config.serverUrl}update-json`, {  // Change the URL here
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newJson),
            });

            if (!response.ok) {
                throw new Error('Failed to update admin data');
            }

            showMessage('Admin data updated successfully', 'success');
        } catch (e: any) {
            console.error('Failed to update admin data:', e);
        }
    };
    const handleDataChange = (data: any) => {
        setRolesData(data);
    };

    const handleChangeRole = async (event: any) => {
        setRoles({ roles: [] })
        const data = await fetchRolesById(event?.target.value) //await fetchAdminData(event?.target.value);

        setTimeout(() => {
            setRoles(data)
        }, 1000);

        setRole({ id: data?.id, name: data?.name })
    }
    return (
        <>
        <Header title="Edit Role" url="/home" type="simple" isDivider={true} isBackButton={true}/>
        {view ? <Paper elevation={1} style={{ paddingLeft: 10, paddingRight: 0, marginTop: 0, paddingBottom: 20, border: '1px solid #ccc', borderRadius: '8px' }}>
                {/* <Grid item container spacing={1}>
                    <Grid item sm={12} xs={12}>
                        <Typography variant="h6" style={{ fontSize: '1.15rem' }} component="h6" fontWeight={'bold'} color={'#414A4C'}>
                            {"Edit Role"}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Divider sx={{ marginY: 2 }} />
                    </Grid>
                </Grid> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                <Grid xs={12} className="modal-input" container spacing={2} style={{ marginTop: 10 }}>
                        {/* <Grid item sm={6}>
                            <WSTextField control={control} id="roleName" {...register('roleName')} name="roleName" rules={{ required: true }} label="Role Name *"></WSTextField>
                        </Grid> */}
                        <Grid item sm={6} xs={6}>
                            <WSSelectField control={control} options={clientConfig.rolesConfig.staticOptionData} defaultValue={'1001'} id="roleName" {...register('roleName')} name="roleName" rules={{ required: true }} label="Role Name *" onChange={handleChangeRole} isMobile={isMobile}></WSSelectField>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Typography variant="subtitle2" fontWeight={'bold'} color={'GrayText'}>Manage Permissions</Typography>
                        </Grid>
                        <Grid item sm={12} xs={12} >
                            {/* {<WSRoles disabled={role?.id === '1001' ? true : false} reset={reset} roles={roles?.roles} control={control} onSubmit={handleDataChange}  setValue={setValue} />} */}
                            <WSRolesNew isMobile={isMobile} disabled={role?.id === '1001' || edit===false ? true : false} id={roles?.id} name={roles?.name} roles={roles?.roles} control={control} onSubmit={handleDataChange} setValue={setValue} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                        <Grid item sx={{ marginRight: 1 }}>
                            <WSButton className={`ws-default ${role?.id === '1001' ? ' disabled' : ''}`} disabled={role?.id === '1001' || edit===false ? true : false} id='submit' label={"Save"} type="submit" size="medium" loading={isSubmitSuccessful} />
                        </Grid>
                        <Grid item sx={{ marginRight: 2 }}>
                            <WSButton id='cancel' label={"Cancel"} type="button" onClick={handleCancelClick} size="medium" className="ws-cancel" />
                        </Grid>
                    </Grid>
                </form>
            </Paper>:<PermissionDenied/>}
        </>
    );
});

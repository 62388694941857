import { Grid, Paper } from "@mui/material";
import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { callAPI, getLocalValue, LoacalVariables, Method } from "../../commonFunctions/CommonFunctions";
import WSButton from "../../Components/Button/WSButton";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import WSTextField, { TextType } from "../../Components/TextBox/WSTextBox";
import { RootState } from "../../StateManagement/store";
import { Header } from "../Header/Header";
import { IChangePassword } from "../Interfaces/Interfaces";

export const ChangePassword: React.FC<any> = React.memo((props) => {
  const { register, control, handleSubmit, formState: { isSubmitSuccessful } } = useForm();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const userType: any = getLocalValue(LoacalVariables.UserType);
  // Watch password and confirm password fields
  const password = useWatch({ control, name: "password" });
  const confirmPassword = useWatch({ control, name: "confirmpassword" });
  const { data, error } = useSelector((state: RootState) => ({
    data: state.components?.['changePassword']?.data,

    error: state.components?.['changePassword']?.error,
  }));
  const url = userType === 'admin' ? 'User/ChangePassword' : 'EmpLogin/ChangePassword'
  const oldPassword = data?.oldPassword

  const handleData = async (data: any) => {
    try {
      let request: IChangePassword = {
        userId: getLocalValue(LoacalVariables.UserId),
        currentPassword: oldPassword ? oldPassword : data?.oldPassword,
        newPassword: data.password,
      };
      const response = await callAPI(url, Method.POST, request);
      if (response) {
        showMessage(response?.message, response.success === true ? "success" : "error");
      }
    } catch (error) {

    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  // Determine if passwords match for real-time feedback
  const passwordsMatch = password === confirmPassword;


  return (
  <>
    <Header title={'Change Password'} type="simple" url="/" isBackButton={true} isDivider={true} />
    
    <Paper elevation={1} style={{ paddingLeft: 10, paddingRight: 0, marginTop: 0, paddingBottom: 20, border: '1px solid #ccc', borderRadius: '8px' }}>
      {/* <Grid item container spacing={1}>
        <Grid item sm={12}>
          <Typography className="header-text">
            {"Change Password"}
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Divider sx={{ marginY: 2 }} />
        </Grid>
      </Grid> */}
      <Grid item xs={12} sm={6} md={4}>
        <form onSubmit={handleSubmit(handleData)}>
        <Grid xs={12} className="modal-input" container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12}>
              <WSTextField
                control={control}
                id="oldPassword"
                {...register("oldPassword", { required: "Old Password is required" })}
                name="oldPassword"
                label="Old Password *"
                type={TextType.Password}
                maxLength={40}
              />

            </Grid>
            <Grid item xs={12}>
              <WSTextField
                control={control}
                id="password"
                {...register("password", { required: "New Password is required" })}
                name="password"
                label="New Password *"
                type={TextType.Password}
                maxLength={40}
                minLength={5}
              />

            </Grid>
            <Grid item xs={12}>
              <WSTextField
                control={control}
                maxLength={40}
                id="confirmpassword"
                {...register("confirmpassword", {
                  required: "Please confirm your password",
                  validate: {
                    matchesPreviousPassword: value => {
                      return passwordsMatch || "Passwords do not match";
                    }
                  }
                })}
                name="confirmpassword"
                label="Confirm Password *"
                type={TextType.Password}
              />

              {!passwordsMatch && confirmPassword && <div style={{ color: '#d32f2f', fontSize: '0.75rem' }}>Passwords do not match</div>}
            </Grid>
            <Grid item container spacing={2} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
              <Grid item>
                <WSButton
                  id="cancel"
                  label={"Cancel"}
                  onClick={handleCancel}
                  type="button"
                  size="medium"
                  className="ws-cancel"
                />
              </Grid>
              <Grid item>
                <WSButton
                  id="submit"
                  label={"Change"}
                  type="submit"
                  size="medium"
                  loading={isSubmitSuccessful}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Paper>
    </>
    );
});

import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps, Theme } from '@mui/material';

interface ControlledButtonProps {
  id: string;
  label: string;
  className?: string;
  variant?: "text" | "outlined" | "contained";
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  type?: 'submit' | 'button';
  loading?: boolean;  // Added loading prop
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  onClick?: () => void;
  startIcon?: any
  endIcon?: any;
  sx?: SxProps<Theme>
}

const WSButton: React.FC<ControlledButtonProps> = ({ id, label, className, variant, size, disabled, type, loading, onClick, color, startIcon, endIcon, sx }) => {
  return (<>
    {!color && <Button
      id={id}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled || loading}
      className={className ? className : 'ws-default'}
      startIcon={startIcon}
      endIcon={endIcon}
      style={{ fontSize: size === 'medium' ? '0.875rem' : size === 'small' ? '0.700rem' : '1rem' }}
      size={size}
      sx={sx}
      variant={variant}
      onClick={onClick}
    >   {loading ? <CircularProgress size={24} color="inherit" /> : label}
    </Button>}
    {color &&
      <Button
        id={id}
        type={type === 'submit' ? 'submit' : 'button'}
        disabled={disabled || loading}
        className={className ? className : ''}
        size={size}
        style={{ fontSize: size === 'medium' ? '0.875rem' : size === 'small' ? '0.700rem' : '1rem' }}
        variant={variant}
        startIcon={startIcon}
        sx={sx}
        endIcon={endIcon}

        onClick={onClick}
      >   {loading ? <CircularProgress size={24} color={color} /> : label}
      </Button>
    }
  </>
  );
};

export default WSButton;

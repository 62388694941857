import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
interface ThreeDotMenuProps {
  items: string[];
  onAction: (action: string) => void;
  align?: 'horizontal' | 'vertical';
}

const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({ items, onAction, align = 'vertical' }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action: string) => {
    onAction(action);
    handleClose();
  };




  return (
    <>
      <IconButton onClick={handleClick} className={`icon-button-${align}`} >
        {align === 'horizontal' ? <MoreHorizIcon /> : <MoreVertIcon />}
      </IconButton>
      <Menu style={{ marginLeft: 22 }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem key={item} onClick={() => handleMenuItemClick(item)} sx={{ height: 30,fontSize:'0.875rem' }}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ThreeDotMenu;

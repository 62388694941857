import { Alert, Grid, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Control, FieldErrors, UseFormRegister, UseFormReset } from "react-hook-form";
import { callAPI, IsSmallScreen, Method } from "../../commonFunctions/CommonFunctions";
import WSSelectField from "../../Components/Select/WSSelect";
import WSTextField from "../../Components/TextBox/WSTextBox";
import config from '../../Config/clientConfig.json';
interface EditWorkLocationProps {
  control: Control<any>;
  errors: FieldErrors;
  register: UseFormRegister<any>;
  id: any;
  reset: UseFormReset<any>;
  workLocationId?: any;
  setValue: any;
  addressId?: any;

}
const EditWorkLocation: React.FC<EditWorkLocationProps> = ({ control, errors, register, id, reset, workLocationId, addressId, setValue }) => {
  useEffect(() => () => {
    reset()
  }, [id, reset])
  const [selectedState, setSelectedState] = useState<string>("");
  const isMobile=IsSmallScreen()
  useEffect(() => {
    /**
     * Edit Function
     * @param id 
     * @param addressid 
     */
    async function getWorkLocation(id: any) {
      try {
        const response: any = await callAPI('worklocation', Method.GET, {}, id);
        if (response && response?.data) {
          const address = response.data?.addresses[0] || {};
          setValue("worklocationname", response.data.workLocationName);
          setValue("addressId", address.id);
          setValue("address", address.addressLine1);
          setValue("addressline2", address.addressLine2);
          setValue("selectstate", address.state);
          setSelectedState(address.state)
          setValue("district", address.city);
          setValue("postalcode", address.pincode);
          setValue("id", response.data.id);
        }
      }

      catch (error) {
        console.log("failed to retrieve data", error);

      }

    }
    if (workLocationId) {
      getWorkLocation(workLocationId);

    }
  }, [workLocationId, setValue]);


  const handleStateChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSelectedState(event.target.value as string);
  };

  return (
    // <Box sx={{ padding: "20px", position: "relative", margin: "auto", marginTop: "50px" }}>
<>
      <Grid className="modal-input" container spacing={2}>
        <Grid item xs={12}>
          <WSTextField
            control={control}
            id="worklocationname"
            {...register("worklocationname")}
            rules={{ required: true }}
            name="worklocationname"
            label="Work location Name *"
            //labelType="Normal"
          />
          {errors.description && (
            <Typography variant="body2" color="error">
              Work Location Name is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <WSTextField
            control={control}
            id="address"
            {...register("address")}
            rules={{ required: true }}
            name="address"
            label="Address line1 *"
            //labelType="Normal"
          />
          {errors.description && (
            <Typography variant="body2" color="error">
              Address is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <WSTextField
            control={control}
            id="addressline2"
            {...register("addressline2")}
            rules={{ required: false }}
            name="addressline2"
            label="Address line2"
            //labelType="Normal"
          />
          {errors.description && (
            <Typography variant="body2" color="error">
              Address is required
            </Typography>
          )}
        </Grid>
        <Grid item container xs={12} sm={4}>
          <WSSelectField
            options={config.stateConfig.staticOptionData}
            rules={{ required: true }}
            {...register("selectstate")}
            id="select"
            control={control}
            defaultValue={"0"}
            label="State *"
            name="selectstate"
            isMobile={isMobile}
            //labelType="Normal"
            onChange={handleStateChange}
          ></WSSelectField>
          {errors.description && (
            <Typography variant="body2" color="error">
              State is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <WSTextField
            control={control}
            id="district"
            {...register("district")}
            rules={{ required: true }}
            name="district"
            label="District *"
            //labelType="Normal"
          />
          {errors.description && (
            <Typography variant="body2" color="error">
              District is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <WSTextField
            control={control}
            id="postalcode"
            {...register("postalcode")}
            rules={{ required: true }}
            name="postalcode"
            label="PIN *"
            //labelType="Normal"
            maxLength={6}
          />
          {errors.description && (
            <Typography variant="body2" color="error">
              Postal code is required
            </Typography>
          )}
        </Grid>
        <Grid style={{ display: "none" }}>
          <WSTextField
            control={control}
            id="id"
            {...register("id")}
            rules={{ required: false }}
            name="id"
            label=""
          // defaultValue={designationId}
          />
        </Grid>
        <Grid style={{ display: "none" }}>
          <WSTextField
            control={control}
            id="addressId"
            {...register("addressId")}
            rules={{ required: false }}
            name="id"
            label=""
          // defaultValue={designationId}
          />
        </Grid>
      </Grid>
      {selectedState === "Kerala" && (
        <Alert severity="info" sx={{ mt: 2 }}>
          Labour Welfare Fund is applicable for Kerala. If you've not configured it yet, configure it in Settings &gt; Statutory Components.
        </Alert>
      )}

</>
    // </Box>
  )
}
export default EditWorkLocation;
import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { convertTo12HourFormat, IRoleMain, IsSmallScreen, MasterPages } from "../../../commonFunctions/CommonFunctions";
import { WSHeaders } from "../../../commonFunctions/HeaderConfig/HeaderConfig";
import WSDataGrid, { IColumns } from "../../../Components/DataGrid/WSDataGrid";
import WSMobViewDataGrid from "../../../Components/DataGrid/WSMobViewDataGrid";
import { IRows } from "../../../Components/Filter/WSFilterComponent";
import { Header } from "../../Header/Header";
import { getPermissions } from "../../../commonFunctions/PermissionManger";
import PermissionDenied from "../../../commonFunctions/PermissionDenied";

export const ScheduleMeetingList: React.FC<any> = React.memo(
    (props) => {
        const navigate = useNavigate();
        const { view, exports, add, edit } = getPermissions(MasterPages.Meetings, IRoleMain.Masters);
        const source = {
            url: "meeting/search",
            page: 1,
            pageSize: 10,
            filter: [],
            sort: [],
        };

        /**
         * Edit function
         * @param action 
         * @param row 
         */
        const handleAction = (action: string, row: any) => {
            if (action === "Edit") {
                navigate(`/schedule/edit/${row?.id}`)
            }
        };

        const columns: IColumns[] = [
            {
                header: "Meeting Title",
                fieldName: "title",
                tooltip: true,
                type: 'default',
            },
            {
                header: "Meeting Date",
                fieldName: "meetingDate",
                type: 'date'
            },
            {
                header: "Meeting Time",
                fieldName: "time",
                type: 'default'
            },
            {
                header: "Actions",
                fieldName: "actions",
                type: "action",
                actions: [edit ? "Edit" : undefined],
                actionCallback: (action, row) => handleAction(action, row),
            },
        ];



        const filterRows: IRows[] = [
            {
                label: "Title",
                fieldName: "Title",
                type: "text",
                operator: 'like'
            },

        ];
        const transFormData = (data: any) => {
            data && data?.map((item: any) => {
                item.time = convertTo12HourFormat(item?.meetingTime)
            })
            return data
        }
        const handleClick = () => {
            navigate('/schedule/add')
        }
        return (
            <>  <Header id={WSHeaders.ScheduleMeetinglist} title="Meeting Schedules" type="simple" isDivider={true} />
                 {view ? <Grid container spacing={2}>
                    {!IsSmallScreen() ? <Grid item xs={12}>
                        <WSDataGrid
                            transFormData={transFormData}
                            id="meeting-management-list"
                            title="Meetings"
                            filterFields={filterRows}

                            columns={columns}
                            source={source}
                            exportCsv={exports}
                            addButton={add ? { label: '', color: 'secondary' } : undefined}
                            onAddButtonClick={handleClick}
                        />
                    </Grid> : <Grid item xs={12}>
                        <WSMobViewDataGrid
                            transFormData={transFormData}
                            id="meeting-management-list"
                            title="Meetings"
                            filterFields={filterRows}

                            columns={columns}
                            source={source}
                            exportCsv={exports}
                            addButton={add ? { label: '', color: 'secondary' } : undefined}
                            onAddButtonClick={handleClick}
                        />
                    </Grid>}
                </Grid>:<PermissionDenied />}
            </>
        );
    }
);





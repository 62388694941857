import React, { forwardRef, useImperativeHandle } from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Box, Grid } from '@mui/material';
import WSButton from '../Button/WSButton';

interface WSStepperProps {
    steps: string[];
    stepComponents: React.ReactNode[];
    initialValues?: any;
    onSubmitValue: (values: any, stepInfo: string, stepIndex?: number) => void;
    icons?: { [index: string]: React.ReactElement };
    handleSubmit: any;
    trigger: any;
    loading?: boolean;
    isSuccess?: boolean;
}

interface WSStepperMethods {
    handleNext: () => Promise<void>;
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(95deg,#367fa9 0%,#367fa9 50%,#367fa9 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(95deg,#ddd 0%,#ddd 50%,#ddd 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#ddd',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'transform 0.3s ease-out',

    '&:hover': {
        transform: 'scale(1.1)',
    },

    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient(136deg, #367fa9 0%, #367fa9 50%, #367fa9 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient(136deg, #ddd 0%, #ddd 50%, #ddd 100%)',
    }),
}));

function ColorlibStepIcon(props: StepIconProps & { icons?: { [index: string]: React.ReactElement }, onClick: () => void }) {
    const { active, completed, className, icons, onClick } = props;

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className} onClick={onClick}>
            {icons && icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const WSStepper = forwardRef<WSStepperMethods, WSStepperProps>(({
    steps,
    stepComponents,
    initialValues,
    onSubmitValue,
    icons,
    handleSubmit,
    trigger,
    loading,
    isSuccess,
}, ref) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [formValues, setFormValues] = React.useState(initialValues);

    const handleNext = async () => {
        const valid = await trigger();
        if (valid) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    useImperativeHandle(ref, () => ({
        handleNext,
    }));

    const handlePrev = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepClick = (index: number) => {
        setActiveStep(index);
    };

    const onSubmit = (values: any) => {
        onSubmitValue(values, steps[activeStep], activeStep);
        // if (activeStep !== steps.length - 1 && isSuccess) {
        //     handleNext();
        // }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues((prevValues: any) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack sx={{ width: '100%', fontSize: '0.875rem' }} spacing={2} justifyContent={'center'}>
                <Stepper sx={{ width: '100%' }} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={(props) => (
                                <ColorlibStepIcon {...props} icons={icons} onClick={() => handleStepClick(index)} />
                            )}>
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                {/* display="flex" justifyContent="center" alignItems="center" p={8} sx={{ width: '99%', margin: '0 auto' }} */}
                    <Grid item container spacing={2} style={{marginTop:20,marginLeft:10,paddingRight:40 ,minHeight:'16rem'}} justifyContent={'center'}>
                        <Grid item sm={12}>
                        {React.cloneElement(stepComponents[activeStep] as React.ReactElement, {
                            onChange: handleInputChange,
                            values: formValues,
                        })}
                    </Grid>
                    </Grid>
                </div>
                <Stack direction="row" spacing={2} justifyContent="center">
                    <Button variant="contained" onClick={handlePrev} disabled={activeStep === 0}>
                        Prev
                    </Button>
                    <WSButton id="btnsave" type='submit' label={activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'} loading={loading} />
                </Stack>
            </Stack>
        </form>
    );
});

export default WSStepper;

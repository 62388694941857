import { Alert, Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI, clearRequest, Method } from "../../commonFunctions/CommonFunctions";
import WSAutocompleteField from "../../Components/AutoComplete/AutoComplete";
import WSButton from "../../Components/Button/WSButton";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import WSTextField from "../../Components/TextBox/WSTextBox";
import { Header } from "../Header/Header";

const MobDesignation: React.FC<any> = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue, watch, reset } = useForm();
    const params = useParams();
    const id = params?.id;
    const navigate = useNavigate();
    useEffect(() => () => {
        reset()
    }, [id, reset])
    const [selectedState, setSelectedState] = useState<string>("");
    const { showMessage } = useSnackbar();
    useEffect(() => {
        reset();
      }, [id, reset]);
    
      useEffect(() => {
        /**
         * edit function
         * @param id 
         */
        async function getDesignation(id: any) {
          try {
            const response: any = await callAPI("designation", Method.GET, {}, id);
            if (response?.data) {
              setValue("designationName", response.data.designationName);
              setValue("workLocation", response.data.workLocation.id);
              setValue("designationId", response.data.id);
            }
    
          } catch (error) {
            console.log("failed to retrieve data", error);
    
          }
        }
        if (id) {
          getDesignation(id);
    
        }
      }, [id]);
    
      const source = {
        url: "worklocation/search",
        page: 1,
        pageSize: 10000,
        filter: [],
        sort: [],
      };
      const transFormData = (data: any) => {
        let newData: Array<any> = [];
        data.map((item: any) => {
          newData.push({ label: item.workLocationName, value: item.id });
        });
        return newData;
      };
    

    const onSubmit = async (data: any) => {
        try {
            let request: any = {
              designationName: data.designationName,
              workLocationId: data.workLocation,
            };
            let newRequest: any = clearRequest(request);
    
            if (data?.designationId && data?.designationId !== "") {
              const response = await callAPI(
                "designation/" + data.designationId,
                Method.PUT,
                newRequest
              );
              showMessage(
                response?.message,
                response?.success === true ? "success" : "error"
              );
              navigate('/designations')
            } else {
              const response = await callAPI(
                "designation",
                Method.POST,
                newRequest
              );

              showMessage(
                response?.message,
                response?.success === true ? "success" : "error"
              );
              navigate('/designations')
            }
          } catch (error) {
            console.error("Failed to create designation", error);
          }

    };
    const handleCancelClick = () => {
        navigate('/designations')
    }
    return (
        // <Box sx={{ padding: "20px", position: "relative", margin: "auto", marginTop: "50px" }}>
        <> <Header title={id?'Edit Designation':'Add Designation'} type="simple" url="/designations" isBackButton={true} isDivider={true} />
       
            <Paper elevation={1} style={{ paddingLeft: 10, paddingRight: 10, marginTop: 0, paddingBottom: 20, border: '1px solid #ccc', borderRadius: '8px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid xs={12} className="modal-input" container spacing={2} style={{ marginTop: 10 }}>

                        <Grid item xs={12}>
                            <WSTextField
                                control={control}
                                id="designationName"
                                {...register("designationName")}
                                rules={{ required: true }}
                                name="designationName"
                                label="Designation Name *"

                            />
                            {errors.description && (
                                <Typography variant="body2" color="error">
                                    Designation Name is required
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>

                            <WSAutocompleteField
                                id="workLocation"
                                {...register("workLocation")}
                                label="Work Location"
                                source={source}
                                transFormData={transFormData}
                                control={control}
                                rules={{ required: true }}
                            />

                        </Grid>
                        <Grid style={{ display: "none" }}>
                            <WSTextField
                                control={control}
                                id="designationId"
                                {...register("designationId")}
                                rules={{ required: false }}
                                name="designationId"
                                label=""
                            />
                        </Grid>
   
                    </Grid>
                    <Grid item container spacing={1} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                        <Grid item sx={{ marginRight: 1 }} >
                            <WSButton id='submit' label={"Save"} type="submit" size="small" />
                        </Grid>
                        <Grid item sx={{ marginRight: 2 }}>
                            <WSButton id='cancel' label={"Cancel"} type="button" onClick={handleCancelClick} size="small" className="ws-cancel" />
                        </Grid>
                    </Grid>
                </form>
                {selectedState === "Kerala" && (
                    <Alert severity="info" sx={{ mt: 2 }}>
                        Labour Welfare Fund is applicable for Kerala. If you've not configured it yet, configure it in Settings &gt; Statutory Components.
                    </Alert>
                )}
            </Paper >
        </>

        // </Box>
    )
}
export default MobDesignation;
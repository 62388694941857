import { Divider } from '@mui/material';
import React, { useState, useRef, ChangeEvent, KeyboardEvent } from 'react';

interface OTPInputProps {
  onSubmit: (otp: string) => void;
  onResend: () => void;
  numberOfColunms?: number
}

const OTPInput: React.FC<OTPInputProps> = ({ onSubmit, onResend,numberOfColunms=6 }) => {
  const [otp, setOtp] = useState<string[]>(Array(numberOfColunms).fill(''));
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleChange = (index: number, value: string) => {
    if (value.match(/^[0-9]{0,1}$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus the next input field
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !otp[index]) {
      // Clear the current input and focus on the previous input
      if (index > 0) {
        const newOtp = [...otp];
        newOtp[index - 1] = '';
        setOtp(newOtp);
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData.getData('Text');
    if (pasteData.length === 6 && /^[0-9]+$/.test(pasteData)) {
      const newOtp = pasteData.split('');
      setOtp(newOtp);
      newOtp.forEach((_, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index].focus();
        }
      });
    }
    event.preventDefault();
  };

  const handleSubmit = () => {
    onSubmit(otp.join('')); // Trigger the submission logic
  };

  const handleResend = () => {
    onResend();
    setOtp(Array(numberOfColunms).fill(''));
    inputRefs.current[0].focus();
  };

  return (
    <div style={styles.container}>
      <div style={styles.otpContainer}>
        {otp.map((digit, index) => (
          <input
            key={index}
            type="text"
            value={digit}
            maxLength={1}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(index, e.target.value)}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            ref={(el) => (inputRefs.current[index] = el!)}
            style={styles.otpInput}
          />
        ))}
          <Divider style={{marginTop:20}}/>
      </div>
      
    
      <div style={styles.buttonContainer}>
        <button type="button" onClick={handleResend} style={styles.resendButton}>
          Resend OTP
        </button>
        <button type="button" onClick={handleSubmit} style={styles.submitButton}>
          Submit
        </button>
      </div>
    </div>
  );
};

// Styles for the component
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    
   // backgroundColor: '#f9f9f9',
  },
  otpContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  otpInput: {
    width: '45px',
    height: '50px',
    fontSize: '24px',
    textAlign: 'center',
    borderRadius: '6px',
    border: '1px solid #ddd',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '0 5px',
    transition: 'all 0.2s ease-in-out',
  },
  buttonContainer: {
    marginTop:25,
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
  },
  resendButton: {
    padding: '10px 15px',
    backgroundColor: '#6c757d',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.875rem',
    transition: 'background-color 0.3s ease',
  },
  submitButton: {
    padding: '10px 10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.875rem',
    transition: 'background-color 0.3s ease',
  },
};

export default OTPInput;

import React, { useState } from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import WSButton from './Button/WSButton';
import { ReactElementProps } from 'react-imask';

interface ControlledCardProps {
    id: string;
    title: string;
    icon?: React.ElementType; // Icon component
    iconColor?: string;
    text?: string;
    value?: string;
    actionButtonText?: string;
    type?: 'Custom' | 'Normal';
    onClick?: (type?: string) => void;
    customContent?: React.ReactNode; // Allow custom content to be passed in
    style?: React.CSSProperties;
    data?: any;
}

const WSCard: React.FC<ControlledCardProps> = ({
    id,
    title,
    icon: Icon, // Renaming for clarity
    text,
    value,
    actionButtonText,
    type,
    onClick,
    customContent,
    iconColor,
    style,
    data
}) => {
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const renderHeader = () => (
        <>
            <CardHeader
                title={
                    <Grid container spacing={1} alignItems="center">
                        <Grid item sm={1} style={{ paddingLeft: 0, marginLeft: 0 }}>
                            {Icon && <Icon style={{ color: iconColor ? iconColor : 'black', fontSize: '1.25rem' }} />} {/* Conditionally render the icon */}
                        </Grid>
                        <Grid item sm={9} xs={9}>
                            <Typography variant="h6" style={{ marginBottom: 2, fontWeight: 'bold', paddingLeft: 2, fontSize: '0.975rem' }}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item sm={2} xs={2} container style={{ marginLeft: 0, paddingLeft: 0 }}>
                            <Box
                                sx={{
                                    backgroundColor: '#C35214', // Background color
                                    borderRadius: '50%', // Makes it fully rounded
                                    width: '30px', // Set width for the circle
                                    height: '30px', // Set height for the circle
                                    display: 'flex', // Flexbox to center content
                                    alignItems: 'center', // Vertical alignment
                                    justifyContent: 'center', // Horizontal alignment
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '0.75rem', // Adjust font size if needed
                                        color: 'white'
                                    }}
                                >
                                    {data?.totalLeave}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                }
            />
            {/* <Divider /> */}
        </>
    );

    const renderContent = () => {
        switch (type) {
            case 'Custom':
                return customContent;
            case "Normal":
                return (
                    <>
                        {text && <Typography variant="body1" color={'GrayText'} style={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                            {text}
                        </Typography>}
                        {value && (
                            <Typography variant="h5" color={'#00008B'} sx={{ mt: 1, fontWeight: 'bold', fontSize: '0.875rem' }}>
                                {value}
                            </Typography>
                        )}
                    </>
                );
            default:
                return (
                    <>
                        {text && <Typography variant="body1" color={'GrayText'} style={{ fontWeight: 'bold', fontSize: '0.95rem' }}>
                            {text}
                        </Typography>}
                        {value && (
                            <Typography variant="h5" color={'#00008B'} sx={{ mt: 1, fontWeight: 'bold', fontSize: '0.875rem' }}>
                                {value}
                            </Typography>
                        )}
                    </>
                );
        }
    };

    return (
        <Paper elevation={1} variant="elevation" sx={{   borderRadius:'14px',}}>
            <Box>
                <Card
                    variant="outlined"
                    sx={{
                        ...style,
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Add transition for smooth effect
                        borderRadius:'14px',
                        '&:hover': {
                            transform: 'scale(1.03)', // Slightly enlarge on hover
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', // Add shadow on hover
                            
                        },
                    }}
                >
                    {renderHeader()}
                    <CardContent sx={{ paddingTop: 0, height: type === 'Normal' ? screenHeight : '' }}>{renderContent()}</CardContent>
                    <CardActions sx={{ paddingTop: 0 }}>
                        {actionButtonText && <Grid container justifyContent="end">
                            <WSButton id="btn" label={actionButtonText || 'Apply'} size="small" color='info' variant='outlined' onClick={onClick} />
                        </Grid>}
                    </CardActions>
                </Card>
            </Box>
        </Paper>
    );
};

export default WSCard;

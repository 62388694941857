import React from 'react';
import { Grid, Typography, Box, Link, Paper, Divider, TableCell } from '@mui/material';
import dayjs from 'dayjs';
import { WSStatus } from '../Status/WSStatus';
import ThreeDotMenu from '../ThreeDotMenu/ThreeDotMenu';
import { convertTo12HourFormat } from '../../commonFunctions/CommonFunctions';

interface IViewAllLinkGrid {
    id: string;
    rows: Array<any>;
    columns: Array<IViewAllLinkColumns>;
    rowsCount?: number; // to show a limited number of rows
    viewAllLink?: string; // the link to redirect to when "View All" is clicked
    title?: string;
    hideViewAllLink?: boolean
    onClick?:()=> void;
}

export interface IViewAllLinkColumns {
    header: string;
    fieldName: string;
    type?: 'default' | 'link' | { image: { suffix1?: string; suffix2?: string; } } | 'action' | 'status' | 'date' | 'time';
    tooltip?: boolean;
    formatDate?: string
    actions?: any;
    actionCallback?: (action: string, row: any) => void;
}

export const WSViewAllLinkGrid: React.FC<IViewAllLinkGrid> = ({
    id,
    rows,
    columns,
    rowsCount = rows.length, // Default to show all rows if rowsCount isn't provided
    viewAllLink = "#",// Default link is "#" if no viewAllLink is provided
    title,
    hideViewAllLink = false,
    onClick
}) => {
    const displayedRows = rows.slice(0, rowsCount); // Limit the number of rows displayed

    return (
        <Paper elevation={3} id={id} style={{ padding: 10, borderRadius: '8px' }}>
            {title && <Grid style={{ paddingLeft: 5, paddingTop: 5, height: '4rem' }} item sm={12} className='ws-data-grid ws-data-grid-title' ><Typography variant="h6" id="tableTitle" sx={{ fontSize: '1rem' }} >
                {title}
            </Typography>
                <Divider style={{ marginTop: 15 }} />
            </Grid>

            }
            {/* {!title && <Grid style={{ paddingLeft: 20, paddingTop: 25, height: '4rem' }} item sm={4} className='ws-data-grid ws-data-grid-title' >
            </Grid>
            } */}
            <Grid container spacing={2}>
                {/* Render Header */}
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {columns.map((column, colIndex) => (
                            <Grid item key={colIndex} xs={12 / columns.length}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '0.875rem', paddingLeft: 5 }}>
                                    {column.header}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <Divider style={{ marginTop: 1 }} />
                </Grid>

                {/* Render Rows */}
                {displayedRows.length > 0 ? (
                    displayedRows.map((row, rowIndex) => (
                        <Grid item xs={12} key={rowIndex}>
                            <Grid container spacing={2}>
                                {columns.map((column, colIndex) => (
                                    <Grid item key={colIndex} xs={12 / columns.length}>
                                        {renderCellContent(column, row[column.fieldName],row)}
                                    </Grid>
                                ))}
                            </Grid>
                            <Divider style={{ marginTop: 7 }} />
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12} key={'nodata'}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                <Typography variant="h6" style={{ padding: '20px', fontSize: '1.15rem' }} align="center">
                                    No Records to Show
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {/* Show "View All" link if rowsCount is less than the total number of rows */}
                {/* rowsCount < rows.length &&  */}
                {(
                    <Grid item xs={12}>
                        <Box textAlign="center" >
                            {!hideViewAllLink && <Link href={viewAllLink} onClick={onClick} underline="hover" color="primary" style={{ fontSize: '1rem' }}>
                                View All
                            </Link>
                            }
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

// Function to render cell content based on the column type
const renderCellContent = (column: IViewAllLinkColumns, cellValue: any,row?:any) => {
    if (column.type === 'date') {
        return (
            <Typography style={{ fontSize: '0.875rem', paddingLeft: 5 }} >
                {cellValue ? dayjs(cellValue).format(column.formatDate ? column.formatDate : 'DD/MM/YYYY') : 'N/A'}
            </Typography>
        );
    }   if (column.type === 'time') {
        return (
            <Typography style={{ fontSize: '0.875rem', paddingLeft: 5 }} >
                {convertTo12HourFormat(cellValue)}
            </Typography>
        );
    } else if (column.type === 'link') {
        return (
            <Typography style={{ fontSize: '0.875rem', paddingLeft: 5 }} >
                <a href={cellValue} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    {cellValue}
                </a>
            </Typography>
        );

    } else if (column.type === 'status') {
        return (
            <WSStatus value={cellValue} />
        );
    }   else if (column.type === 'action' && column?.actions?.length > 0) {
        return (
          
                <ThreeDotMenu
                    items={column.actions}
                    onAction={(action: string) =>
                        column.actionCallback?.(action, row)
                    }
                />
         
        );
    }
    else if (typeof column.type === 'object' && 'image' in column.type) {
        const { suffix1, suffix2 } = column.type.image;
        return (
            <img
                src={`${cellValue}${suffix1 || ''}${suffix2 || ''}`}
                alt="dynamic-content"
                style={{ width: '100px', height: '100px', borderRadius: '4px', paddingLeft: 5 }}
            />
        );
    } else {
        return <Typography style={{ paddingLeft: 5, fontSize: '0.875rem' }}>{cellValue}</Typography>;
    }
};

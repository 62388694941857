import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { WSView } from './WSView';
import dayjs from 'dayjs';
import { WSStatus } from '../Status/WSStatus';

// Define an interface for your component props
interface ViewProps {
    column: IInfoColumns[];
    data: any;
    labelStyle?: React.CSSProperties;
    header?: string;
}

export interface IInfoColumns {
    label: string;
    fieldName: string;
    type?: 'none' | 'status' | 'date'; // Default value will be handled below
    style?: React.CSSProperties;
    isTooltip?: boolean;
}

// Define and export the memoized component in one line
export const WSInfocard: React.FC<ViewProps> = React.memo(({ data, column, labelStyle, header }) => {

    return (
        <Box
            sx={{
                border: '1px solid #ccc',   // Border to give it a card-like appearance
                borderRadius: '4px',        // Rounded corners
                padding: '0px',             // Inner padding
                marginBottom: '16px',       // Spacing from other elements
            }}
        >
            {/* Header */}
            {header && (
                <Box
                    sx={{
                        backgroundColor: 'lightgray',
                        padding: '9px',
                        borderRadius: '4px 4px 0 0',  // Rounded top corners
                        marginBottom: '16px',
                        textAlign: 'start',           // Align the header text
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: '1rem'
                        }}
                    >
                        {header}
                    </Typography>
                </Box>
            )}

            {/* Content */}
            <Grid container spacing={2} style={{ paddingLeft: 20, paddingBottom: 10 }}>
                {column?.map((item: IInfoColumns, index: number) => {
                    // Ensure default value for type
                    const type = item?.type || 'none';

                    return (
                        <React.Fragment key={index}>
                            {/* Label */}
                            <Grid item sm={3} xs={12}>
                                <WSView title={item.label} style={labelStyle || { fontSize: '1rem' }} />
                            </Grid>

                            {/* Data */}
                            <Grid item sm={3} xs={12}>
                                {type === 'date' && (
                                    // Handle date type
                                    <Typography style={item.style || { fontSize: '0.875rem', color: 'gray' }}>
                                        {dayjs(data[item.fieldName])?.toDate().toLocaleDateString()}
                                    </Typography>
                                )}
                                {type === 'none' && (

                                    <WSView
                                        title={data[item.fieldName]}
                                        isTooltip={item.isTooltip}
                                        style={item.style || { color: 'gray' }}
                                    />
                                )}
                                {
                                    type==='status' && (  <WSStatus value={data[item.fieldName]} />)
                                }
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
        </Box>
    );
});

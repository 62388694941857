import React, { useState, useCallback, memo, useRef } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import { DateCalendar, LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface ICalanderProps {
    id: string;
    title: string;
    icon?: React.ElementType;
    iconColor?: string;
    text?: string;
    profileHeight?: number;
    leaveDates: { date: Dayjs; reason: string }[];
}

const CustomDay: React.FC<PickersDayProps<Dayjs> & { leaveDates: { date: Dayjs; reason: string }[] }> = memo(
    ({ day, leaveDates, ...pickersDayProps }) => {
        const leave = leaveDates.find((leaveDate) => day.isSame(leaveDate.date, 'day'));
        const isSunday = day.day() === 0;
        const isToday = dayjs().isSame(day, 'day') && !leave;

        return (
            <Tooltip title={leave ? leave.reason : ''} arrow>
                <span>
                    <PickersDay
                        {...pickersDayProps}
                        day={day}
                        onClick={(e) => e.stopPropagation()} // Prevent click event from propagating
                        sx={{
                            backgroundColor: leave
                                ? '#FFCDD2'
                                : isToday
                                ? 'whitesmoke'
                                : isSunday
                                ? '#FFEBEE'
                                : undefined,
                            color: leave
                                ? '#D32F2F'
                                : isToday
                                ? 'black'
                                : isSunday
                                ? '#D32F2F'
                                : undefined,
                            '&:hover': {
                                backgroundColor: leave
                                    ? '#EF9A9A'
                                    : isToday
                                    ? 'whitesmoke'
                                    : isSunday
                                    ? '#FFCDD2'
                                    : undefined,
                            },
                        }}
                    />
                </span>
            </Tooltip>
        );
    }
);

const WSCalander: React.FC<ICalanderProps> = ({
    id,
    title,
    icon: Icon,
    iconColor,
    profileHeight,
    leaveDates,
}) => {
    const [screenHeight, setScreenHeight] = useState(profileHeight ? profileHeight : window.outerHeight);
    
    const renderDay = useCallback(
        (dayProps:any) => <CustomDay {...dayProps} leaveDates={leaveDates} />,
        [leaveDates]
    );

    // Set handleDateChange to do nothing
    const handleDateChange = useCallback((newDate: Dayjs | null) => {
        // Do nothing on date change
    }, []);

    return (
        <Paper elevation={1} variant="elevation">
            <Box mt={0} height="100%" display="flex" flexDirection="column">
                <Card>
                    <CardHeader
                        title={
                            <Grid container spacing={2} alignItems="center">
                                {Icon && (
                                    <Grid item sm={1}>
                                        <Icon style={{ color: iconColor ? iconColor : 'black' }} />
                                    </Grid>
                                )}
                                <Grid item sm={Icon ? 11 : 12}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            marginBottom: 5,
                                            fontWeight: 'bold',
                                            paddingLeft: 0,
                                            fontSize: '1.15rem',
                                        }}
                                    >
                                        {title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    />
                    <Divider />
                    <CardContent sx={{ flexGrow: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                onChange={handleDateChange}
                                slots={{
                                    day: renderDay,
                                }}
                                sx={{
                                    width: '100%',
                                    maxWidth: '420px',
                                    padding: 1,
                                    '& .MuiPickersDay-root': {
                                        minWidth: '32px',
                                        minHeight: '32px',
                                        fontSize: '0.85rem',
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </CardContent>
                </Card>
            </Box>
        </Paper>
    );
};

export default WSCalander;

/**
 * Component   : UserManagement
 * Description : Adding a new User
 */
import { Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { callAPI, clearRequest, customValidation, encryptPassword, EPhone, formatPhoneNumber, generateKey, Method, validations } from "../../commonFunctions/CommonFunctions";
import WSAutocompleteField from "../../Components/AutoComplete/AutoComplete";
import WSButton from "../../Components/Button/WSButton";
import WSPhone from "../../Components/Phone/WSPhone";
import { useSnackbar } from "../../Components/Snackbar/SnackBarContext";
import WSTextField, { TextType } from "../../Components/TextBox/WSTextBox";
import clientConfig from '../../Config/clientConfig.json';
import { Header } from "../Header/Header";
import { IUser } from "../Interfaces/Interfaces";
export const UserManagement: React.FC<any> = React.memo((props) => {
    const { showMessage } = useSnackbar();
    const { register, handleSubmit, control, formState: { }, setValue, watch, reset } = useForm();
    const navigate = useNavigate();
    const params = useParams();
    const id = params?.id;
    useEffect(() => () => {
        reset()
    }, [])
    useEffect(() => {
        /**
         * Method to get the user record by id for edit purpose
         * @param id 
         */
        async function getUserById(id: any): Promise<void> {
            try {
                const response: any = await callAPI('user', Method.GET, {}, id);
                
                if (response && response.data) {
                    
                    for (const field in response.data) {
                        if (field === 'userName' || field === 'phone') {
                            setValue(field, formatPhoneNumber((response?.data as any)[field], EPhone.AddHyphen))
                        }
                        else {
                            setValue(field, (response?.data as any)[field]);
                        }
                    }
                }
            } catch (error) {
                showMessage('Error fetching user:', 'error');
            }
        }
        getUserById(id);
    }, [id]);

    const handleCancelClick = () => {
        navigate("/user/list");
    };

    /**
     * Method for Add/Update the data
     * @param data 
     */
    const onSubmit = async (data: any) => {
        try {
  
            const request: any = await setRequest(data);
            if (id) {
                const response: any = await callAPI(`user/${id}`, Method.PUT, request);
                if (response) {
                    showMessage(response?.message, response.success === true ? "success" : "error");
                    response.success === true && navigate("/user/list");
                }
            } else {
                const response: any = await callAPI('user', Method.POST, request);
                if (response) {
                    showMessage(response?.message, response.success === true ? "success" : "error");
                    response.success === true && navigate("/user/list");
                }
            }
        } catch (e: any) {
            showMessage(e.error);
        }
    };

    /**
     * Method to set the request for the user
     * @param data 
     * @returns 
     */
    async function setRequest(data: any) {
        let encryptedPassword = undefined;
        if (data?.password && data?.password !== '') {
            const secretKey = await generateKey();
            encryptedPassword = await encryptPassword(data?.password, secretKey);
            // Decrypt the password
            // const decryptedPassword = await decryptPassword(encryptedPassword, secretKey);
        }
        let request: IUser = {
            userName: formatPhoneNumber(data?.userName, EPhone.RemoveHyphen),
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            phone: formatPhoneNumber(data?.phone, EPhone.RemoveHyphen),
            password: data?.password,// encryptedPassword,
            roleId: data?.roleId,
        };
        return clearRequest(request);
    }
    const handleChange=()=>{}
    return (
        <><Header title={id ? "Edit User" : "Add New User"} type="simple" url="/user/list" isBackButton={true} />
            <Paper elevation={3} style={{ paddingLeft: 10, paddingRight: 0, marginTop: 0, paddingBottom: 20, border: '1px solid #ccc', borderRadius: '8px' }}>
                {/* <Grid item container spacing={1}>
                    <Grid item sm={12}>
                        <Typography className="header-text" >
                            {id ? "Edit User" : "Add New User"}
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Divider sx={{ marginY: 2 }} />
                        <br />
                    </Grid>
                </Grid> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                <Grid xs={12} className="modal-input" container spacing={2} style={{ marginTop: 10 }}>
                        <Grid item sm={6} xs={12}>
                            <WSTextField control={control} id="firstName" {...register('firstName')} name="firstName" rules={{ required: true }} label="First Name *"></WSTextField>
                       
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <WSTextField control={control} id="lastName" {...register('lastName')} name="lastName" rules={{ required: true }} label="Last Name *"></WSTextField>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <WSPhone control={control} id="userName" {...register('userName')} rules={{ required: id ? false : true }} name="userName" label="User Name (Phone) *" disabled={id ? true : false}></WSPhone>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <WSTextField control={control} id="email" {...register('email')} name="email" rules={customValidation(true, validations.email)} label="Email *"></WSTextField>
                        </Grid>

                        {!id && <Grid item sm={6} xs={12}>
                            <WSTextField control={control} id="password"  {...register("password", {
                                required: id ? false : true,
                                validate: (value: any) => {
                                    const password = watch("confirmPassword");
                                    return value === password || "Passwords do not match";
                                },
                            })} name="password" label="Password *" type={TextType.Password}></WSTextField>
                        </Grid>}

                        {!id && <Grid item sm={6} xs={12}>
                            <WSTextField control={control} id="confirmPassword" {...register("confirmPassword", {
                                required: id ? false : true,
                                validate: (value: any) => {
                                    const password = watch("password");
                                    return value === password || "Passwords do not match";
                                },
                            })} name="confirmPassword" label="Confirm Password *" type={TextType.Password}></WSTextField>
                        </Grid>}

                        <Grid item sm={6} xs={12}>
                            <WSPhone label="Phone *" rules={{ required: true }} control={control} id="phone" {...register('phone')} name="phone"></WSPhone>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <WSAutocompleteField rules={{ required: true }} control={control} id="roleId" {...register('roleId')} name="roleId" label="Role *" options={clientConfig.rolesConfig.staticOptionData}></WSAutocompleteField>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2} style={{ paddingTop: '1rem' }} justifyContent={'end'}>
                        <Grid item sx={{ marginRight: 1 }}>
                            <WSButton id='submit' label={"Save"} type="submit" size="medium" />
                        </Grid>
                        <Grid item sx={{ marginRight: 2 }}>
                            <WSButton id='cancel' label={"Cancel"} type="button" onClick={handleCancelClick} size="medium" className="ws-cancel" />
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    );
});
